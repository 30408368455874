import React, { useCallback, useMemo, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Button from "@/components/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TrashIcon, PlusIcon } from "@heroicons/react/24/outline";
import { FormBuilder, TextInput, SaveCancel } from "@/components/Form";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { createImage, deleteImage, getImages } from "@/queries/images";

const ImageUploadForm = ({ parentId, entityType, onSuccess, onCancel }) => {
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => createImage(entityType, parentId, data, queryClient),
    onSuccess: async () => {
      console.log("hux1", entityType, parentId)
      //queryClient.invalidateQueries([entityType.replace(/s$/, ''), "images", parentId]);
      await queryClient.fetchQuery([entityType.replace(/s$/, ''), "images", parentId]);

      onSuccess();
    },
    onError: (error) => {
      console.error("Error creating image:", error);
    },
    onSettled: () => {
      setIsSubmitting(false);
      setUploadProgress(0);
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true,
  });

  const addSchema = yup.object().shape({
    name: yup.string()
  });

const handleSubmit = (formData) => {
  if (!files.length) {
    console.error("No images selected");
    return;
  }

  const uploadPromises = files.map(file => {
    const data = new FormData();
    data.append("name", formData.name || file.name); // Use filename as default 'name'
    data.append("file", file); // Set the key to 'file' as required by backend

    return mutation.mutateAsync(data);
  });

  setIsSubmitting(true);
  let progress = 0;
  const progressInterval = setInterval(() => {
    progress += 100 / (files.length * 10);
    setUploadProgress(Math.min(progress, 100));
    if (progress >= 100) clearInterval(progressInterval);
  }, 300);

  Promise.all(uploadPromises)
    .then(() => {
      onSuccess();
    })
    .catch((error) => {
      console.error("Error uploading images:", error);
    })
    .finally(() => {
      setIsSubmitting(false);
      clearInterval(progressInterval);
      setUploadProgress(0);
    });
};



  return (
    <div className="py-6 min-w-full">
      <FormBuilder onSubmit={handleSubmit} schema={addSchema}>
        <div
          {...getRootProps({
            className: `border-2 border-dashed p-6 transition-all ${
              isDragActive ? "border-blue-400 bg-blue-100" : "border-gray-300 bg-gray-50"
            } hover:border-blue-400 hover:bg-blue-50 rounded-lg cursor-pointer text-center`,
          })}
        >
          <input {...getInputProps()} />
          <p className="text-gray-500">
            {isDragActive ? "Drop the images here..." : "Drag and drop images here, or click to select"}
          </p>
        </div>
        {files.length > 0 && (
          <div className="mt-4">
            <h4 className="font-semibold text-lg">Images to upload:</h4>
            <ul className="space-y-2 mt-2">
              {files.map((file) => (
                <li key={file.path} className="flex justify-between items-center p-2 bg-white rounded-lg shadow-sm border border-gray-300">
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-600">{file.name}</span>
                  </div>
                  <span className="text-xs text-gray-500">{(file.size / 1024).toFixed(2)} KB</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        {isSubmitting && (
          <div className="mt-4">
            <h4 className="font-semibold text-lg">Uploading images...</h4>
            <div className="w-full bg-gray-200 rounded-full h-4 mt-2">
              <div
                className="bg-blue-500 h-4 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="text-gray-600 text-sm mt-2">{uploadProgress.toFixed(0)}% complete</p>
          </div>
        )}
        <SaveCancel
          label="Add Images"
          cancelAction={onCancel}
          isSubmitting={isSubmitting}
          submittingMessage="Uploading images..."
        />
      </FormBuilder>
    </div>
  );
};

const Image = ({ image, entityType, editable, parentId }) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const queryClient = useQueryClient();


const deleteMutation = useMutation({
  mutationFn: () => deleteImage(entityType, image.id, queryClient),
  onSuccess: async () => {
  console.log("Invalidate query triggered:", entityType.replace(/s$/, ''), "images", parentId);
    queryClient.invalidateQueries([entityType.replace(/s$/, ''), "images", parentId]);
    setDeleteConfirm(false);
    setDeleteError(null);
  },
  onError: (error) => {
    console.error(`Error deleting image:`, error);
    setDeleteError(error.message || "An error occurred while deleting the image.");
  },
});


  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setDeleteConfirm(true);
    setDeleteError(null);
  };

  const handleConfirmDelete = (e) => {
    e.stopPropagation();
    deleteMutation.mutate();
  };

  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setDeleteConfirm(false);
    setDeleteError(null);
  };

  const getObjectFit = (orientation) => {
    switch (orientation) {
      case "landscape":
        return "object-cover";
      case "portrait":
        return "object-contain";
      default:
        return "object-center";
    }
  };

  return (
    <tr className="flex w-full hover:bg-gray-100 dark:hover:bg-gray-800">
      <td className="p-4 border border-gray-200 w-2/3 flex items-center">
        <img
          src={image.image_url}
          alt={image.name || "Image"}
          className={`w-24 h-24 mr-4 rounded ${getObjectFit(image.orientation)}`}
        />
        <span
          className="text-sm text-blue-500 hover:text-blue-700 cursor-pointer"
          onClick={() => window.open(image.image_url, "_blank")}
        >
          {image.name || `Image ${image.id}`}
        </span>
      </td>
      <td className="p-4 border border-gray-200 w-1/3">
        <div className="flex justify-between items-center">
          <span>{image.orientation || "Unknown"}</span>
          {editable && !deleteConfirm && (
            <TrashIcon
              className="h-6 w-6 text-gray-400 hover:text-red-400 cursor-pointer ml-2"
              onClick={handleDeleteClick}
            />
          )}
        </div>
        {deleteConfirm && (
          <div className="flex justify-between items-center mt-2">
            <span className="text-red-600">Are you sure?</span>
            <div>
              <button
                className="mr-2 rounded bg-red-600 hover:bg-red-700 px-3 py-1 text-white text-sm"
                onClick={handleConfirmDelete}
              >
                Yes
              </button>
              <button
                className="rounded bg-gray-500 hover:bg-gray-600 px-3 py-1 text-white text-sm"
                onClick={handleCancelDelete}
              >
                No
              </button>
            </div>
          </div>
        )}
        {deleteError && (
          <div className="mt-2 text-red-600 text-sm">
            Error: {deleteError}
          </div>
        )}
      </td>
    </tr>
  );
};

export const ImageDisplay = ({ images, id, entityType, editable }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const queryClient = useQueryClient();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const refetchImages = useCallback(() => {
    queryClient.invalidateQueries([entityType.replace(/s$/, ''), "images", id]);
  }, [queryClient, entityType, id]);

  const imageCount = images.isSuccess && Array.isArray(images.data) ? images.data.length : 0;

  return (
    <div className="flex flex-col mb-6">
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-gray-200">Images</h3>
        <div className="flex items-center space-x-4">
          {editable && !showUploadForm && (
            <Button onClick={() => setShowUploadForm(true)}>
              <PlusIcon className="h-5 w-5 mr-2" />
              Add Images
            </Button>
          )}
        </div>
      </div>

      {showUploadForm && (
        <ImageUploadForm
          parentId={id}
          entityType={entityType}
          onSuccess={() => {
            setShowUploadForm(false);
            setIsExpanded(true);
            refetchImages();
          }}
          onCancel={() => setShowUploadForm(false)}
        />
      )}

      {!showUploadForm && (
        <div className="overflow-x-auto mt-4">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 cursor-pointer" onClick={toggleExpand}>
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr className="flex w-full">
                <th scope="col" className="px-4 py-3 w-2/3">
                  {isExpanded ? "Images" : ""}
                </th>
                <th scope="col" className="px-4 py-3 w-1/3 flex justify-between items-center">
                  <span className="dark:text-white text-black">
                    {isExpanded ? "Orientation" : `${imageCount} image${imageCount !== 1 ? 's' : ''} hidden`}
                  </span>
                  <button
                    className="ml-auto"
                    onClick={(e) => { e.stopPropagation(); toggleExpand(); }}
                  >
                    {isExpanded ? (
                      <ChevronUpIcon className="h-5 w-5 dark:text-white" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 dark:text-white" />
                    )}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody className="dark:bg-white">
              {isExpanded && (
                <>
                  {images.isLoading && (
                    <tr>
                      <td colSpan="2" className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400">
                        Loading...
                      </td>
                    </tr>
                  )}
                  {images.isError && (
                    <tr>
                      <td colSpan="2" className="px-6 py-3 text-center text-sm text-red-500 dark:text-red-400">
                        Error loading images. Please try again later.
                      </td>
                    </tr>
                  )}
                  {images.isSuccess &&
                    images.data.map((image) => (
                      <Image
                        key={image.id}
                        image={image}
                        editable={editable}
                        entityType={entityType}
                        parentId={id}
                      />
                    ))}
                  {images.isSuccess && !images.data.length && (
                    <tr>
                      <td colSpan="2" className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400">
                        <em>No images found</em>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ImageDisplay;

import { Table, TableRow } from "../AppTable2";
import SettlementTableTitle from "./SettlementTableTitle";
import SettlementVerticalTable from "./SettlementVerticalTable";

const SettlementRevenuesPL = () => {
  // Data for Promoter Revenues
  const promoterRevenuesSection = {
    title: "Promoter Revenues",
    headers: [
      {
        title: "REVENUES",
        accessor: "revenue",
        key: "revenues",
      },
      {
        title: "TOTAL",
        accessor: "total",
        editable: true,
      },
      {
        title: "PER CAP",
        accessor: "per_cap",
      },
    ],
    data: [
      {
        revenue: "NET TICKET SALES",
        total: 146399.54,
        per_cap: 58.49,
      },
      {
        revenue: "TXT REBATES",
        total: 22300.6,
        per_cap: 8.91,
      },
      {
        revenue: "FAC FEE",
        total: 11263.5,
        per_cap: 4.5,
      },
      {
        revenue: "CONCESSION REVENUE",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
      {
        revenue: "MERCH REVENUE",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
      {
        revenue: "TXTMASTER REVENUE",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
      {
        revenue: "VIP",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
      {
        revenue: "MISC",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
      {
        revenue: "MISC",
        total: 0, // Placeholder, adjust as necessary
        per_cap: 0,
      },
    ],
  };

  // Data for Show P&L
  const showPnLData = [
    {
      key: "profitLoss",
      label: "Profit (Loss)",
      value: "$(170,624.89)",
      isEditable: false,
    },
    {
      key: "ticketRev",
      label: "Ticketing Revenue",
      value: "$146,399.54",
      isEditable: false,
    },
    {
      key: "ancillaryRev",
      label: "Ancillary Revenue",
      value: "$33,844.10",
      isEditable: false,
    },
    {
      key: "expenses",
      label: "Expenses",
      value: "$(225,588.53)",
      isEditable: false,
    },
    {
      key: "guarantee",
      label: "Guarantee",
      value: "$(125,000.00)",
      isEditable: false,
    },
    { key: "overages", label: "Overages", value: "$0.00", isEditable: false },
    { key: "bonus", label: "Bonus", value: "$0.00", isEditable: false },
    {
      key: "deductions",
      label: "Deductions from Guarantee",
      value: "$0.00",
      isEditable: false,
    },
    {
      key: "promoterProfit",
      label: "Promoter Profit (Loss)",
      value: "$(204,288.99)",
      isEditable: false,
    },
  ];

  return (
    <div className="mb-8">
      {/* Flexbox SettlementRevenuesPL for Promoter Revenues and Show P&L */}
      <div className="flex space-x-6 mb-8">
        <div className="w-2/3">
          <SettlementTableTitle>Promoter Revenues</SettlementTableTitle>
          <Table
            columns={promoterRevenuesSection.headers}
            data={promoterRevenuesSection.data}
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "TOTAL REVENUES",
              value: "$94949",
            }}
            rowContainerClass="bg-blue-200"
          />
        </div>
        <div className="w-1/3">
          <SettlementVerticalTable
            title="Show P&L"
            data={showPnLData}
            onBlur={(key, newValue) => {
              console.log(`Updated Show P&L - Field ${key}: ${newValue}`);
            }}
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "PROMOTER PROFIT (LOSS)",
              value: "$94949",
            }}
            rowContainerClass="bg-blue-200"
          />
        </div>
      </div>
    </div>
  );
};

export default SettlementRevenuesPL;

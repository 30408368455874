import React, { ChangeEvent, useState } from "react";
import SettlementVerticalTable from "./SettlementVerticalTable";
import SettlementTableTitle from "./SettlementTableTitle";
import { Table, TableRow } from "../AppTable2";

// Reusable InputField component for handling inputs
const InputField: React.FC<{
  value: string | number;
  isEditable: boolean;
  onChange?: (val: string | number) => void;
}> = ({ value, isEditable, onChange }) => {
  return (
    <input
      type="text"
      value={value}
      readOnly={!isEditable}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        isEditable && onChange?.(e.target.value)
      }
      className={`border p-2 rounded w-full ${isEditable ? "bg-blue-200" : "bg-white"}`}
    />
  );
};

// Example usage rendering the artist section with SettlementVerticalTables and Artist Deductions
const SettlementArtistAdditionals: React.FC = () => {
  const [data, setData] = useState({
    artistFeeCalculation: {
      data: [
        {
          key: "Artist_Guarantee",
          label: "Guarantee",
          value: 125000,
          isEditable: true,
        },
        {
          key: "Artist_PromoterProfit",
          label: "Promoter Profit",
          value: -204288.99,
          isEditable: false,
        },
        {
          key: "Artist_SplitPoint",
          label: "Split Point",
          value: -79488.99,
          isEditable: false,
        },
        {
          key: "Artist_Overages",
          label: "Overages",
          value: 0,
          isEditable: false,
        },
      ],
    },
    artistDeductions: {
      headers: [
        {
          label: "Item",
          title: "Item",
          key: "item",
          accessor: "item",
        },
        {
          label: "Paid By",
          title: "Paid By",
          key: "paidBy",
          accessor: "paidBy",
          editable: true,
        },
        {
          label: "Amount",
          title: "Amount",
          key: "amount",
          accessor: "amount",
          editable: true,
        },
      ],
      data: [
        {
          item: "Deposit",
          paidBy: "Mammoth",
          Amount: 13850,
          isEditable: true,
        },
        { item: "Cash", paidBy: "Mammoth", amount: 10000, isEditable: true },
        { item: "Misc", paidBy: "Misc", amount: 0, isEditable: true },
      ],
    },
    artistReimbursements: {
      data: [
        {
          key: "Artist_Production",
          label: "Artist Production",
          value: 63500,
          isEditable: true,
        },
        { key: "Artist_Bonus", label: "Bonus", value: 0, isEditable: true },
        {
          key: "Artist_TravelBuyout",
          label: "Travel Buyout",
          value: -10000,
          isEditable: true,
        },
      ],
    },
    artistPayments: {
      data: [
        {
          key: "Artist_Guarantee",
          label: "Guarantee",
          value: 125000,
          isEditable: true,
        },
        {
          key: "Artist_Overage",
          label: "Overage",
          value: 0,
          isEditable: false,
        },
        {
          key: "Artist_NetAdjustments",
          label: "Net Adjustments",
          value: 63500,
          isEditable: true,
        },
        {
          key: "Artist_TaxableAmount",
          label: "Taxable Amount",
          value: 188500,
          isEditable: false,
        },
        {
          key: "Artist_TaxState",
          label: "Tax %: State / City",
          value: 0,
          isEditable: true,
        },
        {
          key: "Artist_LessDeductions",
          label: "Less Deductions",
          value: -23850,
          isEditable: true,
        },
      ],
    },
    artistChargebacks: {
      data: [
        {
          key: "Chargebacks_Amount",
          label: "Chargeback Amount",
          value: 0,
          isEditable: false,
        },
      ],
    },
  });

  const handleVerticalBlur = (
    sectionKey: keyof typeof data,
    key: string,
    newValue: string | number
  ) => {
    const updatedData = { ...data };
    updatedData[sectionKey].data = updatedData[sectionKey].data.map((row) =>
      row.key === key ? { ...row, value: newValue } : row
    );
    setData(updatedData);
  };

  const handleArtistDeductionsBlur = (
    index: number,
    field: string,
    newValue: string | number
  ) => {
    const updatedDeductions = [...data.artistDeductions.data];
    updatedDeductions[index][field] = newValue;
    setData({
      ...data,
      artistDeductions: { ...data.artistDeductions, data: updatedDeductions },
    });
  };

  return (
    <div className="grid grid-cols-4">
      {/* Artist Fee Calculation as vertical table */}
      <div className="border border-black">
        <SettlementVerticalTable
          title="Artist Fee Calculation"
          data={data.artistFeeCalculation.data}
          onBlur={(key, newValue) =>
            handleVerticalBlur("artistFeeCalculation", key, newValue)
          }
        />
        <TableRow
          columns={[
            {
              key: "name",
              accessor: "name",
              textAlign: "left",
            },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
            },
          ]}
          rowData={{
            name: "COVERAGES",
            value: "$94949",
          }}
          rowContainerClass="bg-blue-200"
        />
      </div>

      <div className="border border-black">
        {/* Artist Deductions as custom table */}
        <SettlementTableTitle>Artist Deductions</SettlementTableTitle>
        <Table
          columns={data.artistDeductions.headers}
          data={data.artistDeductions.data}
        />
        {/* Artist Chargebacks as vertical table */}
        <div className="border border-black">
          <SettlementVerticalTable
            title="Artist Chargebacks"
            data={data.artistChargebacks.data}
            onBlur={(key, newValue) =>
              handleVerticalBlur("artistChargebacks", key, newValue)
            }
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "NET ADJUSTMENTS",
              value: "$94949",
            }}
            rowContainerClass="bg-blue-200"
          />
        </div>
      </div>

      {/* Artist Reimbursements as vertical table */}
      <div className="border border-black self-start">
        <SettlementVerticalTable
          title="Artist Reimbursements"
          data={data.artistReimbursements.data}
          onBlur={(key, newValue) =>
            handleVerticalBlur("artistReimbursements", key, newValue)
          }
        />
        <TableRow
          columns={[
            {
              key: "name",
              accessor: "name",
              textAlign: "left",
            },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
            },
          ]}
          rowData={{
            name: "",
            value: "$94949",
          }}
          rowContainerClass="bg-blue-200"
        />
      </div>

      {/* Artist Payments as vertical table */}
      <div className="border border-black">
        <SettlementVerticalTable
          title="Artist Payments"
          data={data.artistPayments.data}
          onBlur={(key, newValue) =>
            handleVerticalBlur("artistPayments", key, newValue)
          }
        />
        <TableRow
          columns={[
            {
              key: "name",
              accessor: "name",
              textAlign: "left",
            },
            {
              key: "value",
              accessor: "value",
              textAlign: "right",
            },
          ]}
          rowData={{
            name: "TOTAL DUE ARTIST",
            value: "$94949",
          }}
          rowContainerClass="bg-blue-200"
        />
      </div>
    </div>
  );
};

export default SettlementArtistAdditionals;

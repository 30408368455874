import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import YearView from "./YearView";
import HoldModal from "./HoldModal";
import { getEventsCalendar, fetchYearlyEvents, createEventHolds } from "@/queries/events";
import { EVENT_MAPPING } from "@/components/EventState";
import { usePerms } from "@/components/Auth";
import "./calendar.css";

export const Calendar = () => {
  const [selectedDates, setSelectedDates] = useState(null);
  const [showAddHold, setShowAddHold] = useState(false);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [currentView, setCurrentView] = useState("dayGridMonth");
  const [isYearViewActionLoading, setIsYearViewActionLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const excludedStates = ['offer_sent', 'draft'];
  const [activeFilters, setActiveFilters] = useState(
    Object.keys(EVENT_MAPPING).filter(state => !excludedStates.includes(state))
  );


  const navigate = useNavigate();
  const calendarRef = useRef(null);
  const queryClient = useQueryClient();
  const { perms } = usePerms();

useEffect(() => {
  if (calendarRef.current) {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(currentView);
    setCurrentDate(dayjs(calendarApi.getDate()));
  }
}, []);
  const monthlyPayload = useMemo(() => {
    const startOfMonth = currentDate.startOf('month');
    const endOfMonth = currentDate.endOf('month');
    return {
      start_year: startOfMonth.year(),
      start_month: startOfMonth.month() + 1,
      start_day: 1,
      end_year: endOfMonth.year(),
      end_month: endOfMonth.month() + 1,
      end_day: endOfMonth.date(),
    };
  }, [currentDate]);

  const yearlyPayload = useMemo(() => ({
    year: currentDate.year(),
  }), [currentDate]);

  const { 
    data: monthlyEvents, 
    isLoading: isMonthlyLoading, 
    error: monthlyError 
  } = useQuery(
    ["monthlyEvents", monthlyPayload],
    () => getEventsCalendar(monthlyPayload),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      keepPreviousData: true,
    }
  );

  const { 
    data: yearlyEvents, 
    isLoading: isYearlyLoading, 
    error: yearlyError 
  } = useQuery(
    ["yearlyEvents", yearlyPayload.year],
    () => fetchYearlyEvents(yearlyPayload),
    {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      keepPreviousData: true,
    }
  );

  const events = currentView !== "yearView" ? monthlyEvents || [] : yearlyEvents || [];

  const filteredEvents = useMemo(() => {
    return events.filter(event => 
      activeFilters.length === 0 || activeFilters.includes(event.state)
    ).map((event) => ({
      ...event,
      backgroundColor: EVENT_MAPPING[event.state]["rgb-background"],
      textColor: EVENT_MAPPING[event.state]["rgb-text"],
    }));
  }, [events, activeFilters]);

  const handleShowModal = (value) => {
    if (perms.data.create_events) {
      setShowAddHold(true);
    }
  };
 const handleCloseModal = () => {
    setShowAddHold(false);
  };

  const handleDateSelect = (selectionInfo) => {
    setSelectedDates(selectionInfo);
  };

  const handleDateClick = (info) => {
    setSelectedDates(info);
  };

const clearSelection = () => {
    setSelectedDates(null);
  };

  const renderEvent = (eventInfo) => {
    const event = eventInfo.event;
    const eventName = event.extendedProps.name.slice(0, 6);
    const venueName = event.extendedProps.venue_name.replace(/\bthe\b/gi, '').trim().slice(0, 6);
    const buyerName = event.extendedProps.buyer_name;
    return (
      <div
        className="cursor-pointer truncate p-1 text-xs font-bold"
        style={{
          backgroundColor: event.backgroundColor,
          color: event.textColor,
          borderRadius: "4px",
        }}
        title={event.title}
      >
        {`${eventName}@${venueName}... (${buyerName})`}
      </div>
    );
  };


  const handleDatesSet = (datesSetInfo) => {
    const { start, end, view } = datesSetInfo;
    if (currentView !== "yearView") {
      setSelectedDates({ start, end, view });
      setCurrentDate(dayjs(view.currentStart));
    }
  };

  const handleNavigation = (direction) => {
    if (currentView !== "yearView") {
      const calendarApi = calendarRef.current.getApi();
      direction === "prev" ? calendarApi.prev() : calendarApi.next();
      setCurrentDate(dayjs(calendarApi.getDate()));
    } else {
      setCurrentDate(prev => direction === "prev" ? prev.subtract(1, 'year') : prev.add(1, 'year'));
    }
  };
const createHoldMutation = useMutation({
  mutationFn: (data) => {
    let dates = [];
    if (selectedDates.dateStr) {
      // Single day selection
      dates.push(selectedDates.dateStr);
    } else if (selectedDates.start && selectedDates.end) {
      // Multi-day selection
      let startDate = dayjs(selectedDates.start);
      const endDate = dayjs(selectedDates.end);
      while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'day')) {
        dates.push(startDate.format('YYYY-MM-DD'));
        startDate = startDate.add(1, 'day');
      }
    } else {
      throw new Error('Invalid date selection');
    }

    const holdData = {
      name: data.name,
      dates: dates,
      venue_ids: data.venues.map(venue => venue.value),
      artist_ids: data.artists.map(artist => artist.value),
      description: data.description
    };
    return createEventHolds(holdData);
  },
  onSuccess: () => {
    queryClient.invalidateQueries(["monthlyEvents"]);
    queryClient.invalidateQueries(["yearlyEvents"]);
    setShowAddHold(false);
    clearSelection();
  }
});

    const handleViewChange = useCallback((view) => {
    setIsLoading(true);
    setCurrentView(view);
    if (view !== "yearView" && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(view);
      setCurrentDate(dayjs(calendarApi.getDate()));
    }
    // Use setTimeout to ensure the loading state is visible
    setTimeout(() => setIsLoading(false), 500);
  }, []);


  const toggleFilter = useCallback((filter) => {
    setIsLoading(true);
    setActiveFilters(prev => {
      const newFilters = prev.includes(filter)
        ? prev.filter(f => f !== filter)
        : [...prev, filter];
      
      // Use setTimeout to ensure the loading state is visible
      setTimeout(() => setIsLoading(false), 500);
      return newFilters;
    });
  }, []);

const renderFilterButtons = () => (
  <div className="flex space-x-2 justify-end">
    {Object.entries(EVENT_MAPPING)
      .filter(([state]) => !excludedStates.includes(state))
      .map(([state, colors]) => (
        <button
          key={state}
          className={`px-2 py-1 rounded text-xs transition-colors duration-200`}
          style={{
            backgroundColor: activeFilters.includes(state) ? colors["rgb-background"] : '#4A5568', // Darker gray when off
            color: activeFilters.includes(state) ? colors["rgb-text"] : '#FFFFFF',
          }}
          onClick={() => toggleFilter(state)}
        >
          {state}
        </button>
      ))}
  </div>
);

const renderHeader = () => (
  <div className="flex justify-between items-center mb-4 dark:text-white">
    <div className="flex items-center space-x-2 dark:text-white">
      <button
        onClick={() => handleNavigation("prev")}
        className="text-3xl px-2 py-1 bg-transparent text-[#181818] dark:text-white hover:bg-gray-200 rounded"
        aria-label="Previous"
      >
        &lt;
      </button>
      <span className="text-3xl font-">
        {currentView === "yearView"
          ? currentDate.format("YYYY")
          : currentDate.format("MMM YYYY")}
      </span>
      <button
        onClick={() => handleNavigation("next")}
        className="text-3xl px-2 py-1 bg-transparent text-[#181818] dark:text-white hover:bg-gray-200 rounded"
        aria-label="Next"
      >
        &gt;
      </button>
    </div>

    <div className="flex items-center space-x-2">
      <button
        className={`px-4 py-2 rounded-md text-xs font-medium ${
          currentView === "dayGridMonth"
            ? "bg-cave-blue1 dark:text-black"
            : "bg-cave-blue3 dark:text-black hover:bg-gray-200"
        }`}
        onClick={() => handleViewChange("dayGridMonth")}
      >
        Month
      </button>
      <button
        className={`px-4 py-2 rounded-md text-xs font-medium ${
          currentView === "yearView"
            ? "bg-cave-blue1 dark:text-black"
            : "bg-cave-blue3 dark:text-black hover:bg-gray-200"
        }`}
        onClick={() => handleViewChange("yearView")}
      >
        Year
      </button>
    </div>
  </div>
);
const renderContent = () => {
    if (isLoading || (currentView === "yearView" && isYearlyLoading) || (currentView !== "yearView" && isMonthlyLoading)) {
      return (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      );
    }

    if (currentView !== "yearView") {
      if (monthlyError) {
        return <div className="p-4 text-center text-red-500">Error loading monthly events.</div>;
      }
      return (
   <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView={currentView}
          initialDate={currentDate.toDate()}
          selectable={true}
          select={handleDateSelect}
          events={filteredEvents}
          selectMirror={true}
          dateClick={handleDateClick}
          eventClick={(info) => {
            if (info.event.id && info.event.id !== "") {
              window.open(`/events/${info.event.id}`, '_blank');
            }
          }}
          eventContent={renderEvent}
          dayHeaderFormat={{ weekday: "short" }}
          headerToolbar={false}
          datesSet={handleDatesSet}
          height="auto"
          unselect={clearSelection}
        />
      );
    } else {
      if (yearlyError) {
        return <div className="p-4 text-center text-red-500">Error loading yearly events.</div>;
      }
      return (
        <YearView 
          events={filteredEvents}
          year={currentDate.year()}
          onDateClick={handleDateClick}
        />
      );
    }
  };

  return (
    <div className="w-full mx-auto bg-[#fffef9] dark:bg-cave-gray-900 dark:text-white font-sans relative">

      {renderHeader()}

      {/* Legends 
      <div className="flex space-x-4 mb-4">
        { Object.entries(EVENT_MAPPING).map(([state, colors]) => (
          <div key={state} className="flex items-center space-x-2">
            <span 
              className="w-3 h-3 inline-block" 
              style={{ backgroundColor: colors["rgb-background"] }}
            ></span>
            <span className="text-xs font-bold text-[#181818]">{state.toUpperCase()}</span>
          </div>
        ))}
      </div>
        */}
      {/* Filters */}
      <div className="flex items-center mt-4 mb-4 justify-end">
      {selectedDates && !showAddHold && perms.data.create_events && (
          <button
            onClick={handleShowModal}
            className="px-4 py-2 bg-blue-500 text-white rounded-md text-xs font-medium mr-32"
          >
            Create Hold
          </button>
      )}


       {renderFilterButtons()}
      </div>

      <div className="relative">
        {renderContent()}
      </div>
      <HoldModal
        showModal={showAddHold}
        setShowModal={handleCloseModal}
        selectedDates={selectedDates}
        onSubmit={(data) => createHoldMutation.mutate(data)}
      />
    </div>
  );
};

export default Calendar;


import React, { useState } from "react";
import SettlementVerticalTable from "./SettlementVerticalTable";

const SettlementWireInfo: React.FC = () => {
  const defaultRows = [
    { key: "artistName", label: "Artist Name", value: "", isEditable: true },
    { key: "amountDue", label: "Amount Due", value: "", isEditable: true },
    { key: "bankName", label: "Bank Name", value: "", isEditable: true },
    { key: "bankAddress", label: "Bank Address", value: "", isEditable: true },
    {
      key: "routingNumber",
      label: "Routing Number",
      value: "",
      isEditable: true,
    },
    {
      key: "accountNumber",
      label: "Account Number",
      value: "",
      isEditable: true,
    },
    {
      key: "iban",
      label: "Or IBAN (if international)",
      value: "",
      isEditable: true,
    },
    {
      key: "swiftCode",
      label: "SWIFT Code (if international)",
      value: "",
      isEditable: true,
    },
    { key: "accountName", label: "Account Name", value: "", isEditable: true },
    {
      key: "accountAddress",
      label: "Account Address",
      value: "",
      isEditable: true,
    },
  ];

  const [data, setData] = useState(
    Array.from({ length: 6 }, () => ({
      rows: JSON.parse(JSON.stringify(defaultRows)), // Deep clone defaultRows for each table
    }))
  );

  const handleBlur = (
    tableIndex: number,
    key: string,
    newValue: string | number
  ) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[tableIndex].rows = updatedData[tableIndex].rows.map((row) =>
        row.key === key ? { ...row, value: newValue } : row
      );
      return updatedData;
    });
  };

  return (
    <div className="p-6 grid grid-cols-3 gap-y-4">
      {data.map((table, index) => (
        <SettlementVerticalTable
          key={index}
          title={`Wire Info ${index + 1}`}
          data={table.rows}
          onBlur={(key, newValue) => handleBlur(index, key, newValue)}
        />
      ))}
    </div>
  );
};

export default SettlementWireInfo;

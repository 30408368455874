import dayjs from 'dayjs'

import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import { TwitterHandle } from "@/components/TwitterHandle"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

import {
  getEventAdvance,
} from "@/queries/events"

import { useParams, useNavigate } from 'react-router-dom'

import { BreadCrumbs } from '@/components/BreadCrumbs'
import { usePageTitle } from '@/utils/pagetitle';
import EventAdvance from "@/components/EventDisplay/ROEventAdvance";

export const PublicEventAdvance = () => {
  const { code } = useParams()
  const navigate = useNavigate()
  const advance_public = useQuery(["advance_public", code], () => getEventAdvance(code))
//  usePageTitle(advance_public.isSuccess ? `/c${String.fromCharCode(92)}mammoth_ticketing` : `/c${String.fromCharCode(92)}ave_good...`);

  const queryClient = useQueryClient()

  if (advance_public.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>
  }
  console.log("huxE", advance_public.data.event_id)
  return (
    <div
      className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
    >
    <EventAdvance
        event_id={advance_public.data.event_id}
    />
    </div>
  );
};

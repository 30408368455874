import React, { useCallback, useMemo, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Button from "@/components/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TrashIcon, PlusIcon } from "@heroicons/react/24/outline";
import { FormBuilder, Input, TextInput, SaveCancel, Checkbox } from "@/components/Form";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { createFile, deleteFile } from "@/queries/files";

const FileUploadForm = ({ parentId, entityType, onSuccess, onCancel, marketingOnly }) => {
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => createFile(entityType, parentId, data),
    onSuccess: async () => {
      console.log("hux1", entityType, parentId)
      queryClient.invalidateQueries([entityType.replace(/s$/, ''), "files", parentId]);
      onSuccess();
    },
    onError: (error) => {
      console.error("Error creating file:", error);
    },
    onSettled: () => {
      setIsSubmitting(false);
      setUploadProgress(0);
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  const addSchema = yup.object().shape({
    description: yup.string(),
    marketing: yup.boolean(),
  });

  const handleSubmit = (formData) => {
    if (!files.length) {
      console.error("No files selected");
      return;
    }

    const uploadPromises = files.map(file => {
      const data = new FormData();
      data.append("name", file.name);
      data.append("file", file);
      data.append("description", formData.description || "");
      data.append("marketing", marketingOnly ? true : formData.marketing || false);

      return mutation.mutateAsync(data);
    });

    setIsSubmitting(true);
    let progress = 0;
    const progressInterval = setInterval(() => {
      progress += 100 / (files.length * 10);
      setUploadProgress(Math.min(progress, 100));
      if (progress >= 100) clearInterval(progressInterval);
    }, 300);

    Promise.all(uploadPromises)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      })
      .finally(() => {
        setIsSubmitting(false);
        clearInterval(progressInterval);
        setUploadProgress(0);
      });
  };

  return (
    <div className="py-6 min-w-full">
      <FormBuilder onSubmit={handleSubmit} schema={addSchema} defaultValues={{ marketing: marketingOnly }}>
        <div
          {...getRootProps({
            className: `border-2 border-dashed p-6 transition-all ${
              isDragActive ? "border-blue-400 bg-blue-100" : "border-gray-300 bg-gray-50"
            } hover:border-blue-400 hover:bg-blue-50 rounded-lg cursor-pointer text-center`,
          })}
        >
          <input {...getInputProps()} />
          <p className="text-gray-500">
            {isDragActive ? "Drop the files here..." : "Drag and drop files or folders here, or click to select"}
          </p>
        </div>
        {files.length > 0 && (
          <div className="mt-4">
            <h4 className="font-semibold text-lg">Files to upload:</h4>
            <ul className="space-y-2 mt-2">
              {files.map((file) => (
                <li key={file.path} className="flex justify-between items-center p-2 bg-white rounded-lg shadow-sm border border-gray-300">
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-600">{file.name}</span>
                  </div>
                  <span className="text-xs text-gray-500">{(file.size / 1024).toFixed(2)} KB</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        {isSubmitting && (
          <div className="mt-4">
            <h4 className="font-semibold text-lg">Uploading files...</h4>
            <div className="w-full bg-gray-200 rounded-full h-4 mt-2">
              <div
                className="bg-blue-500 h-4 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="text-gray-600 text-sm mt-2">{uploadProgress.toFixed(0)}% complete</p>
          </div>
        )}
        <TextInput name="description" label="Description" placeholder="Optional description" />
        {!marketingOnly && <Checkbox name="marketing" label="Marketing File" />}
        <SaveCancel
          label={`Add ${marketingOnly ? 'Marketing ' : ''}Files`}
          cancelAction={onCancel}
          isSubmitting={isSubmitting}
          submittingMessage="Uploading files..."
        />
      </FormBuilder>
    </div>
  );
};



const File = ({ file, entityType, editable, canEditMarketing, onDeleteClick, onDeleteComplete }) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: () => deleteFile(entityType, file.id, queryClient),
    onSuccess: async () => {
      onDeleteComplete();
    },
    onError: (error) => {
      console.error(`Error deleting file:`, error);
    },
  });

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick();
    setDeleteConfirm(true);
  };

  const handleConfirmDelete = (e) => {
    e.stopPropagation();
    deleteMutation.mutate();
  };

  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setDeleteConfirm(false);
    onDeleteComplete();
  };

  const canEditFile = editable && (!file.marketing || canEditMarketing);

  return (
    <tr className="flex w-full hover:bg-gray-100 dark:hover:bg-gray-800">
      <td className="p-4 border border-gray-200 w-1/3">
        <span
          className="text-sm text-blue-500 hover:text-blue-700 cursor-pointer"
          onClick={() => window.open(file.file_url, "_blank")}
        >
          {file.name}
        </span>
      </td>
      <td className="p-4 border border-gray-200 w-1/3 flex justify-center">
        {file.marketing && (
          <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">
            Marketing
          </span>
        )}
      </td>
      <td className="p-4 border border-gray-200 w-1/3">
        <div className="flex justify-between items-center">
          <span>{file.description}</span>
          {canEditFile && !deleteConfirm && (
            <TrashIcon
              className="h-6 w-6 text-gray-400 hover:text-red-400 cursor-pointer ml-2"
              onClick={handleDeleteClick}
            />
          )}
        </div>
        {deleteConfirm && (
          <div className="flex justify-between items-center mt-2">
            <span className="text-red-600">Are you sure?</span>
            <div>
              <button
                className="mr-2 rounded bg-red-600 hover:bg-red-700 px-3 py-1 text-white text-sm"
                onClick={handleConfirmDelete}
              >
                Yes
              </button>
              <button
                className="rounded bg-gray-500 hover:bg-gray-600 px-3 py-1 text-white text-sm"
                onClick={handleCancelDelete}
              >
                No
              </button>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export const FileDisplay = ({ files, id, entityType, editable, perms }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const toggleExpand = () => {
    if (!isDeleting) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleDeleteClick = () => {
    setIsDeleting(true);
  };

  const handleDeleteComplete = () => {
    setIsDeleting(false);
  };

  const canViewOnlyMarketing = perms && perms.data ? perms.data.edit_marketing : false;
  const canEditMarketing = Boolean(perms?.data?.edit_marketing && editable);

  const filteredFiles = useMemo(() => {
    if (files.isSuccess && Array.isArray(files.data)) {
      return files.data.filter(file =>
        canViewOnlyMarketing ? file.marketing : true
      );
    }
    return [];
  }, [files.isSuccess, files.data, canViewOnlyMarketing]);

  const fileCount = filteredFiles.length;

  return (
    <div className="flex flex-col mb-6">
      <div className="w-full flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold dark:text-gray-200">
          {canViewOnlyMarketing ? "Marketing Files" : "All Files"}
        </h3>
        <div className="flex items-center space-x-4">
          {editable && !showUploadForm && (
            <Button onClick={() => setShowUploadForm(true)}>
              <PlusIcon className="h-5 w-5 mr-2" />
              Add {canViewOnlyMarketing ? "Marketing " : ""}Files
            </Button>
          )}
        </div>
      </div>

      {showUploadForm && (
        <FileUploadForm
          parentId={id}
          entityType={entityType}
          onSuccess={() => {
            setShowUploadForm(false);
            setIsExpanded(true);
          }}
          onCancel={() => setShowUploadForm(false)}
          canEditMarketing={canEditMarketing}
          marketingOnly={canViewOnlyMarketing}
        />
      )}

      {!showUploadForm && (
        <div className="overflow-x-auto mt-4">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 cursor-pointer" onClick={toggleExpand}>
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr className="flex w-full">
                <th scope="col" className="px-4 py-3 w-1/3">
                  {isExpanded ? "Files" : ""}
                </th>
                <th scope="col" className="px-4 py-3 w-1/3 flex justify-center items-center">
                  <span className="dark:text-white text-black">
                    {isExpanded ? "Type" : `${fileCount} file${fileCount !== 1 ? 's' : ''} hidden`}
                  </span>
                </th>
                <th scope="col" className="px-4 py-3 w-1/3 flex justify-end items-center">
                  <span className="dark:text-white text-black mr-2">
                    {isExpanded ? "Description" : ""}
                  </span>
                  <button
                    className="ml-auto"
                    onClick={(e) => { e.stopPropagation(); toggleExpand(); }}
                  >
                    {isExpanded ? (
                      <ChevronUpIcon className="h-5 w-5 dark:text-white" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 dark:text-white" />
                    )}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody className="dark:bg-white">
              {isExpanded && (
                <>
                  {files.isLoading && (
                    <tr>
                      <td colSpan="3" className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400">
                        Loading...
                      </td>
                    </tr>
                  )}
                  {files.isError && (
                    <tr>
                      <td colSpan="3" className="px-6 py-3 text-center text-sm text-red-500 dark:text-red-400">
                        Error loading files. Please try again later.
                      </td>
                    </tr>
                  )}
                  {files.isSuccess &&
                    filteredFiles.map((file) => (
                      <File
                        key={file.id}
                        file={file}
                        deleteQuery={(id) => deleteFile(entityType, id)}
                        editable={editable}
                        canEditMarketing={canEditMarketing}
                        onDeleteClick={handleDeleteClick}
                        onDeleteComplete={handleDeleteComplete}
                        entityType={entityType}
                      />
                    ))}
                  {files.isSuccess && !filteredFiles.length && (
                    <tr>
                      <td colSpan="3" className="px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-400">
                        <em>No files found</em>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FileDisplay;

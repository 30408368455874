import { CommonSettlementProps, SettlementTab } from "@/types/settlement";
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import React, { useEffect } from "react";
import MoneyInputComponent from "../Common/MoneyInput";
import { getEvent, updateEvent } from "@/queries/events";
import { getArtistEventRollups } from "@/queries/accounting";
import { useParams } from "react-router-dom";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { TableRow } from "../AppTable2";
interface Props extends CommonSettlementProps {
  rollups: UseQueryResult<any, unknown>;
  isPDF: boolean;
}
export default function SettlementFinalFigures() {
  const { event, rollups, handleUpdateEvent, isPDF, id } =
    useSettlementComponent();
  if (!event?.data || !rollups?.data) return null;
  const FinalFiguresRow = ({
    title,
    titleClass,
    subtitle,
    subInput,
    subInputTitle,
    subInputTitleClass,
    includeTotal = true,
    filled = true,
    onBlur,
    allowedValues = "all",
    initialValue = "",
    subInputInitialValue = "",
    editable = true,
    format,
  }: {
    title: string;
    titleClass?: string;
    subtitle?: string;
    subInput?: boolean;
    subInputTitle?: string;
    subInputTitleClass?: string;
    includeTotal?: boolean;
    filled?: boolean;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    allowedValues?: FinalFiguresAllowedValues;
    initialValue?: string;
    subInputInitialValue?: string;
    editable?: boolean;
    format?: "text" | "money";
  }) => {
    const WithoutSubInput = () => {
      return (
        <div className="flex">
          <div className="flex-1">
            <div className={`font-bold${filled ? " bg-gray-200" : "bg-white"}`}>
              {title}
            </div>
          </div>
          <div className="w-48">
            <FinalFiguresInput
              initialValue={initialValue}
              allowedValues={allowedValues}
              onBlur={editable ? onBlur : () => ({})}
              editable={editable}
              format={format}
            />
          </div>
        </div>
      );
    };
    const WithSubInput = () => {
      const subInputClass = () => {
        if (subInputTitle) {
          return "w-80";
        }
        return "w-48";
      };
      return (
        <div className="flex">
          <div className={`flex-1`}>
            {title && (
              <div className={`font-bold${titleClass ? ` ${titleClass}` : ""}`}>
                {title}
              </div>
            )}
            {subtitle && <span className="text-red-600">{subtitle}</span>}
          </div>
          <div className={`flex bg-white ${subInputClass()}`}>
            {subInputTitle && (
              <div
                className={`font-bold flex-1${subInputTitle ? ` ${subInputTitleClass}` : ""}`}
              >
                {isPDF && subInputTitle === EnterYesOrNo.subInputTitle
                  ? ""
                  : subInputTitle}
              </div>
            )}
            <FinalFiguresInput
              allowedValues={allowedValues}
              className="!w-48"
              onBlur={onBlur}
              initialValue={subInputInitialValue}
              format={format}
            />
          </div>
          {includeTotal && (
            <div className="w-48">
              <FinalFiguresInput
                initialValue={initialValue}
                allowedValues={allowedValues}
                onBlur={onBlur}
                format={format}
              />
            </div>
          )}
        </div>
      );
    };

    return (
      <div className="bg-white">
        {subInput ? WithSubInput() : WithoutSubInput()}
      </div>
    );
  };

  const FinalFiguresHeader = ({
    leftTitle,
    rightTitle,
  }: {
    leftTitle: string;
    rightTitle?: string;
  }) => {
    return (
      <div className="flex bg-blue-200 font-extrabold border-black border-b-2 border-t-2">
        <div className="flex-1">{leftTitle}</div>
        {rightTitle && (
          <div className="w-48 underline text-center">{rightTitle}</div>
        )}
      </div>
    );
  };
  type FinalFiguresAllowedValues = "all" | "number" | "yesno";

  const FinalFiguresInput = ({
    initialValue,
    onEnter,
    onBlur,
    className,
    allowedValues = "all",
    editable = true,
    format = "text",
  }: {
    initialValue?: string;
    onEnter?: () => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    className?: string;
    allowedValues?: FinalFiguresAllowedValues;
    editable?: boolean;
    format?: "text" | "money";
  }) => {
    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
          if (onEnter) onEnter();
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [onEnter]);

    const [value, setValue] = React.useState(initialValue ?? "");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;

      if (allowedValues === "number" && !/^\d*$/.test(inputValue)) {
        return; // only allow numbers
      }

      if (allowedValues === "yesno" && !/^(Yes|No)?$/.test(inputValue)) {
        return; // only allow 'Yes' or 'No'
      }

      setValue(inputValue);
    };

    const Render = () => {
      if (format === "money") {
        return (
          <MoneyInputComponent
            value={value}
            onChange={handleChange}
            onBlur={(e) => {
              if (onBlur) onBlur(e);
            }}
            className={`w-full block focus:outline-none bg-input-blue focus:ring text-red-600 text-xs self-start${className ? " " + className : ""}`}
            editable={!editable}
          />
        );
      }
      return (
        <input
          value={value}
          onChange={handleChange}
          onBlur={(e) => {
            if (onBlur) onBlur(e);
          }}
          type="text"
          className={`w-full block focus:outline-none bg-input-blue focus:ring text-red-600 text-xs self-start${className ? " " + className : ""}`}
          readOnly={!editable}
        />
      );
    };

    return <Render />;
  };

  const EnterYesOrNo = {
    subInputTitle: 'Enter "Yes" or "No"',
    subInputTitleClass: "text-red-600",
  };
  return (
    <div className="text-xs border-black border-2">
      <h2 className="text-center font-bold text-blue-600">FINAL FIGURES</h2>
      <FinalFiguresHeader leftTitle="REVENUES" rightTitle="TOTAL" />
      {/* GROSS */}
      <FinalFiguresRow
        title="GROSS"
        editable={false}
        initialValue={rollups.data.tickets_gross || "0"}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            gross: e.target.value,
          });
        }}
        format="money"
      />

      {/* PLATINUM LIFT */}
      <FinalFiguresRow
        title="PLATINUM LIFT"
        initialValue={event.data.platinum_lift || "0"}
        filled={false}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            platinum_lift: e.target.value,
          });
        }}
        format="money"
      />

      {/* TOTAL GROSS REVENUE */}
      <FinalFiguresRow
        title="TOTAL GROSS REVENUE"
        initialValue={rollups.data.total_revenue || "0"}
        editable={false}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            total_gross_revenue: e.target.value,
          });
        }}
        format="money"
      />
      <FinalFiguresHeader leftTitle="PER TICKET DEDUCTIONS:" />
      {/* FACILITY FEE */}
      <FinalFiguresRow
        title="FACILITY FEE RATE"
        initialValue={event.data.facility_fee || "0"}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            facility_fee: e.target.value,
          });
        }}
        format="money"
      />
      <FinalFiguresRow
        title="a) Calculated on Per Txt Deduction"
        subInput
        {...EnterYesOrNo}
        includeTotal={false}
        initialValue={event.data.facility_fee || "0"}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            facility_fee: e.target.value,
          });
        }}
      />

      {/* CHARITY RATE */}
      <FinalFiguresRow
        title="CHARITY RATE:"
        initialValue={event.data.charity || "0"}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            charity: e.target.value,
          });
        }}
        format="money"
      />

      <FinalFiguresHeader leftTitle="TOTAL PER TXT DEDUCTIONS:" />

      <FinalFiguresRow
        title="ADJ BOX OFFICE RECEIPTS"
        initialValue={rollups.data.box_office_receipts || "0"}
        onBlur={async (e) => {
          // await handleUpdateEvent(event.data.id, { tax1: e.target.value });
        }}
      />
      {/* TAX 1 */}
      <FinalFiguresRow
        title="TAX 1"
        subInputTitle="TAX RATE: "
        initialValue={
          rollups.data.tax_percent ? `${rollups.data.tax_percent}%` : "0"
        }
        editable={false}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            tax_percent: e.target.value,
          });
        }}
      />

      <FinalFiguresRow
        title="a) Calculated on Revenue, after per ticket deductions (Primary Divider Tax)"
        subInput
        {...EnterYesOrNo}
        includeTotal={false}
        subInputInitialValue={event.data.calc || ""}
        allowedValues="yesno"
        onBlur={async (e) => {
          // await handleUpdateEvent(event.data.id, {
          //   facility_fee: e.target.value,
          // });
        }}
      />
      <FinalFiguresRow
        title="e) Multiplacative Tax, On Revenue, After per Ticket Deductions (Primary Multiplier Tax)"
        subInput
        {...EnterYesOrNo}
        includeTotal={false}
        subInputInitialValue={event.data.multi || ""}
        allowedValues="yesno"
        onBlur={async (e) => {
          // await handleUpdateEvent(event.data.id, {
          //   facility_fee: e.target.value,
          // });
        }}
      />

      <FinalFiguresRow
        title="TOTAL TAX 1"
        initialValue={rollups.data.tax_amount || "0"}
        editable={false}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            tax1: e.target.value,
          });
        }}
        format="money"
      />
      {/* TAX 2 */}
      <FinalFiguresRow
        title="TAX 2"
        editable={false}
        initialValue={event.data.tax2 || "0"}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            tax2: e.target.value,
          });
        }}
        format="money"
      />
      <FinalFiguresRow
        title="Is Tax 2 calculated After (Net Of) Tax 1?"
        subInput
        {...EnterYesOrNo}
        includeTotal={false}
        titleClass="text-red-600"
        initialValue={event.data.tax2 || ""}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            tax2: e.target.value,
          });
        }}
      />

      {/* TOTAL TAX 2 */}
      <FinalFiguresRow
        title="TOTAL TAX 2"
        initialValue={event.data.total_tax2 || "0"}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            total_tax2: e.target.value,
          });
        }}
        format="money"
      />

      <FinalFiguresHeader leftTitle="VARIABLE DEDUCTIONS:" />

      {/* VARIABLE DEDUCTIONS */}
      <FinalFiguresRow
        title="LESS TM PLATINUM FEE 5%"
        initialValue={rollups.data.tm_platinum_fee || "0"}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            variable_deductions: e.target.value,
          });
        }}
        format="money"
      />
      <FinalFiguresRow
        title="TOTAL VARIABLE DEDUCTIONS"
        initialValue={rollups.data.total_variable_deductions || "0"}
        filled={false}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            variable_deductions: e.target.value,
          });
        }}
        format="money"
      />

      {/* NET GROSS RECEIPTS */}
      <FinalFiguresRow
        title="NET GROSS RECEIPTS"
        initialValue={rollups.data.net_income || "0"}
        onBlur={async (e) => {
          await handleUpdateEvent(event.data.id, {
            net_gross_receipts: e.target.value,
          });
        }}
        format="money"
      />
      <TableRow
        columns={[
          {
            key: "name",
            accessor: "name",
            textAlign: "left",
          },
          {
            key: "value",
            accessor: "value",
            textAlign: "right",
          },
        ]}
        rowData={{
          name: "NET GROSS RECEIPTS",
          value: rollups.data.net_income || "0",
        }}
        rowContainerClass="bg-blue-200"
      />
    </div>
  );
}

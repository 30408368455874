import React from "react";
import ExpenseTable from "../ExpensesTable";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import SettlementRevenuesPL from "./SettlementRevenuesPL";
import SettlementDueGrid from "./SettlementDueGrid";
import DealAndFinalFigures from "./SettlementArtistDealSection";

export default function SettlementCoproPage() {
  const { event, isPDF } = useSettlementComponent();
  return (
    <div>
      <div className="bg-purple-400 text-center py-1 font-bold text-black">
        INTERNAL SETTLEMENT: COPRO
      </div>
      <ExpenseTable
        className="mb-8"
        id={event.data?.id}
        book="copro"
        isPDF={isPDF}
      />
      <ExpenseTable
        title="ADDITIONAL INTERNAL SHOW EXPENSES"
        className="mb-8"
        id={event.data?.id}
        book="copro"
        isPDF={isPDF}
      />
      <DealAndFinalFigures className="mb-8" hideDeal />

      <SettlementRevenuesPL />
      <SettlementDueGrid />
    </div>
  );
}

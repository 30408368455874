import React, { useState, useEffect, ChangeEvent } from "react";
import { formatInputToMoney } from "@/utils/money";
import { useGlowEffect } from "@/hooks/useGlowEffect";

interface MoneyInputProps {
  value: string | number | null;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (value: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  editable?: boolean;
  className?: string;
}

const MoneyInputComponent: React.FC<MoneyInputProps> = ({
  value: initialValue,
  onChange,
  onBlur,
  onFocus,
  editable = true,
  className,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleValueFormat = (val: string | number | null) => {
    if (isFocused) return val?.toString();
    return formatInputToMoney((val?.toString() as string) || "0");
  };

  const [value, setValue] = useState<string | number>(
    handleValueFormat(initialValue)
  );
  const [changed, setChanged] = useState(false);
  const hasGlowEffect = useGlowEffect(changed);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9\.]/g, ""); // Remove non-numeric characters
    setValue(numericValue);
    // if (onChange) onChange(numericValue?.toString());
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const formattedValue = handleValueFormat(value);
    setValue(formattedValue);
    onBlur(e);
    // if (initialValue !== formattedValue) setChanged(true);
    setIsFocused(false);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        const inputElement = document.activeElement as HTMLInputElement;
        inputElement.blur();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleFocus = () => {
    setIsFocused(true);
    setValue(initialValue || 0); // Remove formatting while focused
    if (onFocus) onFocus();
  };

  return (
    <input
      type="text"
      value={handleValueFormat(value)}
      onChange={(e) => handleInputChange(e)}
      onBlur={handleBlur}
      onFocus={handleFocus}
      className={`${hasGlowEffect ? "glow-text" : ""}
        ${className ? " " + className : ""}
        `}
      readOnly={!editable}
    />
  );
};

export default MoneyInputComponent;

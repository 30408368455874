import React from "react";
import SettlementVerticalTable from "./SettlementVerticalTable";

export default function SettlementDueGrid() {
  // Data for Due sections
  const dueMammothData = [
    {
      key: "mammothPercent",
      label: "Mammoth %",
      value: "100.00%",
      isEditable: false,
    },
    {
      key: "profitPercent",
      label: "Profit Percent",
      value: "$(170,624.89)",
      isEditable: false,
    },
    {
      key: "guarantee",
      label: "Plus Guarantee",
      value: "$125,000.00",
      isEditable: false,
    },
    {
      key: "artistCosts",
      label: "Plus Artist Costs",
      value: "$63,500.00",
      isEditable: false,
    },
    { key: "tax", label: "Plus Tax", value: "$13,541.98", isEditable: false },
    {
      key: "ancillary",
      label: "Less Ancillary Revenue",
      value: "$(22,300.69)",
      isEditable: false,
    },
    { key: "taxLess", label: "Less Tax", value: "$0.00", isEditable: false },
    {
      key: "totalDue",
      label: "Total Due",
      value: "$(50,000.00)",
      isEditable: false,
    },
  ];

  const dueBuildingData = [
    {
      key: "buildingPercent",
      label: "Building %",
      value: "0.00%",
      isEditable: false,
    },
    {
      key: "profitPercent",
      label: "Profit Percent",
      value: "$0.00",
      isEditable: false,
    },
    {
      key: "guarantee",
      label: "Plus Guarantee",
      value: "$50,000.00",
      isEditable: false,
    },
    {
      key: "artistCosts",
      label: "Plus Artist Costs",
      value: "$0.00",
      isEditable: false,
    },
    { key: "tax", label: "Plus Tax", value: "$0.00", isEditable: false },
    {
      key: "ancillary",
      label: "Less Ancillary Revenue",
      value: "$0.00",
      isEditable: false,
    },
    { key: "taxLess", label: "Less Tax", value: "$0.00", isEditable: false },
    {
      key: "totalDue",
      label: "Total Due",
      value: "$50,000.00",
      isEditable: false,
    },
  ];

  const dueCopro1Data = [
    {
      key: "copro1Percent",
      label: "Copro 1 %",
      value: "0.00%",
      isEditable: false,
    },
    {
      key: "profitPercent",
      label: "Profit Percent",
      value: "$0.00",
      isEditable: false,
    },
    {
      key: "guarantee",
      label: "Plus Guarantee",
      value: "$0.00",
      isEditable: false,
    },
    {
      key: "artistCosts",
      label: "Plus Artist Costs",
      value: "$0.00",
      isEditable: false,
    },
    { key: "tax", label: "Plus Tax", value: "$0.00", isEditable: false },
    {
      key: "ancillary",
      label: "Less Ancillary Revenue",
      value: "$0.00",
      isEditable: false,
    },
    { key: "taxLess", label: "Less Tax", value: "$0.00", isEditable: false },
    { key: "totalDue", label: "Total Due", value: "$0.00", isEditable: false },
  ];

  const dueCopro2Data = [
    {
      key: "copro2Percent",
      label: "Copro 2 %",
      value: "0.00%",
      isEditable: false,
    },
    {
      key: "profitPercent",
      label: "Profit Percent",
      value: "$0.00",
      isEditable: false,
    },
    {
      key: "guarantee",
      label: "Plus Guarantee",
      value: "$0.00",
      isEditable: false,
    },
    {
      key: "artistCosts",
      label: "Plus Artist Costs",
      value: "$0.00",
      isEditable: false,
    },
    { key: "tax", label: "Plus Tax", value: "$0.00", isEditable: false },
    {
      key: "ancillary",
      label: "Less Ancillary Revenue",
      value: "$0.00",
      isEditable: false,
    },
    { key: "taxLess", label: "Less Tax", value: "$0.00", isEditable: false },
    { key: "totalDue", label: "Total Due", value: "$0.00", isEditable: false },
  ];
  return (
    <div className="grid grid-cols-2 gap-y-4">
      <SettlementVerticalTable
        title="Due Mammoth"
        data={dueMammothData}
        onBlur={(key, newValue) => {
          console.log(`Updated Due Mammoth - Field ${key}: ${newValue}`);
        }}
      />
      <SettlementVerticalTable
        title="Due Building"
        data={dueBuildingData}
        onBlur={(key, newValue) => {
          console.log(`Updated Due Building - Field ${key}: ${newValue}`);
        }}
      />
      <SettlementVerticalTable
        title="Due Copro 1"
        data={dueCopro1Data}
        onBlur={(key, newValue) => {
          console.log(`Updated Due Copro 1 - Field ${key}: ${newValue}`);
        }}
      />
      <SettlementVerticalTable
        title="Due Copro 2"
        data={dueCopro2Data}
        onBlur={(key, newValue) => {
          console.log(`Updated Due Copro 2 - Field ${key}: ${newValue}`);
        }}
      />
    </div>
  );
}

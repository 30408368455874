import { Table as AppTable, TableRow } from "../../components/AppTable2";
import { updateManifest } from "@/queries/events";
import { useMutation } from "@tanstack/react-query";
import { CaveEvent, Manifest } from "@/types/event";
import { toast } from "react-toastify";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";

export default function SettlementTicketManifests() {
  const { event, queryClient } = useSettlementComponent();
  const updateManifestMutation = useMutation<
    Manifest, // TData: The data returned by the mutation function
    Error, // TError: The type of error thrown by the mutation function
    Manifest, // TVariables: The variables passed to the mutation function
    { previousEventData: CaveEvent | undefined } // TContext: Context returned from onMutate
  >({
    mutationFn: async (data: Manifest) => {
      return await updateManifest(data.id, {
        manifest_id: data.id,
        sort_order: data.sort_order,
        eventId: data.event,
        name: data.name,
        kills: data.kills,
        qty: data.qty,
        price: data.price,
        on_sale: new Intl.DateTimeFormat("en-CA").format(
          new Date(data.on_sale ?? new Date())
        ),
        comps: data.comps,
        ticket_fees: data.ticket_fees,
        is_offer: data.is_offer,
      });
    },
    onMutate: async (newManifestData: Manifest) => {
      console.log("newManifestData in mutate", newManifestData);
      const id = newManifestData.event.toString();
      console.log("id", id);
      await queryClient.cancelQueries(["event-detail", id]);
      const previousEventData = queryClient.getQueryData<CaveEvent>([
        "event-detail",
        id,
      ]);
      // Optimistically update the cache
      queryClient.setQueryData<CaveEvent>(
        ["event-detail", id],
        (oldEventData) => {
          if (!oldEventData) {
            return undefined;
          }
          const oldEvent = { ...oldEventData };
          const newManifests = oldEvent.manifests.map((manifest) =>
            manifest.id === newManifestData.id ? newManifestData : manifest
          );
          return { ...oldEventData, manifests: newManifests };
        }
      );

      // Return context for potential rollback
      return { previousEventData };
    },
    onError: (err: Error, newManifestData: Manifest, context) => {
      const id = newManifestData.event.toString();
      toast.error("Error updating manifest");
      // Rollback to previous data
      if (context?.previousEventData) {
        queryClient.setQueryData(
          ["event-detail", id],
          context.previousEventData
        );
      }
    },
    onSuccess: (data: Manifest) => {
      const id = data.event.toString();
      // Invalidate queries to refetch the updated data
      queryClient.invalidateQueries(["event-detail", id]);
    },
  });
  const columns = [
    {
      title: "TICKET SCALING",
      accessor: "name",
      editable: true,
    },
    {
      title: "CAPACITY",
      accessor: "qty",
      textAlign: "center",
      editable: true,
    },
    {
      title: "KILLS",
      accessor: "kills",
      textAlign: "center",
      editable: true,
    },
    {
      title: "COMPS",
      accessor: "comps",
      textAlign: "center",
      editable: true,
    },
    {
      title: "SOLD",
      accessor: "paid",
      textAlign: "center",
      editable: true,
    },
    {
      title: "AVAILABLE",
      accessor: "available",
      textAlign: "center",
      editable: true,
    },
    {
      title: "NET TXT PRICE",
      accessor: "price",
      textAlign: "center",
      editable: true,
      format: "money",
    },
    {
      title: "TXT FEES",
      accessor: "ticket_fees",
      textAlign: "center",
      editable: true,
      format: "money",
    },
    {
      title: "GROSS TXT PRICE",
      accessor: "grosss",
      textAlign: "center",
      editable: false,
      format: "money",
    },
    {
      title: "GROSS",
      accessor: "gross",
      textAlign: "center",
      editable: false,
      format: "money",
    },
  ];

  const manifests = (event.data && event.data.manifests) ?? [];
  const TotalRow = {
    render: () => (
      <>
        <TableRow
          columns={columns.map((col) => ({
            ...col,
            editable: false,
          }))}
          rowData={{
            name: "TOTAL",
            qty: manifests.reduce((acc, curr) => acc + curr.qty, 0),
            kills: manifests.reduce((acc, curr) => acc + curr.kills, 0),
            comps: manifests.reduce((acc, curr) => acc + curr.comps, 0),
            paid: manifests.reduce((acc, curr) => acc + curr.paid, 0),
            available: manifests.reduce((acc, curr) => acc + curr.available, 0),
            price: manifests.reduce((acc, curr) => acc + curr.price, 0),
            ticket_fees: manifests.reduce(
              (acc, curr) => acc + curr.ticket_fees,
              0
            ),
            gross: manifests.reduce((acc, curr) => acc + curr.gross, 0),
          }}
          rowContainerClass="bg-blue-200"
        />
      </>
    ),
  };
  return (
    <>
      <AppTable
        grid
        tableTitle="SHOW 1"
        customRows={[TotalRow]}
        onCellBlur={async (rowIndex, accessor, value) => {
          console.log("rowIndex,accessor,value", rowIndex, accessor, value);
          const manifestItem = manifests[rowIndex];
          await updateManifestMutation.mutate({
            ...manifestItem,
            [accessor]: value,
          });
          //   event.refetch();
        }}
        columns={columns}
        data={manifests.map((manifest) => ({
          name: manifest.name,
          qty: manifest.qty,
          kills: manifest.kills,
          comps: manifest.comps,
          paid: manifest.paid,
          available: manifest.available,
          price: manifest.price,
          gross: manifest.price * manifest.paid,
        }))}
      />
    </>
  );
}

import React, { useState } from 'react';
import { createMessage } from "@/queries/bb";

export const ChallengeHold = ({ event, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState(null);

  const handleInterestClick = async () => {
    setIsLoading(true);
    setError(null);
    
    const messageData = {
      sender_id: user.data.id, 
      recipient_id: event.data.buyer.id,
      content: `${user.data.first_name} is challenging your hold of ${event.data.name} at ${event.data.venue.name} on the ${event.data.date}`,
    };

    try {
      await createMessage(messageData);
      setIsSent(true);
    } catch (err) {
      console.error('Error sending message:', err);
      setError('Failed to send your interest. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {event.isSuccess && event.data.buyer.id !== user.data.id && (
        <div className="w-full p-6 border border-red-600 bg-red-100">
          {!isSent ? (
            <button
              onClick={handleInterestClick}
              disabled={isLoading}
              className={`mt-2 ml-4 rounded-md px-2.5 py-1.5 text-sm font-normal shadow-sm ${
                isLoading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-cave-orange text-white'
              }`}
            >
              {isLoading ? 'Sending...' : "I'm Interested"}
            </button>
          ) : (
            <div className="mt-2 ml-4 text-green-700">
              Your interest has been sent! Please wait for a reply.
            </div>
          )}
          {error && (
            <div className="mt-2 ml-4 text-red-700">
              {error}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ChallengeHold;


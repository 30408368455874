import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';

// Function to determine if dark mode is enabled based on local storage or system preference
const isDark = () => {
  return localStorage.getItem('color-theme') === 'dark' || 
         (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);
};

const determineIcon = (dark) => {
  return dark ? MoonIcon : SunIcon;
};

// Function to apply the theme based on URL and dark mode setting
const applyTheme = (dark, location) => {
  const forceLightModePatterns = [
    /^\/events\/settlement\/artist\/\d+$/,
    /^\/events\/settlement\/\d+$/,
    /^\/events\/edit\/offer\/\d+$/,
  ];

  const forceDarkModePatterns = [
    /^\/dashboard\/?$/,
    /^\/calendar\/?$/
  ];

  const shouldForceLightMode = forceLightModePatterns.some(pattern => pattern.test(location.pathname));
  const shouldForceDarkMode = forceDarkModePatterns.some(pattern => pattern.test(location.pathname));

  if (shouldForceLightMode) {
    document.documentElement.classList.remove('dark');
  } else if (shouldForceDarkMode) {
    document.documentElement.classList.add('dark');
  } else {
    if (dark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }
};

export const DarkToggle = () => {
  const [dark, setDark] = useState(isDark());
  const location = useLocation();

  // Determine the correct icon based on the dark mode state
  let Icon = determineIcon(dark);

  // Use effect to apply theme when location or dark mode changes
  useEffect(() => {
    const isDark = localStorage.getItem('color-theme') === 'dark';
    setDark(isDark);
    applyTheme(isDark, location);  // Reapply theme whenever location changes
  }, [location]);

  const handleButtonPress = () => {
    const newDark = !dark;
    setDark(newDark);
    applyTheme(newDark, location);
    localStorage.setItem('color-theme', newDark ? 'dark' : 'light');
  };

  return (
    <>
      <button
        type="button"
        data-dropdown-toggle="apps-dropdown"
        className="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700"
        onClick={handleButtonPress}  // Toggle dark mode on button press
      >
        <span className="sr-only">Toggle dark mode</span>
        <Icon className="w-6 h-6 hover:text-blue-500" />
      </button>
    </>
  );
};


import React from "react";
import { getMessages, removeMessage, dismissEvent } from "@/queries/bb";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { getEventsBBList, getEventsSettings } from "@/queries/events";
import { PeopleIcon } from "@/components/People/PeopleIcon";
import { usePageTitle } from "@/utils/pagetitle";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const BulletinBoard = () => {
  usePageTitle("Bulletin Board");
  const queryClient = useQueryClient();

  // Fetch bulletin board messages
  const {
    data: messages,
    isLoading: isLoadingMessages,
    error: errorMessages,
  } = useQuery(["messages"], getMessages);

  // Fetch other data
  const {
    data: otherData,
    isLoading: isLoadingOther,
    error: errorOther,
  } = useQuery(["events","bb_list"], getEventsBBList);

  const removeBulletinMessageMutation = useMutation(removeMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["messages", "combinedData"]);
    },
  });

  const dismissEventMutation = useMutation(dismissEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(["otherData", "combinedData"]);
    },
  });

  if (isLoadingMessages || isLoadingOther)
    return <div>Loading messages...</div>;
  if (errorMessages || errorOther) return <div>Error loading messages.</div>;

  // Normalize and combine data
  const normalizedMessages = messages.map((msg) => ({
    id: msg.id,
    source: "bulletin",
    msg: msg.content,
    msgdate: msg.created_at,
    buyer: `User ${msg.sender_id}`, // Replace with sender's name if available
    buyer_id: msg.sender_id,
    buyer_image_url: null, // Or fetch the user's image if available
    event_id: null,
    is_sticky: msg.is_sticky,
  }));

  const normalizedOtherData = otherData.map((item) => ({
    id: item.id,
    source: "event",
    msg: `${item.name}@${item.venue_name}`,
    msgdate: item.confirmed_date,
    buyer: item.buyer,
    buyer_id: item.buyer_id,
    buyer_image_url: item.buyer_image_url,
    event_id: item.id,
    is_sticky: false,
  }));

  const combinedData = [...normalizedMessages, ...normalizedOtherData];

  // Sort combined data by date in descending order
  combinedData.sort((a, b) => new Date(b.msgdate) - new Date(a.msgdate));

  return (
    <div className="h-fit bg-[#F4F2E9] w-full rounded-xl flex flex-col justify-between overflow-hidden">
      <div className="h-10 dark:bg-cave-gray-600 bg-cave-gray-100 px-5 flex items-center justify-start">
        <p className="dark:text-gray-50 text-gray-500 font-bold">
          Bulletin Board
        </p>
      </div>
      <div className="max-h-96 overflow-y-auto">
          {combinedData.map((item, index) => (
            <div
              key={`${item.source}-${item.id}-${item.msgdate}`}
              className={`flex items-center w-full h-[100px] ${
                index % 2 === 0 ? "bg-[#FFFBE6]" : "bg-[#3F3F3F]"
              }`}
            >
              <div className="flex items-center">
                <div className="w-[55px] h-[55px] ml-[20px] bg-[#B3B3B3] rounded-full flex-shrink-0 overflow-hidden">
                  {item.buyer_id && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/people/${item.buyer_id}`}
                    >
                      {/* Replace with actual image or component */}
                      <img
                        src={item.buyer_image_url || "/default-profile.png"}
                        alt="Profile"
                        className="w-full h-full object-cover"
                      />
                    </a>
                  )}
                </div>
                <p
                  className={`ml-[20px] font-inter font-medium text-[15px] leading-[18px] ${
                    index % 2 === 0 ? "text-[#000000]" : "text-[#FFFBE6]"
                  }`}
                >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/events/${item.event_id}`}
                    >
                  {item.msg}
                    </a>
                </p>
              </div>
              <div className="flex items-center ml-auto mr-[20px]">
                <span
                  className={`font-inter font-normal text-[15px] leading-[18px] ${
                    index % 2 === 0 ? "text-[#3F3F3F]" : "text-[#FFFBE6]"
                  }`}
                >
                  {new Date(item.msgdate).toLocaleDateString()}
                </span>
                {!item.is_sticky && (
                  <button
                    onClick={() => {
                      if (item.source === "bulletin") {
                        removeBulletinMessageMutation.mutate(item.id);
                      } else if (item.source === "event") {
                        dismissEventMutation.mutate(item.event_id);
                      }
                    }}
                    className="ml-4 text-[#EC4641] hover:text-red-700"
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      <div className="h-10 dark:bg-cave-gray-600 bg-cave-gray-100 flex justify-between items-center w-100 pr-24 pl-24">
        <a
          href="https://www.facebook.com/mammothlive"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF className="text-xl dark:text-gray-50 text-gray-500" />
        </a>

        {/* add .png here */}
        <a
          href="https://www.mammothlive.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
       <img
         src="/static/images/mammoth-logo-sm-trans-black.png"
         className="h-6 w-auto dark:hidden"
       />
       <img
         src="/static/images/mammoth-logo-sm-trans-white.png"
         className="hidden h-6 w-auto dark:block"
       />
        </a>
        <a
          href="https://www.instagram.com/mammothlive/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="text-xl dark:text-gray-50 text-gray-500" />
        </a>
        <a
          href="https://twitter.com/MammothLive"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter className="text-xl dark:text-gray-50 text-gray-500" />
        </a>
      </div>
 
    </div>
  );
};

export default BulletinBoard;


import { RowData } from "@tanstack/react-table";
import { useState } from "react";
import SettlementVerticalTable from "./SettlementVerticalTable";
import SupportArtistSupports from "./SettlementArtistSupports";
import SettlementArtistAdditionals from "./SettlementArtistAdditionals";
import DealAndFinalFigures from "./SettlementArtistDealSection";
import { TableRow } from "../AppTable2";

const ExamplePage: React.FC = () => {
  const [data, setData] = useState({
    showExpenses: {
      title: "Show Expenses",
      headers: [
        "Expense",
        "Notes",
        "Mammoth",
        "Venue",
        "Artist",
        "Partner 1",
        "Partner 2",
        "Totals",
        "Offer",
        "Difference",
      ],
      data: [
        {
          Expense: "Loverboy",
          Notes: "Main Act",
          Mammoth: 65000,
          Venue: 50000,
          Artist: "Foreigner",
          "Partner 1": "",
          "Partner 2": "",
          Totals: 112088.53,
          Offer: 65000,
          Difference: 0,
        },
        {
          Expense: "TBD",
          Notes: "Opening Act",
          Mammoth: 1000,
          Venue: 500,
          Artist: "Unknown",
          "Partner 1": "",
          "Partner 2": "",
          Totals: 1500,
          Offer: 1000,
          Difference: 0,
        },
        // Add other rows...
      ],
      editableKeys: ["Mammoth", "Venue"],
    },
    headlinerSettlement: {
      title: "Headliner Settlement",
      data: [
        {
          key: "NetReceipts",
          label: "Net Receipts",
          value: -79288.99,
          isEditable: false,
        },
        {
          key: "Guarantee",
          label: "Guarantee",
          value: 125000,
          isEditable: true,
        },
        {
          key: "Deductions",
          label: "Deductions",
          value: -133850,
          isEditable: true,
        },
        { key: "Total", label: "Total", value: -79488.99, isEditable: false },
      ],
    },
    artistDeductions: {
      title: "Artist Deductions",
      headers: ["Item", "Paid By", "Amount"],
      data: [
        { key: "Deposit", label: "Deposit", value: 13850, isEditable: true },
        { key: "Cash", label: "Cash", value: 10000, isEditable: true },
        // Add other rows as needed
      ],
    },
    supportSettlement: {
      title: "Support Settlements",
      headers: ["Guarantee", "Plus Artist Adjustments"],
      data: [
        {
          key: "Support1_Guarantee",
          label: "Support 1",
          value: 65000,
          isEditable: true,
        },
        {
          key: "Support1_Adjustments",
          label: "Support 1 Adjustments",
          value: 0,
          isEditable: false,
        },
        {
          key: "Support2_Guarantee",
          label: "Support 2",
          value: 1000,
          isEditable: true,
        },
        {
          key: "Support2_Adjustments",
          label: "Support 2 Adjustments",
          value: 0,
          isEditable: false,
        },
        {
          key: "Support3_Guarantee",
          label: "Support 3",
          value: 2000,
          isEditable: true,
        },
        {
          key: "Support3_Adjustments",
          label: "Support 3 Adjustments",
          value: 0,
          isEditable: false,
        },
      ],
    },
  });

  const handleVerticalBlur = (
    sectionKey: keyof typeof data,
    key: string,
    newValue: string | number
  ) => {
    const updatedData = { ...data };
    const row = (updatedData[sectionKey].data as RowData[]).find(
      (row) => row.key === key
    );
    if (row) row.value = newValue;
    setData(updatedData);
  };

  return (
    <div>
      <DealAndFinalFigures />
      <div className="flex flex-col space-y-8">
        {/* Show Expenses Table */}
        <SettlementArtistAdditionals />
        <div>
          <SettlementVerticalTable
            className="w-[24%]"
            title="PROMOTER PROFIT (LOSS)"
            data={[
              {
                label: "PROFIT (LOSS)",
                key: "profit",
                value: "$204,288.99",
              },
              {
                label: "ARTIST CHARGEBACKS",
                key: "chargebacks",
                value: "-",
              },
            ]}
            onBlur={() => console.log("")}
          />
          <TableRow
            rowContainerClass="w-[24%] bg-blue-200"
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "TOTAL P&L:",
              value: "$94949",
            }}
          />
        </div>
        <SupportArtistSupports />
      </div>
    </div>
  );
};

export default ExamplePage;

import React from "react";
import ExpenseTable from "../ExpensesTable";
import SettlementArtistTheDeal from "./SettlementArtistTheDeal";
import SettlementTicketManifests from "./SettlementTicketManifests";
import SettlementWireInfo from "./SettlementWireInfo";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";

export default function SettlementArtistPage() {
  const { event, isPDF } = useSettlementComponent();
  if (!event.data) return null;
  return (
    <div>
      <SettlementTicketManifests />
      <SettlementArtistTheDeal />
      <ExpenseTable id={event.data?.id} book={"artist"} isPDF={isPDF} />
      <SettlementWireInfo />
    </div>
  );
}

import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
} from "./base";

export const getPeopleSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getPeople = async () => {
  const url = `/api/people/`;
  return await getApiRequest(url);
};

export const getStaff = async () => {
  const url = `/api/people/staff/`;
  return await getApiRequest(url);
};

export const getPerson = async (id) => {
  const url = `/api/people/${id}/`;
  return await getApiRequest(url);
};

export const updatePerson = async (id, data) => {
  const url = `/api/people/${id}/`;
  return await putApiRequest(url, data);
};

export const createPerson = async (data) => {
  const url = `/api/people/`;
  return await postApiRequest(url, data);
};

export const getPersonEventsRoles = async (id, isPast) => {
  const url = `/api/people/${id}/events-roles/`;
  return await getApiRequest(url);
};

export const getPeopleFileSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getPeopleFiles = async (id) => {
  const url = `/api/people/files/${id}/`;
  return await getApiRequest(url);
};

export const createPeopleFile = async (id, data) => {
  const url = `/api/people/files/${id}/`;
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  const resp = await makeRequest(url, "POST", headers, data);
  return resp.json();
};

export const deletePeopleFile = async (id) => {
  const url = `/api/people/file/${id}/`;
  return await deleteApiRequest(url);
};

export const deletePeopleImage = async (id) => {
  const url = `/api/people/images/delete/${id}/`;
  return await deleteApiRequest(url);
};

export const createPeopleImages = async (peopleId, data) => {
  console.log("createPeopleImage called with peopleId:", peopleId);
  console.log("Data to be sent:", data);
  const url = `/api/people/images/${peopleId}/`;
  console.log("Request URL:", url);
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  try {
    const resp = await makeRequest(url, "POST", headers, data);
    const jsonResponse = await resp.json();
    console.log("createPeopleImage response:", jsonResponse);
    return jsonResponse;
  } catch (error) {
    console.error("Error in createPeopleImage:", error);
    throw error;
  }
};

import React from 'react';

export function ArtistIcon({ artist }) {
  const getImageUrl = () => {
    if (artist?.data?.image_url) {
      return artist.data.image_url;
    } else if (artist?.people_images && artist.people_images.length > 0) {
      return artist.people_images[0].image;
    } else {
      return artist.image_url || null;
    }
  };

  const imageUrl = getImageUrl();

  const initials = artist?.first_name && artist?.last_name 
    ? `${artist.first_name[0]}${artist.last_name[0]}`.toUpperCase()
    : artist?.first_name
      ? artist.first_name[0].toUpperCase()
      : '?';


  return (
    <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
      {imageUrl ? (
        <div
          className="w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url(${imageUrl})` }}
        ></div>
      ) : (
        <span className="text-xl font-semibold text-gray-500">
          {initials}
        </span>
      )}
    </div>
  );
}

// Keep the old version for backwards compatibility
export function AArtistIcon({ imageUrl }) {
  return (
    <div
      className="w-12 h-12 rounded-full bg-cover bg-center"
      style={{ backgroundImage: `url(${imageUrl})` }}
    ></div>
  );
}

import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { Artist } from "@/types/event";
import React, { useEffect, useState } from "react";
import MammothLogo from "../MammothLogo";
import { MAMMOTH_DETAILS } from "@/constants";
export const getCSRFToken = async () => {
  const resp = await fetch("/api/csrf/", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await resp.json();
  return data.token;
};
const getArtist = async (artistId: number) => {
  const token = await getCSRFToken();
  // THE / AT THE END OF THE URL IS NECESSARY!
  const res = await fetch(`/api/artists/${artistId}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": token,
    },
  });
  const data = await res.json();
  return data;
};

const TitleAndInputRow = ({
  title,
  initialValue,
  textCenter,
  onChange,
  onBlur,
  hideTitle = false,
}: {
  title: string;
  hideTitle?: boolean;
  initialValue: string;
  textCenter?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}) => {
  const [value, setValue] = React.useState(initialValue);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event);
    setValue(event.target.value);
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(event);
    setValue(event.target.value);
  };

  return (
    <div className="flex">
      {title && !hideTitle && (
        <h3 className="text-xs w-24 text-right">{title}</h3>
      )}
      <input
        className={
          "text-xs hover:ring duration-200 self-start bg-transparent font-semibold px-2 text-red-700 " +
          (textCenter && "text-center")
        }
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

const HeaderGridCol4 = ({
  title,
  initialValues,
  onChange,
  onBlur,
}: {
  title: string;
  initialValues: string[];
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    phoneState: string,
    emailState: string
  ) => void;
  onBlur: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    phoneState: string,
    emailState: string
  ) => void;
}) => {
  const [values, setValues] = React.useState(initialValues);

  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValues = [...values];
    if (onChange) {
      onChange(event, index, newValues[1], newValues[2]);
    }
    //passing the phone and email state because partial updates makes the other fields null
    newValues[index] = event.target.value;

    setValues(newValues);
  };

  const handleBlur = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValues = [...values];
    //passing the phone and email state because partial updates makes the other fields null
    onBlur(event, index, newValues[1], newValues[2]);
    newValues[index] = event.target.value;

    setValues(newValues);
  };

  return (
    <div className="grid grid-cols-4">
      <span className="font-bold">{title}</span>
      {values.map((value, index) => (
        <input
          key={index}
          type="text"
          className="bg-input-blue text-center text-red-600"
          value={value}
          onChange={(event) => handleChange(index, event)}
          onBlur={(event) => handleBlur(index, event)}
        />
      ))}
    </div>
  );
};
export default function SettlementHeader() {
  const { event, handleUpdateEvent } = useSettlementComponent();
  if (!event.data) return null;
  const [city, setCity] = useState(event.data?.venue.city);
  const [state, setState] = useState(event.data?.venue.state);
  const [support1, setSupport1] = useState(event.data?.offer_support_1);
  const [support2, setSupport2] = useState(event.data?.offer_support_2);
  const [headliner, setHeadliner] = useState(event.data?.offer_headliner);
  const [talentBuyerName, setTalentBuyerName] = useState(
    event.data?.buyer.first_name + " " + event.data?.buyer.last_name
  );
  const [artist, setArtist] = useState<Partial<Artist>>({});
  useEffect(() => {
    if (event.data) {
      //Setting the city or state only in a JSON request makes the other one null. Thats why we keep it as a special state
      setCity(event.data.venue.city);
      setState(event.data.venue.state);
      setSupport1(event.data.offer_support_1);
      setSupport2(event.data.offer_support_2);
      setHeadliner(event.data?.offer_headliner);
      setTalentBuyerName(
        event.data?.buyer.first_name + " " + event.data?.buyer.last_name
      );
      getArtist(event.data.artists[0].id).then((data) => setArtist(data));
    }
  }, [event.data]);
  return (
    <div className="w-full flex justify-between mb-5 border-2 border-black py-2 pl-4">
      {/* START LEFT SIDE */}
      <div className="w-2/5 flex gap-8">
        <div className="font-bold space-y-4">
          <MammothLogo width="225" />
          <div className="space-y-2 text-center">
            <p className="font-montserrat">{MAMMOTH_DETAILS.ADDRESS}</p>
            <p>
              {MAMMOTH_DETAILS.CITY}, {MAMMOTH_DETAILS.STATE}{" "}
              {MAMMOTH_DETAILS.ZIPCODE}
            </p>
            <p className="font-montserrat">{MAMMOTH_DETAILS.PHONE}</p>
          </div>
        </div>
        <div className="w-2/3">
          <div className="flex flex-col space-y-2">
            {/*   <TitleAndInputRow
              title="COMPANY DIVISION"
              initialValue="Mammoth"
            /> */}
            <TitleAndInputRow
              title="HEADLINER"
              initialValue={headliner ?? event.data.offer_headliner}
              onBlur={async (e) => {
                setHeadliner(e.target.value);
                await handleUpdateEvent(event.data.id, {
                  offer_headliner: e.target.value,
                  offer_support_1: support1,
                  offer_support_2: support2,
                  name: event.data.name,
                  date: event.data.date,
                  charity: event.data.charity,
                });
              }}
            />
            {/*    <TitleAndInputRow title="CO-HEADLINER" initialValue="" /> */}
            <TitleAndInputRow
              title="SUPPORT"
              initialValue={event.data.offer_support_1}
              onBlur={async (e) => {
                await handleUpdateEvent(event.data.id, {
                  offer_support_1: e.target.value,
                  offer_support_2: support2,
                  name: event.data.name,
                  date: event.data.date,
                  charity: event.data.charity,
                });
              }}
            />
            <TitleAndInputRow
              title="SUPPORT"
              initialValue={event.data.offer_support_2}
              onBlur={async (e) => {
                setSupport2(e.target.value);
                await handleUpdateEvent(event.data.id, {
                  offer_support_1: support1,
                  offer_support_2: e.target.value,
                  name: event.data.name,
                  date: event.data.date,
                  charity: event.data.charity,
                });
              }}
            />
            <TitleAndInputRow
              title="DATE"
              initialValue={event.data.date}
              onBlur={async (e) => {
                await handleUpdateEvent(event.data.id, {
                  offer_support_1: support1,
                  offer_support_2: support2,
                  name: event.data.name,
                  date: e.target.value,
                  charity: event.data.charity,
                });
              }}
            />
            <TitleAndInputRow
              title="VENUE"
              initialValue={event.data.venue.name}
              onBlur={async (e) => {
                await handleUpdateEvent(event.data.id, {
                  venue: e.target.value,
                });
              }}
            />
            <TitleAndInputRow
              title="CITY"
              initialValue={city ?? event.data.venue.city}
              onBlur={async (e) => {
                setCity(e.target.value);
                await handleUpdateEvent(event.data.id, {
                  city: e.target.value,
                  state,
                });
              }}
            />
            <TitleAndInputRow
              title="STATE"
              initialValue={state ?? event.data.venue.state}
              onBlur={async (e) => {
                setState(e.target.value);
                await handleUpdateEvent(event.data.id, {
                  state: e.target.value,
                });
              }}
            />
            <TitleAndInputRow
              title="TALENT BUYER"
              initialValue={talentBuyerName ?? event.data.buyer.last_name}
              onBlur={async (e) => {
                setTalentBuyerName(e.target.value);
                await handleUpdateEvent(event.data.id, {
                  buyer: {
                    first_name: e.target.value.split(" ")[0],
                    last_name: e.target.value.split(" ")[1],
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      {/* END LEFT SIDE */}

      {/* START RIGHT SIDE */}
      <div className="w-2/4">
        <div className="flex">
          <TitleAndInputRow title="# OF SHOWS:" initialValue="1" textCenter />
        </div>
        <div className="my-4 grid grid-cols-4">
          <h3 className="font-bold">SETTLEMENT CONTACTS:</h3>
          <h3 className="underline text-center">NAME</h3>
          <h3 className="underline text-center">PHONE</h3>
          <h3 className="underline text-center">EMAIL</h3>
        </div>
        {/*  <HeaderGridCol4
          title="MAMMOTH:"
          initialValues={[
            "Russ Martin",
            "785.760.3002",
            "rmartin@mammothlive.com",
          ]}
        /> */}
        <HeaderGridCol4
          title="ARTIST:"
          initialValues={[
            event.data.artists[0].name,
            event.data.artists[0].phone,
            event.data.artists[0].email,
          ]}
          onBlur={async (e, index, phoneState, emailState) => {
            const token = await getCSRFToken();
            const res = await fetch(
              `/api/artists/${artist.id}/`,

              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRFToken": token,
                },
                body: JSON.stringify(
                  index == 0
                    ? {
                        name: e.target.value,
                        email: emailState,
                        phone: phoneState,
                      }
                    : index == 1
                      ? {
                          phone: e.target.value,
                          name: artist.name,
                          email: emailState,
                        }
                      : {
                          email: e.target.value,
                          name: artist.name,
                          phone: phoneState,
                        }
                ),
              }
            );
            const data = await res.json();
            return data;
          }}
        />
        {/* <HeaderGridCol4
          title="BUILDING:"
          initialValues={[
            "Juliet Trieloff",
            "312.341.2378",
            "jtrieloff@auditoriumtheatre.org",
          ]}
        /> */}

        <div className="mt-5">
          {artist && artist.vendors && artist.vendors.length > 0 && (
            <HeaderGridCol4
              title="ARTIST AGENCY:"
              onBlur={async (e) => {
                const token = await getCSRFToken();
                const res = await fetch(
                  `/api/vendors/${artist.vendors[0].id}/`,
                  {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                      "X-CSRFToken": token,
                    },
                    body: JSON.stringify({ name: e.target.value }),
                  }
                );
                const data = await res.json();
                return data;
              }}
              initialValues={[artist.vendors[0].name]}
            />
          )}
        </div>
      </div>
      {/* END RIGHT SIDE */}
    </div>
  );
}

import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { getEventPDFs, createEventPDF } from "@/queries/events";
import { CommonSettlementProps, SettlementTab } from "@/types/settlement";
import { useQuery, useMutation } from "@tanstack/react-query";
import React, { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-toastify";

export default function SettlementTabs() {
  const { event, pageIndex, setPageIndex, queryClient } =
    useSettlementComponent();

  const [isRefetchingPDF, setIsRefetching] = useState(false);
  const [isPDFGenerated, setIsPDFGenerated] = useState(false);
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [pdfType, setPDFType] = useState<SettlementTab>(pageIndex);
  const id = event?.data?.id ?? "";
  const pdfs = useQuery(["event-pdfs", id], () => getEventPDFs(id), {
    refetchInterval: () => (isRefetchingPDF ? 500 : false),
  });
  const createPDFMutation = useMutation({
    mutationFn: (data: { name: string; file_type: string; note: string }) => {
      console.dir(data);
      return createEventPDF(id, data);
    },
    onError: (error, variables, context) => {
      toast.error("Error generating PDF");
      console.dir(error);
      console.dir(variables);
      console.dir(context);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(["event-pdfs", id]);
      // Restart our countdown
      setIsRefetching(true);
      setTimeout(() => {
        setIsRefetching(false);
        setIsPDFGenerated(true);
        toast.success("PDF generated successfully");
      }, 10000);
    },
  });
  const pdfFileType = () => `${pageIndex}_settlement`;
  const handleCreatePDF = () => {
    const data = {
      name: pdfFileType(),
      file_type: pdfFileType(),
      note: "",
    };
    createPDFMutation.mutate(data);
  };
  const availablePdfs = () => {
    if (!pdfs.data?.length) {
      return [];
    }
    return pdfs.data?.filter((pdf) => pdf.file_type === pdfFileType());
  };
  const handleViewPDF = () => {
    if (!pdfs.data?.length) return toast.error("No PDFs generated yet");
    const lastGeneratedPDF = availablePdfs().pop();
    if (lastGeneratedPDF) {
      window.open(lastGeneratedPDF.file_url, "_blank", "noopener,noreferrer");
    }
  };

  const getPDFButtonText = () => {
    if (isRefetchingPDF) {
      return (
        <span className="flex items-center gap-2">
          Generating... &nbsp;&nbsp;
          <div className="spinner"></div>
        </span>
      );
    } else if (availablePdfs()?.length > 0) return "Regenerate PDF";
    else return "Create Offer PDF";
  };
  return (
    <div className="w-full fixed bottom-0 bg-white text-lg flex gap-3 py-1.5">
      <button
        className={`${pageIndex == "artist" ? "bg-blue-600 text-white" : "hover:bg-blue-400 duration-200"} rounded-md p-2`}
        onClick={() => setPageIndex("artist")}
      >
        ARTIST
      </button>
      <button
        className={`${pageIndex == "copro" ? "bg-blue-600 text-white" : "hover:bg-blue-400 duration-200"} rounded-md p-2`}
        onClick={() => setPageIndex("copro")}
      >
        COPRO
      </button>
      <button
        className={`${pageIndex == "final" ? "bg-blue-600 text-white" : "hover:bg-blue-400 duration-200"} rounded-md p-2`}
        onClick={() => setPageIndex("final")}
      >
        FINAL
      </button>

      <button
        onClick={handleCreatePDF}
        className="rounded-md p-2 bg-green-400 text-white hover:bg-green-500 duration-200"
        disabled={isRefetchingPDF}
      >
        {getPDFButtonText()}
      </button>
      <button
        onClick={handleViewPDF}
        className={`rounded-md p-2 bg-blue-400 text-white hover:bg-blue-500 duration-200 ${isRefetchingPDF || !availablePdfs()?.length ? "pointer-events-none opacity-75" : ""}`}
        disabled={!availablePdfs()?.length || isRefetchingPDF}
      >
        View PDF
      </button>
    </div>
  );
}

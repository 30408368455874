import React from "react";
import SettlementInputField from "./SettlementInputField";
import SettlementTableTitle from "./SettlementTableTitle";

type RowData = {
  key: string;
  label: string;
  value: string | number;
  isEditable?: boolean;
  format?: "text" | "money";
};

type SettlementVerticalTableProps = {
  title?: string;
  headers?: string[];
  data: RowData[];
  onBlur: (key: string, newValue: string | number) => void;
  onChange?: (key: string, newValue: string | number) => void;
  className?: string;
};

const SettlementVerticalTable: React.FC<SettlementVerticalTableProps> = ({
  title,
  headers,
  data,
  onBlur,
  onChange,
  className,
}) => {
  const handleFieldChange = (key: string, newValue: string | number) => {
    if (onChange) onChange(key, newValue); // Pass updated value to parent
  };

  return (
    <div className={className}>
      <SettlementTableTitle>{title}</SettlementTableTitle>
      <div className="space-y-4">
        {headers && (
          <div className="grid grid-cols-2 gap-4">
            {headers.map((header, index) => (
              <div key={index} className="font-semibold bg-gray-200 p-2 border">
                {header}
              </div>
            ))}
          </div>
        )}
        <div>
          {data.map((row, index) => (
            <div key={index} className="flex items-center border bg-white">
              <label className="font-medium w-[58%]">{row.label}</label>
              <SettlementInputField
                value={row.value} // Controlled value
                editable={row.isEditable}
                format={row?.format}
                onBlur={() => onBlur(row.key, row.value)} // Handle blur
                onChange={(newValue) => handleFieldChange(row.key, newValue)} // Handle input change
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettlementVerticalTable;

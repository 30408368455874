import {
    getApiRequest,
    postApiRequest,
    putApiRequest,
    deleteApiRequest,
    patchApiRequest,
    jsonHeaders,
    makeRequest,
} from './base'


export const getImages = async (entityType, id, marketingOnly = false) => {
    const url = `/api/${entityType}/images/${id}/${marketingOnly ? '?marketing_only=true' : ''}`;
    return await makeRequest(url, 'GET', await jsonHeaders());
};

export const createImage = async (entityType, parentId, data, queryClient) => {
    console.log(`create${entityType}Image called with parentId:`, parentId);
    const url = `/api/${entityType}/images/${parentId}/`;
    console.log("Request URL:", url);
    const headers = await jsonHeaders(true);
    headers.delete('Content-Type'); // FormData will set Content-Type

    const formData = new FormData();
    for (let [key, value] of data.entries()) {
        if (key === 'image' || key === 'file') { // Ensure correct key for backend
            formData.append('file', value, value.name); // Set 'file' key for Django endpoint
        } else {
            formData.append(key, value);
        }
    }

    try {
        const resp = await makeRequest(url, "POST", headers, formData);
        const jsonResponse = await resp.json();
        console.log(`create${entityType}Image response:`, jsonResponse);

        // Invalidate the query to refresh images
        console.log("hux", queryClient.invalidateQueries([entityType.replace(/s$/, ''), "images", parentId]))
        await queryClient.invalidateQueries([entityType.replace(/s$/, ''), "images", parentId]);

        return jsonResponse;
    } catch (error) {
        console.error(`Error in create${entityType}Image:`, error);
        throw error;
    }
};


export const deleteImage = async (entityType, imageId, queryClient) => {
    console.log(`delete${entityType}Image called with imageId:`, imageId);
    const url = `/api/${entityType}/image/delete/${imageId}/`;
    console.log("Request URL:", url);
    const headers = await jsonHeaders(true);
    try {
        const resp = await makeRequest(url, "DELETE", headers);

        // Check if the response is JSON
        const contentType = resp.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
            const jsonResponse = await resp.json();
            console.log(`delete${entityType}Image response:`, jsonResponse);
            if (!jsonResponse.success) {
                throw new Error("Delete operation failed: " + JSON.stringify(jsonResponse));
            }
            queryClient.invalidateQueries([entityType.replace(/s$/, ''), "images"]);
            return jsonResponse;
        } else {
            // If it's not JSON, it's probably an error
            const textResponse = await resp.text();
            console.error("Unexpected response:", textResponse);
            throw new Error("Unexpected response from server");
        }
    } catch (error) {
        console.error(`Error in delete${entityType}Image:`, error);
        if (error instanceof SyntaxError) {
            console.error("Response was not valid JSON. Full response:", await error.response.text());
        }
        throw error;
    }
};

import {
    getApiRequest,
    postApiRequest,
    putApiRequest,
    deleteApiRequest,
    patchApiRequest,
    jsonHeaders,
    makeRequest,
} from './base'

export const getArtistsSettings = {
    cacheTime: 30 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
}

export const getArtists = async () => {
    const url = `/api/artists/`
    return await getApiRequest(url)
}

export const updateArtist = async (id, data) => {
    const url = `/api/artists/${id}/`
    return await putApiRequest(url, data)
}

export const createArtist = async (data) => {
    const url = `/api/artists/`
    return await postApiRequest(url, data)
}

export const getArtist = async (id) => {
    const url = `/api/artists/${id}/`
    return await getApiRequest(url)
}

export const getArtistPeople = async (id) => {
    const url = `/api/artists/people/${id}/`
    return await getApiRequest(url)
}

export const getArtistRoles = async () => {
    const url = `/api/artists/people/roles/`
    return await getApiRequest(url)
}

export const createArtistPeople = async (id, data) => {
    const url = `/api/artists/people/add/${id}/`
    return await postApiRequest(url, data)
}

export const toggleArtistMainPOC = async (id) => {
    const url = `/api/artists/people/toggle-main-contact/${id}/`
    return await postApiRequest(url)
}

export const getArtistEvents = async (id) => {
    const url = `/api/artists/events/${id}/`
    return await getApiRequest(url)
}

export const getArtistPastEvents = async (id) => {
    const url = `/api/artists/events/past/${id}/`
    return await getApiRequest(url)
}

export const getArtistLinkSettings = {
    cacheTime: 30 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
}

export const getArtistLinks = async (id) => {
    const url = `/api/artists/links/${id}/`
    return await getApiRequest(url)
}

export const createArtistLink = async (id, data) => {
    const url = `/api/artists/links/${id}/`
    return await postApiRequest(url, data)
}

export const deleteArtistLink = async (id) => {
    const url = `/api/artists/link/${id}/`
    return await deleteApiRequest(url)
}

export const getArtistCommentSettings = {
    cacheTime: 10 * 60 * 1000,
    staleTime: 2 * 60 * 1000,
}

export const getArtistComments = async (id) => {
    const url = `/api/artists/comments/${id}/`
    return await getApiRequest(url)
}

export const createArtistComment = async (id, data) => {
    const url = `/api/artists/comments/${id}/`
    return await postApiRequest(url, data)
}

export const getArtistFileSettings = {
    cacheTime: 30 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
}

export const getArtistFiles = async (id) => {
    const url = `/api/artists/files/${id}/`
    return await getApiRequest(url)
}

export const createArtistFile = async (id, data) => {
    const url = `/api/artists/files/${id}/`
    const headers = await jsonHeaders(true)
    headers.delete('Content-Type')
    const resp = await makeRequest(url, "POST", headers, data)
    return resp.json()
}

export const deleteArtistFile = async (id) => {
    const url = `/api/artists/file/${id}/`
    return await deleteApiRequest(url)
}

export const getArtistImageSettings = {
    cacheTime: 10 * 60 * 1000,
    staleTime: 2 * 60 * 1000,
}

export const getArtistImages = async (id) => {
    const url = `/api/artists/images/${id}/`
    return await getApiRequest(url)
}

export const createArtistImage = async (id, data) => {
    const url = `/api/artists/images/add/${id}/`
    const headers = await jsonHeaders(true)
    headers.delete('Content-Type')
    const resp = await makeRequest(url, "POST", headers, data)
    return resp.json()
}

export const deleteArtistImage = async (id) => {
    const url = `/api/artists/images/delete/${id}/`
    return await deleteApiRequest(url)
}

export const createArtistImages = async (artistId, data) => {
    console.log("createArtistImage called with artistId:", artistId);
    console.log("Data to be sent:", data);
    const url = `/api/artists/images/${artistId}/`;
    console.log("Request URL:", url);
    const headers = await jsonHeaders(true);
    headers.delete('Content-Type');
    try {
        const resp = await makeRequest(url, "POST", headers, data);
        const jsonResponse = await resp.json();
        console.log("createArtistImage response:", jsonResponse);
        return jsonResponse;
    } catch (error) {
        console.error("Error in createArtistImage:", error);
        throw error;
    }
};

import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Header } from "@/components/Header";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { HoldSwitcher } from "@/components/EventState/HoldSwitcher";
import { ChallengeHold } from "@/components/ChallengeHold";
import { toast } from "react-toastify";
import {
  getEvent,
  getEventLinks,
  getEventLinkSettings,
  deleteEventLink,
  getEventComments,
  getEventCommentSettings,
  createEventComment,
  getEventFiles,
  getEventFileSettings,
  deleteEventFile,
  toggleEventMainPOC,
  getEventPeople,
  recordTickets,
  getEventImages,
  getEventImageSettings,
  deleteEventImage,
  createEventImages,
  postEventDuplicate,
} from "@/queries/events";
import { useRecordVisit } from "@/queries/visit";
import { useParams, useNavigate } from "react-router-dom";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { amountDisplay } from "@/utils/money";
import {
  PencilSquareIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import EventAdvance from "@/components/EventDisplay/EventAdvance";
import EventState from "@/components/EventState";
import DuplicateEventButton from "@/components/DupeEvent";
import People from "@/components/People/People";
import { Expenses, RollUp, SectionHeader } from "./accountingComponents";
import LinkDisplay from "@/components/Links/LinkDisplay";
import CommentDisplay from "@/components/Comments/CommentDisplay";
import FileDisplay from "@/components/Files/FileDisplay";
import ImageDisplay from "@/components/Images/ImageDisplay";
import { useUser, usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";
import EventPDF from "./EventPDF";
import classNames from "classnames";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "@/components/Form";
import Button from "@/components/Button";
import {
  addEventToOffer,
  createOffer,
  getEventOfferVariables,
  getOffer,
  getOffers,
  updateEvent,
  deleteEventPeople,
} from "../../queries/events";
import { localTime, formatTimeString } from "@/utils/datetime";
import { getEventRollups, getEventCopros } from "@/queries/accounting";
import { FilterDropdown } from "../../components/FilterDropdown/FilterDropdown";
import { SortedTable } from "../../components/SortedTable/SortedTable";
import { formatInputToMoney } from "../../utils/money";
import { Wires } from "../../components/Wires/Wires";
import styles from "./EventDetail.module.css";

const ItemTable = ({
  rows = [],
  header,
  info,
  columns = [],
  color = "gray",
  highlightedColumn,
  onUpdate,
  tableId,
  showTable,
  columnWidths = ["200px", "auto"],
  showChevrons = true,
}) => {
  const [editableRows, setEditableRows] = useState(rows);

  const thClasses = classNames(
    "cursor-pointer text-center font-bold text-white text-md font-inter",
    { "bg-[#4E6B85] hover:bg-[#3A4F61]": color === "gray" },
    { "bg-red-500 hover:bg-red-700": color === "red" }
  );

  const handleBlur = (accessor, e, row) => {
    onUpdate && onUpdate(accessor, e.target.textContent);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  };

  const handleHeaderChange = (event, rowIndex) => {
    const newRows = [...editableRows];
    newRows[rowIndex].header = event.target.value;
    setEditableRows(newRows);
  };

  return (
    <table
      className="min-w-full rounded-lg overflow-hidden table-fixed"
      style={{ tableLayout: "fixed", width: "100%" }}
    >
      <colgroup>
        {columnWidths.map((width, idx) => (
          <col key={idx} style={{ width }} />
        ))}
      </colgroup>

      <thead>
        {header && (
          <tr className="h-10">
            <th className={thClasses} colSpan={columns.length || 1}>
              <div className="flex justify-center items-center">
                <span>{header.value}</span>
                {showChevrons &&
                  (showTable ? (
                    <ChevronDownIcon className={styles.chevronAccordionDown} />
                  ) : (
                    <ChevronUpIcon className={styles.chevronAccordionUp} />
                  ))}
              </div>
            </th>
          </tr>
        )}
        {showTable && columns.length > 0 && (
          <tr className="bg-[#f3836f]">
            {columns.map((col, idx) => (
              <th
                key={idx}
                className={`border-r border-black text-center dark:text-white uppercase ${styles.heavyFont}`}
              >
                {col.header}
              </th>
            ))}
          </tr>
        )}
      </thead>
      {showTable && (
        <tbody className="w-full">
          {info && (
            <tr className="w-full h-[240px]">
              <td
                colSpan={columns.length || 1}
                className="bg-white border-b border-[black] text-center text-sm font-inter whitespace-pre-line fton-[900] text-[1.2rem] leading-[1.5]"
              >
                {info}
              </td>
            </tr>
          )}
          {editableRows.length > 0 ? (
            editableRows.map((row, index) => (
              <tr
                className={`${
                  index % 2 !== 0 ? "bg-gray-100" : "bg-white"
                } h-12 w-40`}
                key={index}
              >
                {columns.map((col, colIdx) => (
                  <td
                    key={colIdx}
                    className={`border-r border-black p-1 px-3 text-sm font-inter ${
                      colIdx === 0
                        ? "text-black font-[900]"
                        : "text-black font-[400]"
                    }`}
                    contentEditable={colIdx !== 0 && row.editable}
                    suppressContentEditableWarning={true}
                    onBlur={(e) => handleBlur(col.accessor, e, row)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    style={{
                      whiteSpace: "wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {col.accessor === "header" && row.isEditableHeader ? (
                      <input
                        type="text"
                        value={row.header}
                        onChange={(e) => handleHeaderChange(e, index)}
                        className="bg-transparent border-none p-0 m-0 text-center text-black font-[900]"
                        style={{
                          width: "100%",
                          outline: "none",
                          textAlign: "left",
                          textTransform: "uppercase",
                        }}
                      />
                    ) : (
                      row[col.accessor]
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length || 1}
                className="bg-white border-b border-black text-center text-sm font-normal whitespace-pre-line h-12 w-40"
              >
                NO DATA
              </td>
            </tr>
          )}
        </tbody>
      )}
    </table>
  );
};

const ManifestRow = (props) => {
  const { id, canEdit, name, qty, comps, price, available, paid, on_sale } =
    props;
  const { perms } = usePerms();
  const { user } = useUser();
  const navigate = useNavigate();

  return (
    <tr
      className="bg-white border-b dark:bg-g[red] dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      key={id}
    >
      <td className="px-6 py-4 flex flex-inline justify-between">
        {name}
        <div className="p-1 hover:bg-blue-200">
          {canEdit && (
            <PencilSquareIcon
              className="h-4 w-4 hover:text-blue-700"
              onClick={() => navigate(`/events/manifest/edit/${id}`)}
            />
          )}
        </div>
      </td>
      <td className="px-6 py-4">{qty}</td>
      <td className="px-6 py-4">{comps}</td>
      <td className="px-6 py-4">
        {on_sale && dayjs(on_sale).format("MMM D, YYYY")}
      </td>
      <td className="px-6 py-4">{amountDisplay(price)}</td>
      <td className="px-6 py-4">{available}</td>
      <td className="px-6 py-4">{paid}</td>
      <td className="px-6 py-4 text-right">{amountDisplay(price * paid)}</td>
    </tr>
  );
};

const ManifestRollup = (props) => {
  const { id, name, qty, comps, available, paid, total, toggleShowManifests } =
    props;
  return (
    <tr
      className="bg-green-100 cursor-pointer border-b dark:bg-[gray-800] dark:border-gray-700"
      key={id}
      onClick={() => toggleShowManifests()}
    >
      <td className="px-6 py-4 flex flex-inline justify-between">{name}</td>
      <td className="px-6 py-4">{qty}</td>
      <td className="px-6 py-4">{comps}</td>
      <td className="px-6 py-4"></td>
      <td className="px-6 py-4"></td>
      <td className="px-6 py-4">{available}</td>
      <td className="px-6 py-4">{paid}</td>
      <td className="px-6 py-4 text-right">{amountDisplay(total)}</td>
    </tr>
  );
};

const Manifests = (props) => {
  const { manifests, eventID, canEdit, facility_fee } = props;

  const [showManifests, setShowManifests] = useState(null);
  const [showTicketSalesTable, setShowTicketSalesTable] = useState(false);
  const [showTicketTypesTable, setShowTicketTypesTable] = useState(false);
  const [sortedManifests, setSortedManifests] = useState([...manifests]);
  const [sortKey, setSortKey] = useState(null);
  const sortOrder = useRef("asc");

  const navigate = useNavigate();
  const { perms } = usePerms();
  const { user } = useUser();

  useEffect(() => {
    if (showManifests == null && manifests.length > 4) {
      setShowManifests(false);
    }
    if (showManifests == null && manifests.length <= 4) {
      setShowManifests(true);
    }
  }, [manifests, eventID]);

  const toggleShowManifests = () => {
    setShowManifests(!showManifests);
  };

  // Calculate total tickts
  let total = 0;
  let totalValues = {
    qty: 0,
    comps: 0,
    platinum_lift: 0,
    available: 0,
    paid: 0,
  };

  manifests.map((manifest) => {
    if (manifest.active) {
      total = total + manifest.paid * manifest.price;
      totalValues.qty = totalValues.qty + manifest.qty;
      totalValues.comps = totalValues.comps + manifest.comps;
      totalValues.available = totalValues.available + manifest.available;
      totalValues.paid = totalValues.paid + manifest.paid;
    }
  });

  let tableBody = (
    <ManifestRollup
      key={0}
      name="All Tickets"
      total={total}
      toggleShowManifests={toggleShowManifests}
      {...totalValues}
    />
  );

  if (showManifests) {
    tableBody = manifests.map((manifest) => {
      if (manifest.active) {
        return <ManifestRow key={manifest.id} {...manifest} />;
      }
    });
  }

  const handleTicketTypesEdit = (rowData, rowIndex) => {
    navigate(`/events/manifest/edit/${rowData.id}?type=ticket`);
  };

  const handleTicketSalesEdit = (rowData, rowIndex) => {
    navigate(`/events/manifest/edit/${rowData.id}?type=sales`);
  };

  const formatManifests = (manifests) => {
    // calculate total for each manifest
    return (
      manifests &&
      manifests.map((manifest) =>
        Object.assign(manifest, {
          total: (manifest.price + manifest?.ticket_fees) * manifest.qty,
          total_price: manifest.price + manifest?.ticket_fees,
          facility_fee: facility_fee,
        })
      )
    );
  };

  const canEditManifests = canEdit && user?.data?.role !== "marketing";

  return (
    <>
      <div className="flex xs:flex-col sm:flex-row xs:items-center w-full mb-4 mt-12 flex justify-between border-[#595959] pt-[1rem]">
        <h3 id="manifests" className="float-left text-2xl font-semibold text-gray-900 dark:text-white">
          {/* Manifests */}
        </h3>
        {canEditManifests && (
          <Button
            onClick={() => {
              navigate(`/events/manifest/add/${eventID}`);
            }}
          >
            Add Manifest
          </Button>
        )}
      </div>
      <SortedManifestTable
        manifests={formatManifests(manifests)}
        eventID={eventID}
        headers={[
          { header: "Name", accessor: "name" },
          { header: "Qty", accessor: "qty" },
          { header: "Comps", accessor: "comps" },
          { header: "On Sale", accessor: "on_sale" },
          { header: "Ticket Fees", accessor: "facility_fee", type: "money" },
          { header: "Price", accessor: "total_price", type: "money" },
          { header: "Available", accessor: "available" },
          { header: "Paid", accessor: "paid" },
          { header: "Total", accessor: "total", type: "money" },
        ]}
        type={"TICKET"}
        canEdit={canEditManifests}
        title={"Ticket Types"}
        handleEditClick={handleTicketTypesEdit}
      />

      <SortedManifestTable
        manifests={formatManifests(manifests)}
        eventID={eventID}
        headers={[
          { header: "NAME", accessor: "name" },
          { header: "TM", accessor: "tm" },
          { header: "ETix", accessor: "etix" },
          { header: "Venue", accessor: "venue" },
          { header: "Fan Club", accessor: "fan_club" },
          { header: "Outlets", accessor: "outlets" },
          { header: "VIP", accessor: "vip" },
        ]}
        type={"SALES"}
        canEdit={canEditManifests}
        title={"Ticket Sales"}
        handleEditClick={handleTicketSalesEdit}
      />
    </>
  );
};

const SortedManifestTable = ({
  manifests,
  headers,
  total,
  canEdit,
  title,
  handleEditClick,
}) => {
  const handleFormatCell = (accessor, rowData, rowIndex) => {
    if (accessor === "on_sale") {
      if (rowData["on_sale"]) {
        let dt = dayjs(rowData["on_sale"]);
        if (dt.isBefore(dayjs("2000-01-01"))) {
          return "No Date";
        } else {
          return dt.format("MMM D, YYYY");
        }
      } else {
        return "No Date";
      }
    }
    // return nothing for all other values
  };

  const handleSort = (key, item) => {
    if (key === "total") {
      return item["price"] * item["qty"];
    } else {
      return item[key];
    }
  };

  return (
    <SortedTable
      total={total}
      hasFilter={true}
      data={manifests}
      headers={headers}
      title={title}
      customSort={handleSort}
      formatCell={handleFormatCell}
      onEditClick={canEdit ? handleEditClick : null}
      excludeDarkMode={true}
    />
  );
};

const ManifestFilter = ({
  filterOptions,
  data,
  onFilteredData,
  parentFilters,
  updateFilters,
}) => {
  const [filters, setFilters] = useState(parentFilters);
  const [filteredData, setFilteredData] = useState(
    filterInitialData(parentFilters)
  );

  useEffect(() => {
    setFilters(parentFilters);
  }, [parentFilters]);

  useEffect(() => {
    onFilteredData(filteredData);
  }, [filteredData, onFilteredData]);

  function filterInitialData(filters) {
    return filterData(filters, data);
  }

  const applyFilters = (newFilters) => {
    // update local state
    setFilters(newFilters);
    // update parent state - tracks filters so when table is opened after closed, the filter state will persist
    updateFilters(newFilters);
    let newData = [...data];
    setFilteredData(filterData(newFilters, newData));
  };

  function filterData(newFilters, newData) {
    newFilters.forEach((filter) => {
      if (filter.field.value && filter.type && filter.value) {
        newData = newData.filter((row) => {
          const itemToFilter =
            row[filter.field.value.toLowerCase().replace(" ", "_")];
          switch (filter.type) {
            case "contains":
              return itemToFilter
                .toString()
                .toLowerCase()
                .includes(filter.value.toLowerCase());
            case "does not contain":
              return !itemToFilter
                .toString()
                .toLowerCase()
                .includes(filter.value.toLowerCase());
            case "equals":
              return itemToFilter.toString() === filter.value.toString();
            case "greater_than":
              return parseFloat(itemToFilter) > parseFloat(filter.value);
            case "less_than":
              return parseFloat(itemToFilter) < parseFloat(filter.value);
            case "is_before":
              return new Date(itemToFilter) < new Date(filter.value);
            case "is_after":
              return new Date(itemToFilter) > new Date(filter.value);
            default:
              return true;
          }
        });
      }
    });
    return newData;
  }

  return (
    <FilterDropdown
      parentFilters={filters}
      options={filterOptions}
      onFilterUpdate={applyFilters}
    />
  );
};

const CoPros = (props) => {
  const { id } = useParams();
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const copros = useQuery(["event-copros", id], () => getEventCopros(id));
  const queryClient = useQueryClient();

  if (event.isLoading || copros.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  let display_copros = [];
  if (event.data.is_building_copro) {
    display_copros.push(event.data.venue.name);
  }

  copros.data.map((copro) => {
    display_copros.push(copro.name);
  });

  return (
    <p className="mb-4 border-t-[1px] border-[#595959] pt-[1rem]">
      <span className="font-semibold mr-2 dark:text-[#FFEBE6]">CoPros:</span>
      <span className="dark:text-[#FFEBE6]">{display_copros.join(", ")}</span>
    </p>
  );
};

const AgeTimeInfo = (props) => {
  const { event } = props;

  if (!event.age_requirement && !event.door_time) {
    return null;
  }

  let ageText = null;
  switch (event.age_requirement) {
    case "all-ages":
      ageText = "All Ages";
      break;
    case "16-up":
      ageText = "16+";
      break;
    case "18-up":
      ageText = "18+";
      break;
    case "21-up":
      ageText = "21+";
      break;
  }

  let timeText = null;
  if (event.door_time) {
    const timeParts = event.door_time.split(":");
    const hour = parseInt(timeParts[0]);
    const minute = timeParts[1];
    let amPm = "AM";
    if (hour > 12) {
      amPm = "PM";
      timeText = `${hour - 12}:${minute} ${amPm}`;
    } else {
      timeText = `${hour}:${minute} ${amPm}`;
    }
  }

  return (
    <>
      <h4 id="section4" className="my-2 text-gray-500">
        {ageText && `${ageText} - `}
        {timeText && `Doors at ${timeText}`}
      </h4>
      <h1 className="my-2 text-2xl text-black">
        <span className="text-md text-bold dark:text-white">Buyer: </span>
        <span className="text-2xl text-bold dark:text-white">
          {event.buyer.first_name} {event.buyer.last_name}
        </span>
      </h1>
    </>
  );
};

const EventConfirmation = (props) => {
  const { user } = useUser();
  const { perms } = usePerms();
  const [grossPotential, setGrossPotential] = useState(0);
  const [ticketFees, setTicketFees] = useState(0);
  const [expandedTables, setExpandedTables] = useState({});

  const event = useQuery(["event-detail", props.id], () => getEvent(props.id));
  const variables = useQuery(["variables-offer-pdf", props.id], () =>
    getEventOfferVariables(props.id)
  );
  const rollups = useQuery(["event-rollups", props.id], () =>
    getEventRollups(props.id)
  );
  const copros = useQuery(["event-copros", props.id], () =>
    getEventCopros(props.id)
  );

  const toggleTable = (tableId) => {
    setExpandedTables((prev) => ({
      ...prev,
      [tableId]: !prev[tableId],
    }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (event && event.data) {
      setGrossPotential(
        event.data.manifests.reduce(
          (total, manifest) =>
            total + (manifest.price + manifest?.ticket_fees) * manifest.qty,
          0
        )
      );
      setTicketFees(
        event.data.manifests.reduce(
          (total, manifest) => total + manifest.qty * manifest?.ticket_fees,
          0
        )
      );
    }
  }, [event?.data]);

  if (
    event.isLoading ||
    variables.isLoading ||
    rollups.isLoading ||
    copros.isLoading
  ) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  if (event.isError || variables.isError || rollups.isError || copros.isError) {
    return <div>Error Occurred</div>;
  }

  // constants

  const MAMMOTH_SOFT_MERCH = 100 - event.data.artist_soft_merch_percentage;
  const MAMMOTH_HARD_MERCH = 100 - event.data.artist_hard_merch_percentage;

  // handlers

  const handleEditButtonPress = () => {
    navigate(`/events/edit/offer/${event.data.id}`);
  };

  return (
    // Event Confirmation Return Section
    <div className="eventConfirmationWrapper border-t-[1px] border-[#595959] pt-[1rem]">
      <div className="flex flex-row justify-between items-start md:items-center mb-4">
        <h2 id="section2" className="text-lg sm:text-xl md:text-2xl font-bold mb-2 md:mb-0 xs:mx-auto md:mx-1 dark:text-white">
          Event Confirmation
        </h2>
        {event.isSuccess && event.data.buyer.id === user.data.id && (
          <button
            className="ml-0 md:ml-3 mt-2 md:mt-0 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
            onClick={handleEditButtonPress}
          >
            Edit Event
          </button>
        )}
      </div>

      <div className="grid grid-cols-12 grid-rows-48 gap-4">
        <div
          className={classNames(
            `col-start-1 col-span-6 row-start-1 row-span-2 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["offerInfo"],
              [styles.collapsed]: !expandedTables["offerInfo"],
            }
          )}
          onClick={() => toggleTable("offerInfo")}
        >
          <ItemTable
            columnWidths={["10px", "30px"]}
            header={{ value: "OFFER INFORMATION" }}
            rows={[
              {
                header: "AGENT",
                value: event.data.offer_agent,
                editable: false,
              },
              {
                header: "BUYER",
                value: variables.data.buyer_name,
                editable: false,
              },
              {
                header: "HEADLINER",
                value: event.data.offer_headliner,
                editable: false,
              },
              {
                header: "SUPPORT 1",
                value: event.data.offer_support_1,
                editable: false,
              },
              {
                header: "SUPPORT 2",
                value: event.data.offer_support_2,
                editable: true,
              },
              {
                header: "OPENER",
                value: event.data.offer_opener,
                editable: true,
              },
              {
                header: "BILLING",
                value: event.data.offer_billing,
                editable: true,
              },
              {
                header: "STIPULATIONS",
                value: event.data.offer_stipulations,
                editable: true,
              },
              {
                header: "AVAILS 1",
                value: event.data.offer_avails_1,
                editable: false,
              },
              {
                header: "AVAILS 2",
                value: event.data.offer_avails_2,
                editable: false,
              },
            ]}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
            showTable={expandedTables["offerInfo"] || false}
            showChevrons={true}
            tableId="offerInfo"
            onToggle={toggleTable}
          />
        </div>

        <div
          className={classNames(
            `col-start-7 col-span-6 row-start-1 row-span-2 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["venueInfo"],
              [styles.collapsed]: !expandedTables["venueInfo"],
            }
          )}
          onClick={() => toggleTable("venueInfo")}
        >
          <ItemTable
            header={{ value: "VENUE INFORMATION" }}
            rows={[
              {
                header: "AGE REQUIREMENT",
                value: event.data.age_requirement,
                accessor: "age_requirement",
                format: "select",
                editable: false,
              },
              {
                header: "MERCH",
                value: `${event.data.artist_soft_merch_percentage} / ${event.data.artist_hard_merch_percentage}`,
                accessor: "artist_soft_merch_percentage",
                editable: false,
              },
              {
                header: "WHO SELLS",
                value: event.data.who_sells_merch,
                accessor: "who_sells_merch",
                format: "select",
                editable: false,
              },
            ]}
            info={`${event.data.venue.name}\n${event.data.venue.address}\n${
              event.data.venue.city
            }, ${event.data.venue.state} ${event.data.venue.zipcode}\nPh: ${
              event.data.venue.phone ? event.data.venue.phone : "N/A"
            }\n`}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
            showTable={expandedTables["venueInfo"] || false}
            showChevrons={true}
            tableId="venueInfo"
            onToggle={toggleTable}
          />
        </div>

        <div
          className={classNames(
            `col-start-1 col-span-4 row-start-3 row-span-1 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["coPromoters"],
              [styles.collapsed]: !expandedTables["coPromoters"],
            }
          )}
          onClick={() => toggleTable("coPromoters")}
        >
          <ItemTable
            header={{ value: "CO-PROMOTERS" }}
            rows={copros.data.map((copro) => ({
              header: `Co-Pro ${copro.id}`,
              value: copro.name,
              editable: false,
            }))}
            info={copros.data.length === 0 ? "No co-promoters" : ""}
            showTable={expandedTables["coPromoters"] || false}
            showChevrons={true}
            tableId="coPromoters"
            onToggle={toggleTable}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
          />
        </div>

        <div
          className={classNames(
            `col-start-5 col-span-4 row-start-3 row-span-1 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["totals"],
              [styles.collapsed]: !expandedTables["totals"],
            }
          )}
          onClick={() => toggleTable("totals")}
        >
          <ItemTable
            header={{ value: "TOTALS" }}
            rows={[
              {
                header: "TOTAL CAPACITY",
                value: rollups.data.capacity,
                editable: false,
              },
              {
                header: "GROSS POTENTIAL",
                value: amountDisplay(grossPotential),
                editable: false,
              },
              {
                header: "TICKET FEES",
                value: amountDisplay(ticketFees),
                editable: false,
              },
              {
                header: "ADJUSTED GROSS",
                value: amountDisplay(variables.data.adjusted_gross),
                editable: false,
              },
              {
                header: "TAX PERCENTAGE",
                value: `${event.data.venue.sales_tax}%`,
                highlight: true,
                editable: false,
              },
              {
                header: "TOTAL TAX",
                value: amountDisplay(variables.data.total_tax),
                editable: false,
              },
              {
                header: "NET POTENTIAL",
                value: amountDisplay(variables.data.net_potential),
                editable: false,
              },
            ]}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
            showTable={expandedTables["totals"] || false}
            showChevrons={true}
            tableId="totals"
            onToggle={toggleTable}
          />
        </div>

        <div
          className={classNames(
            `col-start-9 col-span-4 row-start-3 row-span-1 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["setTimes"],
              [styles.collapsed]: !expandedTables["setTimes"],
            }
          )}
          onClick={() => toggleTable("setTimes")}
        >
          <ItemTable
            header={{ value: "SET TIMES" }}
            rows={[
              {
                header: "DOORS",
                value: localTime(event.data.door_time),
                editable: true,
              },
              {
                header: "OPENERS",
                value: localTime(event.data.opener_time),
                editable: true,
              },
              {
                header: "SUPPORT",
                value: localTime(event.data.support_time),
                editable: true,
              },
              {
                header: "HEADLINER",
                value: localTime(event.data.headliner_time),
                highlight: true,
                editable: true,
              },
              {
                header: "CURFEW",
                value: localTime(event.data.curfew_time),
                editable: false,
              },
            ]}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
            showTable={expandedTables["setTimes"] || false}
            showChevrons={true}
            tableId="setTimes"
            onToggle={toggleTable}
          />
        </div>
        <div
          className={classNames(
            `col-start-1 col-span-12 row-start-34 row-span-1 ${styles.chevronAccordionDiv}`,
            {
              [styles.expanded]: expandedTables["contactInfo"],
              [styles.collapsed]: !expandedTables["contactInfo"],
            }
          )}
          onClick={() => toggleTable("contactInfo")}
        >
          <ItemTable
            header={{ value: "CONTACT INFO" }}
            rows={[
              {
                header: "PRODUCTION ADV.",
                value: "production@mammothlive.com",
                editable: false,
              },
              { header: "LOCAL MARKETING", value: "", editable: true },
              {
                header: "TICKETING",
                value: "ticketing@mammothlive.com",
                editable: false,
              },
              { header: "TOUR ADMAT", value: "", editable: true },
              {
                header: "TOUR MARKETING",
                value: "",
                highlight: true,
                editable: true,
              },
              { header: "ON SALE INFO", value: "", editable: true },
              { header: "PRE SALE INFO", value: "", editable: true },
              {
                header: "CONTRACTS",
                value: "contracts@mammothlive.com",
                editable: false,
              },
              { header: "TOUR MANAGER", value: "", editable: true },
              { header: "TOUR PRODUCTION", value: "", editable: true },
            ]}
            columns={[
              { header: "", accessor: "header" },
              { header: "", accessor: "value" },
            ]}
            showTable={expandedTables["contactInfo"] || false}
            showChevrons={true}
            tableId="contactInfo"
            onToggle={toggleTable}
            long
          />
        </div>
      </div>
    </div>
  );
};



export const EventDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { perms } = usePerms();
  const { user } = useUser();
  const visitMutation = useRecordVisit();
  const [isManifestsOpen, setIsManifestsOpen] = useState(false);

  const [books, setBooks] = useState(null);

  const event = useQuery(["event-detail", id], () => getEvent(id));
  const people = useQuery(["event", "people", id], () => getEventPeople(id));
  // Can this user edit this event?
  let canEdit = false;

  // Can this user view these event numbers?
  let canSeeNumbers = false;

  let onlyMarketing = false;


  const toggleManifests = () => {
    setIsManifestsOpen(!isManifestsOpen);
  };

  const links = useQuery(
    ["event", "links", id],
    () => getEventLinks(id),
    getEventLinkSettings
  );
  const comments = useQuery(
    ["event", "comments", id],
    () => getEventComments(id),
    getEventCommentSettings
  );
  const files = useQuery(
  ["event", "files", id, onlyMarketing],
  () => getEventFiles(id, onlyMarketing),
  getEventFileSettings
  );
  const images = useQuery(
    ["event", "images", id],
    () => getEventImages(id),
    getEventImageSettings
  );

  usePageTitle(
    event.isSuccess
      ? `/c${String.fromCharCode(92)}${event.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  // Comments
  const commentMutation = useMutation({
    mutationFn: (data) => {
      return createEventComment(id, data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["event", "comments", id]);
    },
  });

    const mutation = useMutation(
    (personId) => deleteEventPeople(personId),
    {
      onSuccess: (data, personId) => {
        if (data.success) {
          // Invalidate and refetch
          queryClient.invalidateQueries("event","people", id);
          toast.success("Person removed successfully.");
        } else {
          toast.error("Failed to remove the person.");
        }
      },
      onError: (error) => {
        console.error("Error removing person:", error);
        toast.error("An error occurred while removing the person.");
      },
    }
  );

  const toggleMainPOC = useMutation(
    (personId) => toggleMainPOCApiCall(personId), // Implement this API call
    {
      onSuccess: () => {
        queryClient.invalidateQueries("eventPeople");
      },
      onError: (error) => {
        console.error("Error toggling POC:", error);
        toast.error("An error occurred while toggling the main POC.");
      },
    }
  );

  const removePerson = (personId) => {
    if (window.confirm("Are you sure you want to remove this person from the event?")) {
      mutation.mutate(personId);
    }
  };
  const handleCommentSubmit = (data) => {
    commentMutation.mutate(data);
  };

  const handleBookChange = (value) => {
    setBooks(value);
    queryClient.invalidateQueries(["expenses"]);
    queryClient.invalidateQueries(["expense-rollups"]);
  };

  useEffect(() => {
    if (event.isSuccess) {
      visitMutation.mutate({
        itemID: event.data.id,
        itemType: "event",
        itemURL: `/events/${event.data.id}`,
      });

      if (books === null) {
        if (event.data.expenses_are_offer) {
          setBooks("offer");
        } else {
          setBooks("actual");
        }
        queryClient.invalidateQueries(["expenses"]);
        queryClient.invalidateQueries(["expense-rollups"]);
      }
    }
  }, [event.isSuccess]);

  if (event.isLoading || user.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  if (perms.data.edit_events && event.data.buyer == user.data.id) {
    canEdit = true;
    canSeeNumbers = true;
    onlyMarketing = false;
  }
  const getEventDate = event.isSuccess ? `[${event.data.date}]` : "";
  // Special display for holds the user is not allowed to see
  if (
    event.data.state == "hold" &&
    (event.data.buyer != user.data.id || !perms.data.view_holds)
  ) {
    return (
      <>
        <Header />
        <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
          <BreadCrumbs
            links={[
              { text: "Events", url: `/events/` },
              {
                text: `${event.data.name} ${getEventDate}`,
                url: `/events/${event.data.id}`,
              },
              { text: "Details" },
            ]}
          />
          <div className="pt-6 pb-2 md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 id="section2" className="text-2xl font-bold leading-7 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">
                Hold
              </h2>
              <h3 id="section3" className="my-2 text-gray-500">
                {dayjs(event.data.date).format("dddd MMM D, YYYY")} @{" "}
                {event.data.venue &&
                  `${event.data.venue.name} - ${event.data.venue.city}, ${event.data.venue.state}`}
              </h3>
              <AgeTimeInfo event={event.data} />
            </div>
          </div>
          <div>
                    {event.isSuccess && event.data.buyer.id == user.data.id && (
                      <HoldSwitcher eventId={event.data.id} />
                    )}
                    { event.isSuccess && event.data.buyer.id != user.data.id && (
                      <div className="w-full p-6 border border-red-600 bg-red-100">
                        There is a hold on this venue for this date from another buyer.<br />
                        <button onClick={null} className="mt-2 ml-4 rounded-md px-2.5 py-1.5 text-sm font-normal shadow-sm bg-cave-orange text-white">Challenge Hold</button>
                        <ChallengeHold event={event} user={user} />
                      </div>
                    ) }
          </div>
        </div>
      </>
    );
  }

  if (
    people.isSuccess &&
    people.data &&
    Array.isArray(people.data) &&
    people.data.length > 0
  ) {
    let debugInfo = [];
    const matchedPerson = people.data.find((person, index) => {
      const personLog = {
        index: index,
        person: JSON.parse(JSON.stringify(person)),
        userIdMatch: person.user_id === user.data.id,
        userDataId: user.data.id,
        personUserId: person.user_id,
      };
      return (
        person.user_id === user.data.id && person.role_name === "Promoter Rep"
      );
    });
    if (matchedPerson) {
      const isPromoterRep = !!matchedPerson;

      canEdit = true;
      canSeeNumbers = true;
    }
  }

  if (event.isSuccess) {
  }
  if (perms.data.edit_marketing) {
    canEdit = true;
    canSeeNumbers = true;
    onlyMarketing = true;
  }
  if (["admin", "accounting_admin", "accounting"].includes(user.data.role)) {
    canEdit = true;
    canSeeNumbers = true;
    onlyMarketing = false;
  }

  const handleCreateImage = (data) => {
    return createEventImages(id, data);
  };

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <BreadCrumbs
          links={[
            { text: "Events", url: "/events" },
            { text: event.data.name },
          ]}
        />

        <div className="pt-6 pb-2 md:flex md:items-center md:justify-between ">
          <div className="min-w-0 flex-8">
            <h2 className="text-2xl font-bold leading-7 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">
              {event.data.name}
            </h2>
            <h2 className="p-4">
              {event.data.highlight && (
                <img src={event.data.highlight} width="300" height="200" />
              )}
            </h2>
            <h3 className="my-2 text-gray-500">
              {dayjs(event.data.date).format("dddd MMM D, YYYY")} @{" "}
              {event.data.venue &&
                `${event.data.venue.name} - ${event.data.venue.city}, ${event.data.venue.state}`}
            </h3>
            <AgeTimeInfo event={event.data} />
          </div>
          <div className="w-3/6 container p-2">
            <div className="grid grid-cols-3 gap-y-2">
              { //((event.isSuccess && event.data.buyer.id === user.data.id) ||
                   ( perms.data.is_admin) && (
                    <>
                      <Button
                        onClick={() =>
                          navigate(`/events/settlement/${event.data.id}`)
                        }
                      >
                        Edit Settlement
                      </Button>
                    </>
                  ) }
            {((event.isSuccess && event.data.buyer.id === user.data.id) ||
              perms.data.is_admin) && (
              <>
             <div className="bg-blue-500 hover:bg-blue-600
              text-white font-bold uppercase
              p-4 rounded-lg shadow-md
              flex items-center justify-center
              h-10 w-40 transition-colors duration-300 ease-in-out
              cursor-pointer">
                <div className="text-white font-bold py-2 px-4 rounded" >
                  <a onClick={() =>
                    navigate(`/events/edit/offer/${event.data.id}`)
                  }>
                  Edit Offer</a>
                </div>
                  </div>
              </>
            )}
            {((event.isSuccess && event.data.buyer.id === user.data.id) ||
              perms.data.is_admin) && (
              <>
             <div className="bg-blue-500 hover:bg-blue-600
              text-white font-bold uppercase
              p-4 rounded-lg shadow-md
              flex items-center justify-center
              h-10 w-40 transition-colors duration-300 ease-in-out
              cursor-pointer">
                <div className="text-white font-bold py-2 px-4 rounded" >
                 <a onClick={() => navigate(`/events/edit/${event.data.id}`)}>

                  Edit Event</a>
                </div>
            </div>
              </>
              )}
             <div>
            {((event.isSuccess && event.data.buyer.id === user.data.id) ||
              perms.data.is_admin) && (
                  <DuplicateEventButton eventId={id} />
              )
            }</div>
                  <div className="bg-blue-500 hover:bg-blue-600
              text-white font-bold uppercase
              p-4 rounded-lg shadow-md
              flex items-center justify-center
              h-10 w-40 transition-colors duration-300 ease-in-out
              cursor-pointer">
                    <a href="#files">Files</a> </div>
                  <div className="bg-blue-500 hover:bg-blue-600
              text-white font-bold uppercase
              p-4 rounded-lg shadow-md
              flex items-center justify-center
              h-10 w-40 transition-colors duration-300 ease-in-out
              cursor-pointer">
                    <a href="#people">People</a></div>
                  <div className="bg-blue-500 hover:bg-blue-600
              text-white font-bold uppercase
              p-4 rounded-lg shadow-md
              flex items-center justify-center
              h-10 w-40 transition-colors duration-300 ease-in-out
              cursor-pointer">
                    <a href="#advance">Advance</a></div>
        </div>
            </div>
        </div>
        <div className="my-6">
            <ImageDisplay
              images={images}
              createImage={handleCreateImage}
              deleteQuery={deleteEventImage}
              editable={perms.data.edit_events}
              entityType="events"
              id={event.data.id}
            />

        </div>

        {event.data.artists && event.isSuccess && (
          <div className="mb-4 border-t-[1px] border-[#595959] pt-[1rem]">
            <span className="font-semibold mr-2 dark:text-[#FFFBE6]">
              Artists&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|:-
              {event.data.artists
                .map((artist) => (
                  <a
                    key={artist.id}
                    href={`/artists/${artist.id}`}
                    className="text-blue-500 hover:underline"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{artist.name}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </a>
                ))
                .reduce((prev, curr) => [prev, "-:|:-", curr])}{" "}
              -:|
            </span>
          </div>
        )}
        <CoPros />
        {event.data.description && (
          <p className="mb-4 dark:text-gray-500 ">{event.data.description}</p>
        )}

        <div className="mb-4 dark:text-[#FFFBE6]">
          <EventState state={event.data.state} />
        </div>

        <EventConfirmation id={id} />

        <div className="mt-[2rem] border-t-[1px] border-[#595959] py-[1rem]">
          <button
            className={`flex justify-between items-center w-full px-4 py-2 rounded-[10px] bg-[#4E6B85] text-white text-lg font-semibold cursor-pointer mt-[1.5rem] ${styles.chevronAccordionDiv}`}
            onClick={toggleManifests}
          >
            <span className="m-auto">Ticketing Manifests</span>
            {isManifestsOpen ? (
              <ChevronDownIcon className={styles.chevronAccordionDown} />
            ) : (
              <ChevronUpIcon className={styles.chevronAccordionUp} />
            )}
          </button>
          {isManifestsOpen && (
            <div>
              <Manifests
                manifests={event.data.manifests}
                eventID={event.data.id}
                canEdit={canEdit}
                facility_fee={event.data.facility_fee}
              />
            </div>
          )}
        </div>
        <div className="py-6">
          <CommentDisplay
            comments={comments}
            handleAddComment={handleCommentSubmit}
          />
        </div>
       <div id="advance" className="py-6">
        <EventAdvance id={id} />
       </div>

        {canSeeNumbers && (
          <>
            <Expenses
              eventID={id}
              event={event.data}
              books={books}
              setBooks={handleBookChange}
              onlyMarketing={onlyMarketing}
            />

            <RollUp
              eventID={id}
              event={event.data}
              books={books}
              onlyMarketing={onlyMarketing}
            />
          </>
        )}

        {/* perms.data.view_wires && (
            <div className="mt-[2rem] border-t-[1px] border-[grey]">
          <Wires />
        </div>
        )*/}

        <div id="people" className="my-6 mt-12 border-t-[1px] border-[#595959] pt-[1rem]">
          <People
            people={people}
            toggleEventMainPOC={toggleEventMainPOC}
            removePerson={removePerson}
            addURL={`/events/people/add/${event.data.id}`}
            itemType="event"
            editable={perms.data.edit_events}
          />
        </div>

        {perms.data.view_files && (
        <div className="flex flex-col my-6 border-t-[1px] border-[#595959] pt-[1rem]">
          <LinkDisplay
            links={links}
            addURL={`/events/link/add/${id}`}
            deleteQuery={deleteEventLink}
            editable={perms.data.edit_events}
          />
        </div>
        )}
        {perms.data.view_files && (
          <div id="files" className="flex flex-col my-6 border-t-[1px] border-[#595959] pt-[1rem]">

<FileDisplay
  files={files}
  id={id}
  entityType="events"
  editable={perms.data.edit_events}
  perms={perms}
            />
          </div>
        )}

        {canEdit && canSeeNumbers && (
          <div className="flex flex-col my-6 border-t-[1px] border-[#595959] pt-[1rem]">
            <EventPDF event={event.data} canAdd={true} />
          </div>
        )}

        <div className="mb-12">&nbsp;</div>
        <div className="mb-12">&nbsp;</div>
        <div className="mb-12">&nbsp;</div>
        <div className="mb-12">&nbsp;</div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import { useUser } from "@/components/Auth"
import { Calendar } from "@/components/Calendar/index.jsx"
import { usePageTitle } from '@/utils/pagetitle';

export const EventsCalendar = () => {
  usePageTitle(`/c${String.fromCharCode(92)}cal`);
  const { user } = useUser()

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0
    "
      >
        <div className="my-6 font-sans font-bold">
          <Calendar />
        </div>
      </div>
    </>
  );
};


import React, { useState } from "react";
import SettlementVerticalTable from "./SettlementVerticalTable"; // Assuming you have it already
import SettlementFinalFigures from "./SettlementFinalFigures";

const DealAndFinalFigures: React.FC = ({
  className,
  hideDeal,
}: {
  className?: string;
  hideDeal?: boolean;
}) => {
  const [data, setData] = useState({
    deal: {
      title: "THE DEAL",
      data: [
        {
          key: "Guarantee",
          label: "Guarantee",
          value: 125000,
          isEditable: true,
        },
        { key: "DealType", label: "Deal Type", value: "VS", isEditable: false },
        {
          key: "PromProfit",
          label: "PROM PROFIT %",
          value: 0,
          isEditable: true,
        },
        {
          key: "ArtistShare",
          label: "ARTIST SHARE",
          value: "85.00%",
          isEditable: true,
        },
        { key: "Versus", label: "VERSUS %", value: "15%", isEditable: true },
        {
          key: "GuaranteePaidBy",
          label: "GUARANTEE PAID BY:",
          value: "MAMMOTH",
          isEditable: false,
        },
        {
          key: "CoHeadlinerSplit",
          label: "CO-HEADLINER SPLIT (if applicable)",
          value: "100%",
          isEditable: false,
        },
      ],
    },
    ticketInfo: {
      title: "Ticket Info",
      data: [
        {
          key: "PercentSold",
          label: "% SOLD",
          value: "39.40%",
          isEditable: true,
        },
        {
          key: "AvgTxtPrice",
          label: "AVERAGE TXT PRICE",
          value: 68.4,
          isEditable: true,
        },
        { key: "DropCount", label: "DROP COUNT", value: 0, isEditable: true },
      ],
    },
  });

  const handleBlur = (
    sectionKey: keyof typeof data,
    key: string,
    newValue: string | number
  ) => {
    const updatedData = { ...data };
    updatedData[sectionKey].data = updatedData[sectionKey].data.map((row) =>
      row.key === key ? { ...row, value: newValue } : row
    );
    setData(updatedData);
  };

  return (
    <div className={className}>
      <div className="grid grid-cols-3 my-8 gap-4">
        {/* THE DEAL Section */}
        {hideDeal ? (
          <div></div>
        ) : (
          <SettlementVerticalTable
            title={data.deal.title}
            data={data.deal.data}
            onBlur={(key, newValue) => handleBlur("deal", key, newValue)}
          />
        )}
        {/* Ticket Info Section */}
        <SettlementVerticalTable
          title={data.ticketInfo.title}
          data={data.ticketInfo.data}
          onBlur={(key, newValue) => handleBlur("ticketInfo", key, newValue)}
        />
        <SettlementFinalFigures />
        {/* Final Figures Section */}
        {/* Final Figures are already implemented */}
      </div>
    </div>
  );
};

export default DealAndFinalFigures;

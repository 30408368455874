import { useEffect, useRef, useState } from "react";
import { SectionHeader } from "../../views/events/accountingComponents";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { amountDisplay } from "../../utils/money";
import { SearchBar } from "../Search/SearchBar";
import { PPeopleIcon, PeopleIcon } from "@/components/People/PeopleIcon";
import { AArtistIcon, ArtistIcon } from "@/components/Artist/Icon";

export const SortedTable = ({
  data,
  headers,
  total,
  title,
  customSort,
  onEditClick,
  calculateTotal,
  formatCell,
  hasFilter = false,
  hasHeader = false,
  handleRowClick,
  hasSorting = true,
  initiallyOpen = true,
  initialSortKey = null,
  initialSortOrder = "asc",
  excludeDarkMode = false, // New prop
}) => {
  const [sortedData, setSortedData] = useState([]);
  const [sortKey, setSortKey] = useState(initialSortKey);
  const [showTable, setShowTable] = useState(initiallyOpen);
  const [filters, setFilters] = useState([]);

  const sortOrder = useRef(initialSortOrder);

  useEffect(() => {
    const formattedData = formatData([...data]);
    if (sortKey) {
      const sorted = sortData(formattedData, sortKey, sortOrder.current);
      setSortedData(sorted);
    } else {
      setSortedData(formattedData);
    }
  }, [data, sortKey]);

  function localFormatCell(cellType, value) {
    if (cellType === "money") {
      return amountDisplay(value);
    } else if (cellType === "number") {
      return value.replace(/\D/g, "");
    } else {
      return value;
    }
  }

  function formatData(data) {
    let formattedData = data.map((row, rowIndex) => {
      let formattedRow = {};
      Object.keys(row).forEach((propertyName) => {
        let headerObj = headers.find(
          (header) => header.accessor === propertyName
        );
        if (!headerObj) {
          formattedRow[propertyName] = row[propertyName];
        } else {
          let output = formatCell && formatCell(propertyName, row, rowIndex);
          formattedRow[propertyName] =
            output && output !== row[propertyName]
              ? output
              : localFormatCell(headerObj["type"], row[propertyName]);
        }
      });
      return formattedRow;
    });
    return formattedData;
  }

  const handleSort = (key) => {
    if (sortKey === key) {
      sortOrder.current = sortOrder.current === "asc" ? "desc" : "asc";
    } else {
      setSortKey(key);
      sortOrder.current = "asc";
    }

    setSortedData(sortData([...sortedData], key, sortOrder.current));
  };

  const sortData = (dataToSort, key, order) => {
    return dataToSort.sort((a, b) => {
      let aValue = customSort ? customSort(key, a) : a[key];
      let bValue = customSort ? customSort(key, b) : b[key];

      if (order === "desc") {
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      } else {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      }
    });
  };

  const handleSearch = (searchedValue) => {
    const lowerCaseSearchedValue = searchedValue.toLowerCase();

    setSortedData(
      formatData(data).filter((item) => {
        let statement = headers.some((header) => {
          const value = item[header.accessor];
          return (
            value &&
            value.toString().toLowerCase().includes(lowerCaseSearchedValue)
          );
        });
        return statement;
      })
    );
  };

  const getCellValue = (row, accessor) => {
    const keys = accessor.split(".");
    let value = row;
    for (const key of keys) {
      if (value && typeof value === "object" && key in value) {
        value = value[key];
      } else {
        return null;
      }
    }
    return value;
  };

  const renderCell = (row, header) => {
    const value = getCellValue(row, header.accessor);
    if (header.accessor.endsWith("photo")) {
      return value ? <PeopleIcon imageUrl={value} /> : "";
    }
    if (header.accessor.endsWith("people_images")) {
      return value ? <PPeopleIcon person={row} /> : "";
    }
    if (header.accessor === 'name' && row.image_url) {
      return value ? (
        <>
          <div className="flex items-center space-x-2">
            <AArtistIcon imageUrl={row.image_url} />
            <span> {value} </span> 
          </div>
        </>
      ) : "";
    }

    return value;
  };

  return (
    <div className="w-full">
      {hasHeader && (
        <div className="flex justify-between items-center">
          <SectionHeader
            className={excludeDarkMode ? "" : "dark:text-gray-500"}
            title={title}
            collapsible
            bgPress={() => setShowTable(!showTable)}
          />
        </div>
      )}
      {showTable && (
        <>
          {hasFilter && (
            <div className="flex justify-left pl-2 items-center mb-4">
              <SearchBar width="300px" handleSearch={handleSearch} />
            </div>
          )}
          <div className="overflow-x-auto">
            <table
              className={`w-full text-sm text-left text-gray-500 mb-4 rounded-lg overflow-hidden ${
                excludeDarkMode ? "" : "dark:text-white"
              }`}
            >
              <thead
                className={`text-xs text-gray-700 uppercase bg-cave-green-700 ${
                  excludeDarkMode ? "" : "dark:text-gray-500"
                }`}
              >
                <tr className="cursor-pointer w-full">
                  {headers.map((headerObj) => (
                    <th
                      key={headerObj.accessor}
                      className={`text-white px-6 py-3 cursor-pointer ${
                        hasSorting ? "hover:bg-cave-green-800" : ""
                      } select-none ${
                        headerObj.styles ? headerObj.styles : ""
                      }`}
                      onClick={() =>
                        hasSorting && handleSort(headerObj.accessor)
                      }
                    >
                      {headerObj.header}
                      <span className="text-white ml-2 text-[9px]">
                        {sortKey === headerObj.accessor &&
                          (sortOrder.current === "asc" ? "▲" : "▼")}
                      </span>
                    </th>
                  ))}
                  {onEditClick && (
                    <th className="px-6 py-3 cursor-pointer"></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {sortedData.length > 0 ? (
                  sortedData.map((row, index) => (
                    <tr
                      className={`bg-white border-b ${
                        excludeDarkMode
                          ? ""
                          : "dark:bg-gray-800 dark:border-gray-700"
                      } ${
                        handleRowClick
                          ? "cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                          : ""
                      }`}
                      key={index}
                      onClick={() =>
                        handleRowClick && handleRowClick(row, index)
                      }
                    >
                      {headers.map((header, cellIndex) => (
                        <td
                          key={cellIndex}
                          className={`px-6 py-4 ${
                            row.styles ? row.styles : ""
                          }`}
                        >
                          {renderCell(row, header)}
                        </td>
                      ))}
                      {onEditClick && (
                        <td className="px-6 py-4">
                          <div className="p-1 hover:bg-blue-200 w-fit">
                            <PencilSquareIcon
                              className="h-4 w-4 hover:text-blue-700"
                              onClick={() => onEditClick(row, index)}
                            />
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={headers.length + (onEditClick ? 1 : 0)}
                      className="p-4"
                    >
                      No data to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import React from "react";
import SettlementRevenuesPL from "./SettlementRevenuesPL";
import ExpenseTable from "../ExpensesTable";
import DealAndFinalFigures from "./SettlementArtistDealSection";
import SettlementVerticalTable from "./SettlementVerticalTable";
import { Table, TableRow } from "../AppTable2";

export default function SettlementFinalPage() {
  const { event, isPDF } = useSettlementComponent();
  const txtRebates = {
    title: "MAMMOTH TICKETED EVENTS ONLY",
    headers: [
      {
        title: "MAMMOTH TXT REBATES",
        accessor: "rebate",
        key: "rebate",
      },
      {
        title: "REBATE TOTAL",
        accessor: "total",
        editable: false,
      },
    ],
    data: [
      {
        rebate: "3.90",
        total: 146399.54,
      },
      {
        rebate: "7.20",
        total: 22300.6,
      },
      {
        rebate: "9.45",
        total: 11263.5,
      },
      {
        rebate: "9.45",
        total: 34232.33, // Placeholder, adjust as necessary
      },
      {
        rebate: "9.45",
        total: 4249942.23, // Placeholder, adjust as necessary
      },
      {
        rebate: "9.45",
        total: 43343434.24, // Placeholder, adjust as necessary
      },
      {
        rebate: "13.00",
        total: 3333333.94, // Placeholder, adjust as necessary
      },
      {
        rebate: "13.00",
        total: 44432.23, // Placeholder, adjust as necessary
      },
      {
        rebate: "13.00",
        total: 5848432.23, // Placeholder, adjust as necessary
      },
      {
        rebate: "13.00",
        total: 68848432.23, // Placeholder, adjust as necessary
      },
      {
        rebate: "13.00",
        total: 9848432.23, // Placeholder, adjust as necessary
      },
    ],
  };
  return (
    <div>
      <div className="bg-green-300 text-center py-1 font-bold text-black">
        INTERNAL FINAL SETTLEMENT
      </div>
      <ExpenseTable
        className="mb-8"
        id={event.data?.id}
        book="final"
        isPDF={isPDF}
      />
      <ExpenseTable
        title="ADDITIONAL INTERNAL SHOW EXPENSES"
        className="mb-8"
        id={event.data?.id}
        book="final"
        isPDF={isPDF}
      />
      <DealAndFinalFigures className="mb-8" hideDeal />
      {/* 2 empty divs to match size of final figures */}
      <div className="grid grid-cols-3 gap-4 mb-8">
        <div></div>
        <div></div>
        <div>
          <Table
            tableTitle={txtRebates.title}
            columns={txtRebates.headers}
            data={txtRebates.data}
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "",
              value: "$22,300.60",
            }}
            rowContainerClass="bg-blue-200 border-2 border-t-0 border-black"
          />
        </div>
      </div>
      <SettlementRevenuesPL />
    </div>
  );
}

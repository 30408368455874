import {
    getApiRequest,
    postApiRequest,
    putApiRequest,
    deleteApiRequest,
    patchApiRequest,
    jsonHeaders,
    makeRequest,
} from './base'

export const getVenuesSettings = {
    cacheTime: 30 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
}

export const getVenues = async () => {
    const url = `/api/venues/`
    return await getApiRequest(url)
}

export const getVenue = async (id) => {
    const url = `/api/venues/${id}/`
    return await getApiRequest(url)
}

export const updateVenue = async (id, data) => {
    const url = `/api/venues/${id}/`
    return await putApiRequest(url, data)
}

export const createVenue = async (data) => {
    const url = `/api/venues/`
    return await postApiRequest(url, data)
}

export const getVenueRoles = async () => {
    const url = `/api/venues/people/roles/`
    return await getApiRequest(url)
}

export const getVenuePeople = async (id) => {
    const url = `/api/venues/people/${id}/`
    return await getApiRequest(url)
}

export const createVenuePeople = async (id, data) => {
    const url = `/api/venues/people/add/${id}/`
    return await postApiRequest(url, data)
}

export const toggleVenueMainPOC = async (id) => {
    const url = `/api/venues/people/toggle-main-contact/${id}/`
    return await postApiRequest(url)
}

export const getVenueEvents = async (id) => {
    const url = `/api/venues/events/${id}/`
    return await getApiRequest(url)
}

export const getVenuePastEvents = async (id) => {
    const url = `/api/venues/events/past/${id}/`
    return await getApiRequest(url)
}

export const getVenueLinkSettings = {
    cacheTime: 30 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
}

export const getVenueLinks = async (id) => {
    const url = `/api/venues/links/${id}/`
    return await getApiRequest(url)
}

export const createVenueLink = async (id, data) => {
    const url = `/api/venues/links/${id}/`
    return await postApiRequest(url, data)
}

export const deleteVenueLink = async (id) => {
    const url = `/api/venues/link/${id}/`
    return await deleteApiRequest(url)
}

export const getVenueCommentSettings = {
    cacheTime: 10 * 60 * 1000,
    staleTime: 2 * 60 * 1000,
}

export const getVenueComments = async (id) => {
    const url = `/api/venues/comments/${id}/`
    return await getApiRequest(url)
}

export const createVenueComment = async (id, data) => {
    const url = `/api/venues/comments/${id}/`
    return await postApiRequest(url, data)
}

export const getVenueFileSettings = {
    cacheTime: 10 * 60 * 1000,
    staleTime: 2 * 60 * 1000,
}

export const getVenueFiles = async (id) => {
    const url = `/api/venues/files/${id}/`
    return await getApiRequest(url)
}

export const createVenueFile = async (id, data) => {
    const url = `/api/venues/files/${id}/`
    const headers = await jsonHeaders(true)
    headers.delete('Content-Type')
    const resp = await makeRequest(url, "POST", headers, data)
    return resp.json()
}

export const deleteVenueFile = async (id) => {
    const url = `/api/venues/file/${id}/`
    return await deleteApiRequest(url)
}

export const fetchSeatingCharts = () => {
    const url = `/api/seating/charts/`
    return getApiRequest(url)
}

export const createSeatingChart = (data) => {
    const url = `/api/seating/charts/`
    return postApiRequest(url, data)
}

export const updateSeatingChart = (id, data) => {
    const url = `/api/seating/charts/${id}/`
    return putApiRequest(url, data)
}

export const deleteSeatingChart = (id) => {
    const url = `/api/seating/charts/${id}/`
    return deleteApiRequest(url, id)
}

export const fetchSeatingChart = (id) => {
    const url = `/api/seating/charts/${id}/`
    return getApiRequest(url)
}

export const fetchSeatingSections = () => {
    const url = `/api/seating/sections/`
    return getApiRequest(url)
}

export const createSeatingSection = (data) => {
    const url = `/api/seating/sections/`
    return postApiRequest(url, data)
}

export const updateSeatingSection = (id, data) => {
    const url = `/api/seating/sections/${id}/`
    return putApiRequest(url, data)
}

export const deleteSeatingSection = (id) => {
    const url = `/api/seating/sections/${id}/`
    return deleteApiRequest(url)
}

export const getVenueImageSettings = {
    cacheTime: 10 * 60 * 1000,
    staleTime: 2 * 60 * 1000,
}

export const getVenueImages = async (id) => {
    const url = `/api/venues/images/${id}/`
    return await getApiRequest(url)
}

//export const createVenueImage = async (id, data) => {
//  const url = `/api/venues/images/add/${id}/`
//  const headers = await jsonHeaders(true)
//  headers.delete('Content-Type')
//  const resp = await makeRequest(url, "POST", headers, data)
//  return resp.json()
//}

export const deleteVenueImage = async (id) => {
    const url = `/api/venues/images/delete/${id}/`
    return await deleteApiRequest(url)
}

export const createVenueImages = async (venueId, data) => {
    console.log("createVenueImage called with venueId:", venueId);
    console.log("Data to be sent:", data);
    const url = `/api/venues/images/${venueId}/`;
    console.log("Request URL:", url);
    const headers = await jsonHeaders(true);
    headers.delete('Content-Type');
    try {
        const resp = await makeRequest(url, "POST", headers, data);
        const jsonResponse = await resp.json();
        console.log("createVenueImage response:", jsonResponse);
        return jsonResponse;
    } catch (error) {
        console.error("Error in createVenueImage:", error);
        throw error;
    }
};

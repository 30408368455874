/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { amountDisplay, formatInputToMoney } from "@/utils/money";
import { formatTimeString } from "@/utils/datetime";

type RenderType =
  | "input"
  | "number"
  | "numbers"
  | "select"
  | "radio"
  | "money"
  | "time";
type DisplayValueFunction = (value: string | number | null) => string | number;
interface EditableCellProps {
  field: string;
  accessor?: string;
  value: any;
  format?: RenderType;
  displayValue?: string | number | DisplayValueFunction;
  renderType?: RenderType;
  editable?: boolean;
  options?: { label: string; value: string }[];
  isMulti?: boolean;
  onValueChange?: (
    value: string | number,
    field: string,
    hasChanged?: boolean
  ) => void;
  onBlurChange?: (
    value: string | number,
    field: string,
    hasChanged?: boolean
  ) => void;
  msg?: string;
}

export const FIELD_UPDATE_ANIMATION_TIME = 2000;
const defineColumnField = (col: Col) => col.accessor || col.field;
export const EditableCell: React.FC<EditableCellProps> = ({
  field,
  value: initialValue,
  displayValue,
  format,
  renderType = "input",
  editable = true,
  options = [],
  onValueChange,
  onBlurChange,
  isMulti,
  msg,
}) => {
  const handleValueFormat = (val: string | number | null) => {
    if ([format, renderType].includes("money")) {
      return formatInputToMoney(val);
    }
    if ([format, renderType].includes("time")) {
      return formatTimeString(val);
    }
    return val;
  };
  const [value, setValue] = useState(handleValueFormat(initialValue));
  const [changed, setChanged] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (changed) {
      const timeout = setTimeout(() => {
        setChanged(false);
      }, FIELD_UPDATE_ANIMATION_TIME);
      return () => clearTimeout(timeout);
    }
  }, [changed]);

  useEffect(() => {
    setValue(handleValueFormat(initialValue));
  }, [initialValue]);

  const handleInputChange = (newValue: any) => {
    const valueChanged = JSON.stringify(newValue) !== JSON.stringify(value);
    const nonBlurTypes: RenderType[] = ["select", "radio"];
    const isNonBlurType = nonBlurTypes.includes(renderType);
    setValue(newValue);
    if (isNonBlurType) {
      setChanged(true);
    }
    if (onValueChange) {
      onValueChange?.(newValue, field, isNonBlurType ? valueChanged : false);
    } else {
      onBlurChange?.(newValue, field, isNonBlurType ? valueChanged : false);
    }
  };

  const handleBlur = () => {
    const valueChanged = JSON.stringify(initialValue) !== JSON.stringify(value);
    if (!valueChanged) {
      return;
    }
    setValue(handleValueFormat(value));
    let submitValue: string | number = value;
    if ([format, renderType].includes("money")) {
      if (!value) {
        submitValue = 0;
      }
    }
    setChanged(true);
    setIsFocused(false);
    onBlurChange?.(submitValue, field, valueChanged);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const sharedInputClassName = editable
    ? "bg-blue-300 hover:ring-2 focus:outline-none focus:ring focus:ring-blue-400 duration-200 ease-in"
    : "bg-transparent focus:outline-none focus:ring-0";

  const valueToDisplay = () => {
    if (isFocused) return value;
    if (!displayValue) return value;
    if (typeof displayValue === "function") return displayValue(value);
    return displayValue;
  };

  const renderInput = () => {
    switch (renderType) {
      case "number":
        return (
          <input
            type="number"
            value={valueToDisplay() as string}
            onChange={(e) => handleInputChange(e.target.value)}
            onBlur={handleBlur}
            onFocus={handleFocus}
            className={`w-full h-full block ${sharedInputClassName} selection:bg-blue-900 selection:text-white`}
            readOnly={!editable}
          />
        );
      case "select":
        return (
          <Select
            defaultValue={value}
            options={options as any[]}
            isMulti={isMulti}
            onChange={(selectedOption) => {
              const determineValue = () => {
                if (isMulti) {
                  return selectedOption;
                }
                return selectedOption?.value;
              };
              handleInputChange(determineValue());
            }}
            onFocus={handleFocus}
            styles={{
              container: (provided) => ({
                ...provided,
                border: "2px dashed #B3B3B3",
                background: "transparent",
                backgroundColor: "transparent",
                cursor: "pointer",
              }),
              control: (provided) => ({
                ...provided,
                backgroundColor: "none",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                border: "none",
                boxShadow: "none",
                cursor: "pointer",
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: 0,
                border: "none",
                paddingLeft: "8px",
                paddingRight: "8px",
                fontSize: 14,
                cursor: "pointer",
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: "black",
                border: "none",
                fontSize: 14,
                cursor: "pointer",
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                backgroundColor: "none",
                border: "none",
              }),
              option: (provided) => ({
                ...provided,
                fontSize: 14,
                cursor: "pointer",
              }),
            }}
            className="w-full bg-input-blue"
          />
        );
      case "radio":
        return (
          <div>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={!!value}
                onChange={(e) => handleInputChange(e.target.checked)}
                readOnly={!editable}
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        );
      case "input":
      default:
        return (
          <input
            type="text"
            value={valueToDisplay() as string}
            onChange={(e) => handleInputChange(e.target.value)}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.target.blur();
              }
            }}
            className={`w-full h-full block ${sharedInputClassName} selection:bg-blue-900 selection:text-white`}
            readOnly={!editable}
          />
        );
    }
  };

  return (
    <div className={`${changed ? "glow-text" : ""} p-1 text-xs relative`}>
      {renderInput()}

      {msg && <div className="absolute right-1 top-0.5">{msg}</div>}
    </div>
  );
};

export interface Col {
  field: string;
  accessor?: string;
  header: string | React.ReactNode;
  type?: RenderType;
  value?: unknown;
  editable?: boolean;
  options?: { label: string; value: string }[];
  onValueChange?: (value: string, field: string) => void;
  onBlurChange?: (value: string, field: string) => void;
  isMulti?: boolean;
  editableToggle?: boolean;
  editableToggleValue?: boolean;
  onToggleChange?: (value: boolean, field: string) => void;
  msg?: string;
}
export const DataRow = ({ col }: { col: Col }) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [toggle, setToggle] = useState(!!col?.editableToggleValue);

  const handleTogleChange = (value: boolean) => {
    setToggle(value);
    col?.onToggleChange?.(value, defineColumnField(col));
    setHasChanged(true);
  };

  useEffect(() => {
    if (hasChanged) {
      const timeout = setTimeout(() => {
        setHasChanged(false);
      }, FIELD_UPDATE_ANIMATION_TIME);
      return () => clearTimeout(timeout);
    }
  });
  return (
    <>
      <div
        className={`bg-gray-50 text-sm p-1 flex justify-between ${hasChanged ? "glow-text" : ""}`}
      >
        {col.header}

        {col.editableToggle ? (
          <div>
            <InputSwitch
              className="small-input-switch"
              checked={toggle as boolean}
              onChange={(e) => handleTogleChange(e.value)}
            />
          </div>
        ) : null}
      </div>
      <div className={`bg-blue-300 h-full ${hasChanged ? "glow-text" : ""}`}>
        <EditableCell
          {...col}
          field={defineColumnField(col)}
          value={col.value}
          renderType={col.type}
          msg={col?.msg}
          editable={col.editable && toggle}
          onBlurChange={(v, field, hasChanged) => {
            setHasChanged(hasChanged as boolean);
            col?.onBlurChange?.(v, field);
          }}
          onValueChange={(v, field, hasChanged) => {
            setHasChanged(hasChanged as boolean);
            col?.onValueChange?.(v, field);
          }}
        />
      </div>
    </>
  );
};
export const DataGrid = ({
  header,
  cols = [],
  onBlur,
  className,
}: {
  header?: string | React.ReactNode;
  cols: Col[];
  onBlur?: (value: string, field: string) => void;
  className?: string;
}) => {
  return (
    <div className={className}>
      <h2
        className={`text-sm font-montserrat font-normal ${typeof header === "string" ? "bg-gray-300 text-center font-bold" : ""}`}
      >
        {/* Optional Header that should be a prop of type string or renderable e.g. header: */}
        {header}
      </h2>
      <div className="grid grid-cols-2 items-start font-monserrat">
        {/* list of items, with a title/lable(renderable... e.g. I should  be able to do [{ title: 'MyTitle' }, { title: <div>My Title</div>}]) */}
        {cols.map((col) => {
          return (
            <DataRow
              key={defineColumnField(col)}
              col={{
                ...col,
                onBlurChange: (value) => {
                  if (col.onBlurChange) {
                    col.onBlurChange(value, defineColumnField(col));
                  } else if (onBlur) {
                    onBlur(value, defineColumnField(col));
                  }
                },
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

import React, { useState } from "react";
import SettlementVerticalTable from "./SettlementVerticalTable";
import { TableRow } from "../AppTable2";

// Example usage with multiple vertical tables for support sections
const SupportArtistSupports: React.FC = () => {
  const [data, setData] = useState({
    support1: {
      title: "Support 1",
      rows: [
        {
          key: "Support1_Guarantee",
          label: "Guarantee",
          value: 65000,
          isEditable: true,
        },
        {
          key: "Support1_Adjustments",
          label: "Plus Artist Adjustments",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support1_Misc",
          label: "Plus Misc",
          value: 0,
          isEditable: true,
        },
        { key: "Support1_Tax", label: "Tax %", value: 0, isEditable: true },
        {
          key: "Support1_LessDeposit",
          label: "Less Deposit",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support1_TotalDue",
          label: "Total Due",
          value: 65000,
          isEditable: true,
        },
      ],
    },
    support2: {
      title: "Support 2",
      rows: [
        {
          key: "Support2_Guarantee",
          label: "Guarantee",
          value: 1000,
          isEditable: true,
        },
        {
          key: "Support2_Adjustments",
          label: "Plus Artist Adjustments",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support2_Misc",
          label: "Plus Misc",
          value: 0,
          isEditable: true,
        },
        { key: "Support2_Tax", label: "Tax %", value: 0, isEditable: true },
        {
          key: "Support2_LessDeposit",
          label: "Less Deposit",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support2_TotalDue",
          label: "Total Due",
          value: 1000,
          isEditable: true,
        },
      ],
    },
    support3: {
      title: "Support 3",
      rows: [
        {
          key: "Support3_Guarantee",
          label: "Guarantee",
          value: 2000,
          isEditable: true,
        },
        {
          key: "Support3_Adjustments",
          label: "Plus Artist Adjustments",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support3_Misc",
          label: "Plus Misc",
          value: 0,
          isEditable: true,
        },
        { key: "Support3_Tax", label: "Tax %", value: 0, isEditable: true },
        {
          key: "Support3_LessDeposit",
          label: "Less Deposit",
          value: 0,
          isEditable: true,
        },
        {
          key: "Support3_TotalDue",
          label: "Total Due",
          value: 2000,
          isEditable: true,
        },
      ],
    },
  });

  const handleBlur = (key: string, newValue: string | number) => {
    const updatedData = { ...data };
    Object.keys(updatedData).forEach((sectionKey) => {
      updatedData[sectionKey].rows = updatedData[sectionKey].rows.map((row) =>
        row.key === key ? { ...row, value: newValue } : row
      );
    });
    setData(updatedData);
  };

  return (
    <div className="grid grid-cols-3 gap-y-4">
      {Object.keys(data).map((sectionKey) => (
        <div key={sectionKey}>
          <SettlementVerticalTable
            title={data?.[sectionKey]?.title}
            data={data?.[sectionKey]?.rows ?? []}
            onBlur={(key, newValue) => handleBlur(key, newValue)}
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
              },
            ]}
            rowData={{
              name: "TOTAL DUE",
              value: "$94949",
            }}
            rowContainerClass="bg-blue-200 p-0.5"
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable: true,
              },
            ]}
            rowData={{
              name: "PAYMENT METHOD (check / wire)",
              value: "$94949",
            }}
            rowContainerClass="border p-0.5"
            inputClassName="!border-0"
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable: true,
              },
            ]}
            rowData={{
              name: "CHECK #",
              value: "$94949",
            }}
            rowContainerClass="border p-0.5"
            inputClassName="!border-0"
          />
          <TableRow
            columns={[
              {
                key: "name",
                accessor: "name",
                textAlign: "left",
              },
              {
                key: "value",
                accessor: "value",
                textAlign: "right",
                editable: true,
              },
            ]}
            rowData={{
              name: "PAID THROUGH:",
              value: "$94949",
            }}
            rowContainerClass="border p-0.5"
            inputClassName="!border-0"
          />
        </div>
      ))}
    </div>
  );
};

export default SupportArtistSupports;

import { getArtistEventRollups } from "@/queries/accounting";
import {
  getEvent,
  updateEvent,
  getEventSettlementInfo,
  getEventPeople,
} from "@/queries/events";
import { SettlementTab } from "@/types/settlement";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function useSettlementComponent() {
  const { id, type: settlementType } = useParams<{
    id: string;
    type: SettlementTab;
  }>();
  const navigate = useNavigate();
  const pageIndex = settlementType;
  const setPageIndex = (settlementType: SettlementTab) => {
    navigate(`/events/settlement/${settlementType}/${id}`);
    window.scrollTo(0, 0);
  };
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const queryClient = useQueryClient();
  const handleUpdateEvent = async (
    id = event.data?.id,
    data: Record<string, unknown>
  ) => {
    await updateEvent(id, {
      ...event.data,
      artists: event.data.artists?.map((artist) => artist.id),
      buyer: event.data.buyer.id,
      manifests: event.data.manifests?.map((manifest) => manifest.id),
      venue: event.data.venue.id,
      ...data,
    });
    queryClient.invalidateQueries(["event-detail", id]);
    queryClient.invalidateQueries(["event-rollups", "artist", id]);
    event.refetch();
    rollups.refetch();
  };

  const settlement = useQuery(["event-settlement-info", id], () =>
    getEventSettlementInfo(id)
  );
  const people = useQuery(["event", "people", id], () => getEventPeople(id));
  const rollups = useQuery(["event-rollups", "artist", id], () =>
    getArtistEventRollups(id)
  );
  const location = useLocation();

  const isPDF = location.pathname.includes("pdf");
  return {
    id,
    pageIndex,
    setPageIndex,
    event,
    queryClient,
    handleUpdateEvent,
    settlement,
    people,
    rollups,
    isPDF,
  };
}

import React from 'react';
import dayjs from 'dayjs';

const YearView = ({ events, year, onDateClick, isLoading, error }) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const getEventsForDate = (date) => {
    return events.filter(event => dayjs(event.date).isSame(date, 'day'));
  };

  const renderMonth = (month) => {
    const daysInMonth = dayjs(`${year}-${month + 1}`).daysInMonth();
    const firstDayOfMonth = dayjs(`${year}-${month + 1}-01`).day();
    
    const days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="h-8 w-8"></div>);
    }
    
    for (let day = 1; day <= daysInMonth; day++) {
      const date = dayjs(`${year}-${month + 1}-${day}`);
      const dayEvents = getEventsForDate(date);
      days.push(
        <div
          key={`day-${day}`}
          className="border h-20 flex flex-col items-center justify-start text-xs cursor-pointer relative overflow-hidden"
          onClick={() => onDateClick({ date: date.toDate() })}
        >
          <span className="mb-1">{day}</span>
          {dayEvents.length > 0 && (
            <div className="absolute bottom-0 left-0 right-0 flex flex-col">
              {dayEvents.map((event, index) => (
                <div
                  key={index}
                  className="h-1 w-full"
                  style={{ backgroundColor: event.backgroundColor }}
                  title={event.name}
                ></div>
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <div key={month} className="p-2">
        <h3 className="text-sm font-semibold mb-2">{months[month]}</h3>
        <div className="grid grid-cols-7 gap-1 text-center">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} className="text-xs font-medium">{day.charAt(0)}</div>
          ))}
          {days}
        </div>
      </div>
    );
  };

  const renderContent = () => (
    <div className="grid grid-cols-3 gap-4">
      {months.map((_, index) => renderMonth(index))}
    </div>
  );

  if (error) {
    return <div className="p-4 text-center text-red-500">Error loading yearly events.</div>;
  }

  return (
    <div className="relative">
      {renderContent()}
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-75 flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      )}
    </div>
  );
};

export default YearView;

import React, { useEffect, useState } from "react"
import { Header } from "@/components/Header"
import { useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { BreadCrumbs } from '@/components/BreadCrumbs'
import Button from '@/components/Button'
import { getArtists, getArtistsSettings } from "@/queries/artists"
import { usePageTitle } from '@/utils/pagetitle';
import { ToggleSwitch } from "@/components/Button/ToggleSwitch"
import { SortedTable } from "@/components/SortedTable/SortedTable"
import { PPeopleIcon } from "@/components/People/PeopleIcon";
import { AArtistIcon, ArtistIcon } from "@/components/Artist/Icon";


const FilteredArtistsTable = ({ artists, headers, total, canEdit, title, handleEditClick }) => {

  const navigate = useNavigate()

  const formatData = (artists) => {
      const filteredArtists = artists.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
    return filteredArtists 
  }

  const handleFormatCell = (accessor, rowData, rowIndex) => {
    if(accessor === "on_sale") {
      return rowData["on_sale"] ? dayjs(rowData["on_sale"]).format("MMM D, YYYY") : "No Date"
    }
    // return nothing for all other values
  }

  const handleSort = (key, item) => {
    if(key === "total") {
      return item["price"] * item["qty"]
    } else {
      return item[key]
    }
  }

  return (
    <SortedTable
      total={total} 
      data={formatData(artists)}
      headers={headers} 
      customSort={handleSort} 
      formatCell={handleFormatCell} 
      onEditClick={canEdit ? handleEditClick : null}
      handleRowClick={(row, index) => navigate(`/artists/${row.id}`)}
      hasSorting
      hasFilter
    />  
  )
};

export const ArtistsHome = () => {
  const artists = useQuery(["artists"], getArtists, getArtistsSettings);
  const navigate = useNavigate();
  const filteredArtists = artists?.data || [];
  const [isFadingOut, setIsFadingOut] = useState(false);
  usePageTitle(artists.isSuccess ? `/c${String.fromCharCode(92)}artists` : `/c${String.fromCharCode(92)}ave_good...`);

  useEffect(() => {
    if (artists.isLoading) {
      setIsFadingOut(false);
    } else if (artists.isSuccess) {
      setTimeout(() => {
        setIsFadingOut(true);
      }, 1000); // Adjust the delay as needed
    }
  }, [artists.isLoading, artists.isSuccess]);

  return (
    <div className="relative">
      {artists.isLoading && (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center bg-welcome bg-cover bg-center transition-opacity duration-1000 ${
            isFadingOut ? "opacity-0" : "opacity-100"
          }`}
          style={{ backgroundImage: "url('/static/images/welcome_to_cave_loading.jpg')" }}

        >
          <div className="animate-pulse text-white text-sm mt-4"><br /> <br /><br /><br /><br /><br /><br /><br /> <br /><br />/c\</div>
        </div>
      )}
      <div
        className={`transition-opacity duration-1000 ${
          artists.isLoading && !isFadingOut ? "opacity-0" : "opacity-100"
        }`}
      >
        <Header />

        <div className="flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full flex justify-between items-center">
            <BreadCrumbs links={[{ text: "Artists", url: "/artists" }]} />
            <span>
              <Button onClick={() => navigate("/artists/add")}>
                Add Artist
              </Button>
            </span>
          </div>

          <div className="my-2 w-full overflow-x-auto shadow-md sm:rounded-lg">
            <FilteredArtistsTable
              artists={filteredArtists}
              headers={[
                { header: "NAME", accessor: "name" },
                { header: "POC NAME", accessor: "poc_name" },
                { header: "POC EMAIL", accessor: "poc_email" },
                { header: "POC PHONE", accessor: "poc_phone" },
                { header: "FUTURE EVENTS", accessor: "future_events" },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};



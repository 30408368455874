import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Mousetrap from "mousetrap";
import * as yup from "yup";
import classnames from "classnames";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useForm, useFormContext } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { Disclosure } from "@headlessui/react";
import {
  ChevronRightIcon,
  EnvelopeOpenIcon,
  CreditCardIcon,
  MusicalNoteIcon,
  BuildingOfficeIcon,
  PresentationChartLineIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import {
  FormBuilder,
  Input,
  TextInput,
  FormSelect,
  selectOptionToValue,
  selectValueToOption,
  Toggle,
} from "@/components/Form";
import {
  getExpenseGroups,
  getExpenseGroupSettings,
  getEventExpenses,
  getEventExpenseFiles,
  getEventExpensesSettings,
  createEventExpense,
  markExpensePaid,
  markExpenseUnpaid,
  updateEventExpense,
  getEventRollups,
  getEventCopros,
  deleteExpense,
  createExpenseFile,
} from "@/queries/accounting";
import {
  amountDisplay,
  formatDollarsToCents,
  formatCentsToDollars,
} from "@/utils/money";
import { usePerms } from "@/components/Auth";
import Select from "react-select";
import { FilterDropdown } from "../../components/FilterDropdown/FilterDropdown";
import Button from "@/components/Button";
import styles from "./accountingComponents.module.css";

const PaymentSelectOptions = [
  { value: "check", label: "Check" },
  { value: "cash", label: "Cash" },
  { value: "cc", label: "Credit Card" },
  { value: "office", label: "Office" },
  { value: "wire", label: "Wire" },
  { value: "ach", label: "ACH" },
  { value: "internal", label: "Internal" },
  { value: "building", label: "Building" },
];

const getPaymentOptions = (copros) => {
  let options = [...PaymentSelectOptions];
  if (copros.length > 0) {
    copros.map((copro) => {
      options.push({ value: copro.id, label: `Copro - ${copro.name}` });
    });
  }
  return options;
};

const PaymentOptions = (props) => {
  const { copros, defaultValue } = props;
  const options = getPaymentOptions(copros);

  return (
    <FormSelect
      name="payment_method"
      label="Payout"
      options={options}
      defaultValue={defaultValue}
    />
  );
};

const SubGroupOptions = (props) => {
  const { subGroups } = props;
  const options = subGroups.map((subGroup) => {
    return { value: subGroup.id, label: subGroup.name };
  });
  return <FormSelect name="subgroup" label="Subgroup" options={options} />;
};

const AddForm = (props) => {
  const { title, cancelClick, subGroups, eventID, books } = props;
  const copros = useQuery(["event-copros", eventID], () =>
    getEventCopros(eventID)
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    Mousetrap.bind("esc", () => {
      cancelClick();
    });
    return () => {
      Mousetrap.unbind("esc");
    };
  }, []);

  if (copros.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  const CheckNumberInput = (props) => {
    const { watch } = useFormContext();
    const [display, setDisplay] = useState(false);

    useEffect(() => {
      const subscription = watch((value, { name, type }) => {
        if (name !== "payment_method") {
          return;
        }

        if (value.payment_method.value !== "check") {
          setDisplay(false);
        } else {
          setDisplay(true);
        }

        console.log("Sub", value.payment_method.value, display);
      });

      return () => {
        subscription.unsubscribe();
      };
    }, [watch]);

    if (!display) {
      return null;
    }

    return (
      <Input
        label="Check Number"
        name="check_number"
        placeholder="Optional check number"
      />
    );
  };

  const addSchema = yup.object().shape({
    payment_method: yup.object().required(),
    description: yup.string().required(),
    amount: yup.string().required(),
  });

  let values = {
    payment_method: { value: "office", label: "Office" },
  };

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating new expense");
      console.dir(data);

      // Handle missing check_number situation
      if (data.payment_method !== "check") {
        data.check_number = "";
      }

      // Ensure our booleans are not strings
      if (data.exclude_artist === "") {
        data.exclude_artist = false;
      }
      if (data.exclude_copro === "") {
        data.exclude_copro = false;
      }
      if (data.exclude_final === "") {
        data.exclude_final = false;
      }

      data = selectOptionToValue("payment_method", data);
      data = selectOptionToValue("subgroup", data);
      if (books == "offer") {
        data.is_offer = true;
      } else {
        data.is_offer = false;
      }

      data.amount = formatDollarsToCents(data.amount);
      console.dir(data);
      return createEventExpense(eventID, data);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["expenses"]);
      cancelClick();
    },
  });

  const formClasses = classnames(
    "mt-4 mb-4 p-6 border border-2 border-blue-300",
    {
      "bg-gray-100 dark:bg-gray-800": books === "actual",
      "bg-blue-100 dark:bg-gray-800": books === "offer",
    }
  );

  // Whether or not the Exclusions box is open or not
  let exclusionsDefaultOpen = false;
  if (books === "artist" || books == "copro" || books === "final") {
    exclusionsDefaultOpen = true;
  }

  console.log("subgroups", subGroups);

  return (
    <div className={formClasses}>
      <h3 className="text-xl font-bold mb-4 dark:text-white">{title}</h3>
      <FormBuilder
        defaultValues={values}
        onSubmit={(data) => {
          mutation.mutate(data);
        }}
        schema={addSchema}
      >
        <SubGroupOptions subGroups={subGroups} />
        <PaymentOptions copros={copros.data} />
        <Input
          label="Description"
          name="description"
          placeholder="Short description of the expense"
        />
        <CheckNumberInput />
        <Input label="Amount" name="amount" placeholder="$0.00" />
        <TextInput
          label="Notes"
          name="notes"
          placeholder="Optional notes here"
          rows={3}
        />
        <div className="my-4">
          <Disclosure defaultOpen={exclusionsDefaultOpen}>
            {({ open }) => (
              <>
                <Disclosure.Button className="p-2 flex justify-between w-full text-left font-semibold bg-gray-300 hover:bg-gray-200">
                  Exclusions
                  <ChevronRightIcon
                    className={open ? "rotate-90 transform w-6 h-6" : "w-6 h-6"}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="text-gray-500 py-2">
                  <div className="my-2">
                    <Toggle
                      label="Exclude From Artist"
                      name="exclude_artist"
                      value={false}
                    />
                  </div>
                  <div className="my-2">
                    <Toggle
                      label="Exclude From CoPro"
                      name="exclude_copro"
                      value={false}
                    />
                  </div>
                  <div className="my-2">
                    <Toggle
                      label="Exclude From Final"
                      name="exclude_final"
                      value={false}
                    />
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
        <div className="w-full">
          <div className="float-right">
            <button
              type="button"
              onClick={cancelClick}
              className="mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-blue-600 px-12 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Save
            </button>
          </div>
        </div>
      </FormBuilder>
    </div>
  );
};

export const SectionHeader = (props) => {
  const {
    title,
    adding,
    collapsible,
    canEdit,
    buttonClick,
    bgPress,
    subGroups,
    eventID,
    books,
    onlyMarketing,
  } = props;

  const [expand, setExpand] = useState(false);

  const classes = classnames(
    "mb-2 float-right rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
    {
      "text-white": !adding,
      "bg-blue-500": !adding,
      "hover:bg-blue-400 focus-visible:outline-blue-500": !adding,
      "text-gray-500": adding,
      "bg-yellow-300": adding,
      "cursor-not-allowed": adding,
    }
  );

  const parentClasses = classnames(
    "w-full my-4 border-b-2 border-gray-200 dark:border-gray-600 bg-gray-600 px-[10px] rounded-[0px]",
    {
      "flex justify-between hover:bg-gray-200 dark:hover:bg-gray-600 py-2 cursor-pointer":
        collapsible,
    }
  );

  let buttonText = `Add ${title}`;
  if (books == "offer") {
    buttonText = `Add Offer ${title}`;
  }

  const handleCollapsiblePress = () => {
    setExpand(!expand);
    bgPress && bgPress();
  };
  return (
    <>
      <div className={parentClasses} onClick={handleCollapsiblePress}>
        <h2 className="text-xl font-bold float-left mb-2 dark:text-white">
          {title}
        </h2>
        <div className="flex items-center">
          {canEdit && (
            <Button onClick={buttonClick} disabled={adding} size="sm">
              {buttonText}
            </Button>
          )}
          {expand ? (
            <ChevronDownIcon className={styles.chevronAccordionDown} />
          ) : (
            <ChevronUpIcon className={styles.chevronAccordionUp} />
          )}
        </div>
      </div>
      {adding && (
        <AddForm
          title={buttonText}
          cancelClick={buttonClick}
          subGroups={subGroups}
          eventID={eventID}
          books={books}
        />
      )}
    </>
  );
};

const Edit = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
      />
    </svg>
  );
};

const Due = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6 text-red-500"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

const Paid = (props) => {
  const { paidOn } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6 text-green-500"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

export const Expand = (props) => {
  const { expanded, onClick } = props;
  const classes = classnames(
    "w-6 h-6 hover:text-blue-500 hover:bg-blue-100 dark:bg-white dark:text-black",
    {
      "text-white bg-blue-500 dark:bg-blue-500": expanded,
    }
  );

  return (
    <div onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={classes}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
        />
      </svg>
    </div>
  );
};

export const Collapse = (props) => {
  const { expanded, onClick } = props;
  const classes = classnames(
    "w-6 h-6 hover:text-blue-500 hover:bg-blue-100  dark:bg-white dark:text-black",
    {
      "text-white bg-blue-500 dark:bg-blue-500": !expanded,
    }
  );
  return (
    <div onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={classes}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
        />
      </svg>
    </div>
  );
};

const PaymentDisplay = (props) => {
  const { expense, canEdit } = props;
  const queryClient = useQueryClient();

  // determine what text to show
  let displayText = "";
  switch (expense.payment_method) {
    case "check":
      let checkNumber = "";
      if (expense.check_number) {
        checkNumber = `# ${expense.check_number}`;
      }
      displayText = `CHECK ${checkNumber}`;
      break;
    case "cash":
      displayText = "CASH";
      break;
    case "office":
      displayText = "OFFICE";
      break;
    case "building":
      displayText = "BUILDING";
      break;
    case "copro":
      displayText = "COPRO";
      break;
    case "wire":
      displayText = "WIRE";
      break;
    case "ach":
      displayText = "ACH";
      break;
    case "internal":
      displayText = "Internal";
      break;
    default:
      displayText = "UNKNOWN";
  }

  const mutation = useMutation({
    mutationFn: (data) => {
      if (!canEdit) {
        return;
      }
      console.log("---> marking expense paid");
      return markExpensePaid(expense.id, expense.check_number);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["expenses"]);
    },
  });

  const revertMutation = useMutation({
    mutationFn: (data) => {
      if (!canEdit) {
        return;
      }
      console.log("---> marking expense unpaid");
      return markExpenseUnpaid(expense.id, expense.check_number);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["expenses"]);
    },
  });

  return (
    <div className="flex inline-flex w-full">
      <div className="mr-2">
        {expense.paid ? (
          <>
            <Paid />
          </>
        ) : (
          <Due />
        )}
      </div>
      <span>
        {displayText}{" "}
        {expense.paid && (
          <span className="text-gray-400">
            Paid {dayjs(expense.paid_on).format("dddd MMM D, YYYY")}
          </span>
        )}
      </span>
      {!expense.paid && (
        <button
          type="button"
          className="ml-2 rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          onClick={() => mutation.mutate(expense.id, expense.check_number)}
        >
          Mark as paid
        </button>
      )}
      {expense.paid && (
        <button
          type="button"
          className="ml-2 rounded bg-red-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          onClick={() =>
            revertMutation.mutate(expense.id, expense.check_number)
          }
        >
          Mark as unpaid
        </button>
      )}
    </div>
  );
};

const EditFiles = (props) => {
  const { expense, classes, toggleEditingFiles, canEdit } = props;
  const files = useQuery(["event-expense-files", expense.id], () =>
    getEventExpenseFiles(expense.id)
  );
  const queryClient = useQueryClient();
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const buttonClasses = classnames(
    "mb-2 float-right rounded-md px-6 py-2 bg-green-400 hover:bg-green-600 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
    {}
  );

  const validationSchema = yup.object().shape({
    file: yup.mixed(),
    name: yup.string().required(),
  });

  const { register, errors, handleSubmit, control, setValue } = useForm({
    validationSchema,
  });

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating expense file");
      console.dir(data);
      setIsSubmitting(true);
      return createExpenseFile(expense.id, data);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["event-expense-files", expense.id]);
      setIsSubmitting(false);
    },
  });

  const { acceptedFiles, getRootProps, getInputProps, isDragAccept } =
    useDropzone({
      onDrop: (files) => {
        setValue("file", files);
        setFile(files[0]);
      },
    });

  const hasFiles = acceptedFiles.length > 0;
  let fileName = null;
  if (hasFiles) {
    fileName = acceptedFiles[0].name;
  }

  console.log("acceptedFiles", acceptedFiles);
  const onSubmit = (data) => {
    console.log("Submitting");
    console.dir(data);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("file", file);
    mutation.mutate(formData);
  };

  if (files.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  return (
    <div className={classes}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="inline-flex">
          <div
            className="text-blue-600 hover:text-blue-400"
            onClick={toggleEditingFiles}
          >
            {canEdit && <Edit />}
          </div>
          <span className="ml-4 font-bold">Editing Files</span>
        </div>

        <h2 className="text-lg font-semibold my-6">Files</h2>

        {files.data.map((file, index) => {
          return (
            <div
              className="p-1 px-2 bg-green-100 hover:bg-gray-100 border border-gray-500 my-2"
              onClick={() => window.open(file.file, "_blank")}
            >
              {file.name}
            </div>
          );
        })}

        <h2 className="text-lg font-semibold my-6">Upload New File</h2>
        <div
          {...getRootProps()}
          className="my-4 p-4 flex items-center justify-center bg-gray-100 border border-gray-400 hover:bg-gray-200 cursor-pointer"
        >
          <p className="text-gray-600">
            {!hasFiles && (
              <em>Drag 'n' drop file here or click to select file</em>
            )}
            {hasFiles && (
              <>
                Ready to upload: <strong>{fileName}</strong>
              </>
            )}
          </p>
          <input
            ref={register("file", { required: true })}
            {...getInputProps()}
          />
        </div>

        <div className="my-3">
          <label
            htmlFor="name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Description
          </label>
          <input
            {...register("name", { required: true })}
            id="name"
            name="name"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="pb-8">
          <button
            type="submit"
            className="ml-2 mb-2 float-right rounded-md px-6 py-2 bg-red-400 hover:bg-red-600 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={() => toggleEditingFiles()}
          >
            <span>Cancel</span>
          </button>
          <button type="submit" className={buttonClasses}>
            {!isSubmitting && <span>Save</span>}
            {isSubmitting && <span>Uploading...</span>}
          </button>
        </div>
      </form>
    </div>
  );
};

const ExpandedExpense = (props) => {
  const { expense, index, count, canEdit, onlyMarketing } = props;
  const [editing, setEditing] = useState(false);
  const [editingFiles, setEditingFiles] = useState(false);
  const amtDisplay = amountDisplay(expense.amount);
  const queryClient = useQueryClient();
  const copros = useQuery(["event-copros", expense.event], () =>
    getEventCopros(expense.event)
  );

  const toggleEditing = () => {
    if (canEdit) {
      setEditing(!editing);
    }
  };

  const toggleEditingFiles = () => {
    if (canEdit) {
      setEditingFiles(!editingFiles);
    }
  };

  const buttonClasses = classnames(
    "mb-2 float-right rounded-md px-6 py-2 bg-green-400 hover:bg-green-600 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
    {}
  );

  const classes = classnames("w-full mt-2 mb-2", {
    "border-b-2 border-gray-400": index + 1 !== count,
    "pt-2": index !== 0,
    "pb-2": index + 1 !== count,
    "pb-4": index === 0 && count !== 1,
  });

  const mutation = useMutation({
    mutationFn: (data) => {
      console.log("---> updating expense");
      data = selectOptionToValue("payment_method", data);
      data.amount = formatDollarsToCents(data.amount);
      return updateEventExpense(data);
    },
    onSuccess: async () => {
      toggleEditing();
      queryClient.invalidateQueries(["expenses"]);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (data) => {
      console.log("---> deleting expense");
      toggleEditing();
      return deleteExpense(expense.id);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["expenses"]);
    },
  });

  const updateSchema = yup.object().shape({
    expense_id: yup.number().required(),
    payment_method: yup.object().required(),
    description: yup.string().required(),
    amount: yup.string().required(),
    notes: yup.string(),
    exclude_artist: yup.boolean(),
    exclude_copro: yup.boolean(),
    exclude_final: yup.boolean(),
  });

  if (copros.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  let paymentOptions = getPaymentOptions(copros.data);
  let paymentObject = null;
  if (expense.payment_method) {
    let method = expense.payment_method;
    if (method == "copro") {
      method = expense.copro;
    }
    paymentObject = selectValueToOption(method, paymentOptions);
  }

  // Edit Expense
  if (editing) {
    let values = {
      expense_id: expense.id,
      description: expense.description,
      payment_method: paymentObject,
      amount: formatCentsToDollars(expense.amount),
      check_number: expense.check_number || "",
      notes: expense.notes,
      exclude_artist: expense.exclude_artist,
      exclude_copro: expense.exclude_copro,
      exclude_final: expense.exclude_final,
    };

    console.log("editing...", values);
    const CheckNumberInput = (props) => {
      const { watch } = useFormContext();
      const [display, setDisplay] = useState(false);

      useEffect(() => {
        const subscription = watch((value, { name, type }) => {
          if (name !== "payment_method") {
            return;
          }

          if (value.payment_method.value !== "check") {
            setDisplay(false);
          } else {
            setDisplay(true);
          }

          console.log("Sub", value.payment_method.value, display);
        });

        if (values.payment_method.value === "check") {
          setDisplay(true);
        }

        return () => {
          subscription.unsubscribe();
        };
      }, [watch]);

      if (!display) {
        return null;
      }

      return (
        <Input
          label="Check Number"
          name="check_number"
          placeholder="Optional check number"
        />
      );
    };

    let defaultOpen = false;
    if (
      expense.exclude_artist ||
      expense.exclude_copro ||
      expense.exclude_final
    ) {
      defaultOpen = true;
    }

    return (
      <div className={classes}>
        <FormBuilder
          defaultValues={values}
          onSubmit={(data) => {
            mutation.mutate(data);
          }}
          schema={updateSchema}
        >
          <div className="inline-flex">
            <div
              className="text-blue-600 hover:text-blue-400"
              onClick={toggleEditing}
            >
              {canEdit && <Edit />}
            </div>
            <span className="ml-4 font-bold">Editing</span>
          </div>
          <Input
            name="description"
            label="Description"
            placeholder="Short description of the expense"
          />
          <PaymentOptions copros={copros.data} canEdit={canEdit} />
          <CheckNumberInput />
          <Input name="amount" label="Amount" placeholder="000" />
          <Input name="notes" label="Notes" placeholder="Optional notes" />
          <div className="my-4">
            <Disclosure defaultOpen={defaultOpen}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="p-2 flex justify-between w-full text-left font-semibold bg-gray-300 hover:bg-gray-200">
                    Exclusions
                    <ChevronRightIcon
                      className={
                        open ? "rotate-90 transform w-6 h-6" : "w-6 h-6"
                      }
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-500 py-2">
                    <div className="my-2">
                      <Toggle
                        label="Exclude From Artist"
                        name="exclude_artist"
                        value={expense.exclude_artist}
                      />
                    </div>
                    <div className="my-2">
                      <Toggle
                        label="Exclude From CoPro"
                        name="exclude_copro"
                        value={expense.exclude_copro}
                      />
                    </div>
                    <div className="my-2">
                      <Toggle
                        label="Exclude From Final"
                        name="exclude_final"
                        value={expense.exclude_final}
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="pb-8">
            <button
              type="button"
              className="ml-1 mr-4 rounded-md bg-red-400 px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              onClick={() => deleteMutation.mutate()}
            >
              Delete Expense
            </button>
            <button
              type="button"
              onClick={toggleEditing}
              className="ml-4 mr-4 float-right rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Cancel
            </button>
            <button type="submit" className={buttonClasses}>
              Save
            </button>
          </div>
        </FormBuilder>
      </div>
    );
  }

  // Edit Files
  if (editingFiles) {
    return (
      <>
        <EditFiles
          expense={expense}
          classes={classes}
          toggleEditingFiles={toggleEditingFiles}
          canEdit={canEdit}
        />
      </>
    );
  }

  const ExclusionText = (props) => {
    const { expense } = props;
    const { exclude_artist, exclude_copro, exclude_final } = expense;

    // This expense is not excluded from anything
    if (!exclude_artist && !exclude_copro && !exclude_final) {
      return null;
    }

    const exclusions = [];
    if (exclude_artist) {
      exclusions.push("Artist Settlement");
    }
    if (exclude_copro) {
      exclusions.push("CoPro Settlement");
    }
    if (exclude_final) {
      exclusions.push("Final Settlement");
    }

    let exclusionText = "";
    if (exclusions.length === 1) {
      exclusionText = exclusions[0];
    } else if (exclusions.length > 1) {
      exclusionText =
        exclusions.slice(0, exclusions.length - 1).join(", ") +
        " and " +
        exclusions[exclusions.length - 1];
    }

    return (
      <div className="mt-4 ml-4 text-sm italic font-light">
        Excluded from {exclusionText}
      </div>
    );
  };

  return (
    <div className={classes}>
      <div className="grid grid-cols-4">
        <span className="font-semibold text-gray-900 flex flex-inline">
          <div
            className="mr-4 text-gray-400 hover:text-blue-600"
            onClick={toggleEditing}
          >
            {canEdit && <Edit />}
          </div>
          {expense.description}
        </span>
        <PaymentDisplay expense={expense} canEdit={canEdit} />
        <span className="text-gray-900">
          <div className="text-right">
            <button
              type="button"
              className="ml-2 rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={toggleEditingFiles}
            >
              {expense.file_count === 0
                ? `Click here to upload `
                : `${expense.file_count} Files`}
            </button>
          </div>
        </span>
        <span className="text-right">{amtDisplay}</span>
      </div>
      {expense.notes && (
        <div className="mt-4 ml-4 text-sm">{expense.notes}</div>
      )}
      <ExclusionText expense={expense} />
    </div>
  );
};

const ExpandedSubGroup = (props) => {
  const { subgroup, expenses, total, totalDisplay, toggleClosed, canEdit } =
    props;
  const expenseCount = expenses.length;

  const classes = classnames("grid grid-cols-3 items-center font-bold", {
    "bg-blue-300 hover:bg-blue-200 dark:hover:bg-blue-400 dark:hover:text-gray-800":
      expenseCount > 0,
  });

  const outerClasses = classnames("", {
    "bg-blue-100": expenseCount > 0,
  });

  return (
    <div className={outerClasses}>
      <div onClick={toggleClosed} className={classes}>
        <span className="py-4 px-2">{subgroup.name}</span>
        <span></span>
        <span className="text-right items-center px-2">{totalDisplay}</span>
      </div>
      <div className="p-2">
        {expenses.map((expense, index) => (
          <ExpandedExpense
            key={index}
            index={index}
            count={expenseCount}
            expense={expense}
            canEdit={canEdit}
          />
        ))}
      </div>
    </div>
  );
};

const SubGroup = (props) => {
  const { subgroup, expenses, expandAll, canEdit } = props;
  const [expanded, setExpanded] = useState(false);
  const subGroupExpenses = expenses.filter(
    (expense) => expense.subgroup === subgroup.id
  );
  const total = subGroupExpenses.reduce(
    (total, expense) => total + expense.amount,
    0
  );
  const totalDisplay = amountDisplay(total);

  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  if (!subgroup.default && subGroupExpenses.length === 0) {
    return null;
  }

  if (expanded) {
    return (
      <ExpandedSubGroup
        subgroup={subgroup}
        expenses={subGroupExpenses}
        total={total}
        totalDisplay={totalDisplay}
        toggleClosed={() => setExpanded(!expanded)}
        canEdit={canEdit}
      />
    );
  }

  if (!subGroupExpenses.length) {
    return (
      <div className="grid grid-cols-2 items-center hover:bg-gray-200 dark:hover:bg-gray-400 dark:hover:text-gray-800 dark:bg-white">
        <span className="py-4 px-2 dark:text-gray-800">{subgroup.name}</span>
        <span colSpan="2" className="text-right pr-4">
          <em>empty</em>
        </span>
      </div>
    );
  }

  const PaidOrNot = (props) => {
    let paid = false;
    let allPaid = true;
    Array.from(subGroupExpenses).forEach((expense) => {
      switch (expense.paid) {
        case true:
          paid = true;
          break;
        case false:
          allPaid = false;
          break;
      }
    });

    if (paid && allPaid) {
      return (
        <>
          <Paid />
        </>
      );
    }
    if (paid && !allPaid) {
      return (
        <>
          <Paid /> <span className="font-thin mr-2 ml-2">/</span> <Due />
        </>
      );
    }
    return (
      <>
        <Due />
      </>
    );
  };

  const MiddleColumn = (props) => {
    const totalHeader = (
      <span className="text-gray-400">({subGroupExpenses.length})</span>
    );
    const paymentSet = new Set(
      subGroupExpenses.map((expense) => expense.payment_method.toUpperCase())
    );

    return (
      <div className="grid grid-cols-3 items-center">
        <span className="text-gray-400">{totalHeader}</span>
        <span className="flex flex-inline">
          <PaidOrNot />
        </span>
        <span>{Array.from(paymentSet).join(", ")}</span>
      </div>
    );
  };
  return (
    <div
      onClick={() => setExpanded(!expanded)}
      className="grid grid-cols-3 items-center hover:bg-gray-200 dark:hover:bg-gray-400 dark:hover:text-gray-800 dark:bg-white"
    >
      <span className="py-4 px-2 dark:text-gray-800">{subgroup.name}</span>
      <span>
        <MiddleColumn />
      </span>
      <span className="text-right px-2">{totalDisplay}</span>
    </div>
  );
};

const ExpenseTable = (props) => {
  const { books, group, expenses, expandAll, eventID, onlyMarketing } = props;
  const [adding, setAdding] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [sortedSubGroups, setSortedSubGroups] = useState([...group.subgroups]);
  const [sortOption, setSortOption] = useState(null);
  const [filters, setFilters] = useState([]);
  const title = group.name;
  const subGroupIDs = group.subgroups.map((subgroup) => subgroup.id);
  const subGroupExpenses = expenses.filter((expense) =>
    subGroupIDs.includes(expense.subgroup)
  );
  const total = subGroupExpenses.reduce(
    (total, expense) => total + expense.amount,
    0
  );
  const totalDisplay = amountDisplay(total);
  const { perms } = usePerms();

  useEffect(() => {
    setSortedSubGroups([...group.subgroups]);
  }, [group.subgroups]);

  const sortOptions = [
    { value: "relevant", label: "Most Relevant" },
    { value: "name-asc", label: "Name A-Z" },
    { value: "name-desc", label: "Name Z-A" },
    { value: "total-asc", label: "Total Ascending" },
    { value: "total-desc", label: "Total Descending" },
  ];

  // Determine permissions
  let canEdit = false;
  if (perms.data.edit_events) {
    canEdit = true;
  }

  const handleSort = (selectedOption) => {
    setSortOption(selectedOption);
    switch (selectedOption.value) {
      case "name-asc":
        setSortedSubGroups(
          [...sortedSubGroups].sort((a, b) => a.name.localeCompare(b.name))
        );
        break;
      case "name-desc":
        setSortedSubGroups(
          [...sortedSubGroups].sort((a, b) => b.name.localeCompare(a.name))
        );
        break;
      case "total-asc":
        setSortedSubGroups(
          [...sortedSubGroups].sort((a, b) => {
            const totalA = subGroupExpenses
              .filter((exp) => exp.subgroup === a.id)
              .reduce((acc, curr) => acc + curr.amount, 0);
            const totalB = subGroupExpenses
              .filter((exp) => exp.subgroup === b.id)
              .reduce((acc, curr) => acc + curr.amount, 0);
            return totalA - totalB;
          })
        );
        break;
      case "total-desc":
        setSortedSubGroups(
          [...sortedSubGroups].sort((a, b) => {
            const totalA = subGroupExpenses
              .filter((exp) => exp.subgroup === a.id)
              .reduce((acc, curr) => acc + curr.amount, 0);
            const totalB = subGroupExpenses
              .filter((exp) => exp.subgroup === b.id)
              .reduce((acc, curr) => acc + curr.amount, 0);
            return totalB - totalA;
          })
        );
        break;
      case "relevant":
      default:
        setSortedSubGroups([...group.subgroups]);
        break;
    }
  };

  const TotalRender = (
    <div className="grid grid-cols-3 sm:grid-cols-3">
      <span
        className={`text-left font-bold p-2 ${
          !showTable ? "bg-transparent" : "bg-gray-100"
        } dark:text-white`}
      ></span>
      <span
        className={`text-left font-bold p-2 ${
          !showTable ? "bg-transparent" : "bg-gray-100"
        } dark:text-white`}
      ></span>
      <span
        className={`text-right font-bold p-2 ${
          !showTable ? "bg-transparent" : "bg-gray-100"
        } text-gray-400 dark:text-white`}
      >
        {!onlyMarketing && <> Total {totalDisplay}</>}
      </span>
    </div>
  );

  return (
    <>
      <div
        className="flex flex-col xs:flex-col sm:flex-row items-center justify-between py-2 cursor-pointer bg-[#323232] px-[10px] rounded-[10px] relative"
        onClick={() => setShowTable(!showTable)}
      >
        <h2 className="text-xl font-bold float-left mb-2 text-white">
          {title}
        </h2>
        <div className="flex items-center">
          {canEdit && (
            <button
              onClick={() => setAdding(!adding)}
              className="min-w-[10rem] ml-2 rounded-[5px] bg-[#e7dfd9] text-[black] px-4 py-[5px] text-sm font-semibold text-black shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Add {title}
            </button>
          )}
          {showTable ? (
            <ChevronDownIcon className={"w-6 h-6 dark:text-white ml-2"} />
          ) : (
            <ChevronUpIcon className="w-6 h-6 dark:text-white ml-2" />
          )}
        </div>
      </div>
      {showTable ? (
        <div className="flex flex-col">
          <div className="flex flex-inline justify-start items-center gap-x-2">
            <ExpenseFilter
              filterOptions={[
                { header: "Name", type: "string" },
                { header: "Total", type: "number" },
              ]}
              parentFilters={filters}
              expenses={subGroupExpenses}
              subGroups={group.subgroups}
              onFilteredData={setSortedSubGroups}
              updateFilters={setFilters}
            />
            <Select
              options={sortOptions}
              value={sortOption}
              onChange={handleSort}
              className="mb-4 max-w-[250px] min-w-[200px]"
            />
          </div>
          <div className="border border-gray-200 w-full">
            <div className="grid grid-cols-3 bg-gray-800">
              <span className="text-left font-bold p-2 bg-gray-200">Name</span>
              <span className="text-left font-bold p-2 bg-gray-200"></span>
              <span className="text-right font-bold p-2 bg-gray-200">
                Total {totalDisplay}
              </span>
            </div>
            <div>
              {sortedSubGroups.map((item, index) => (
                <SubGroup
                  key={index}
                  subgroup={item}
                  expenses={expenses}
                  expandAll={expandAll}
                  canEdit={canEdit}
                  onlyMarketing={onlyMarketing}
                />
              ))}
            </div>
            {TotalRender}
          </div>
        </div>
      ) : (
        TotalRender
      )}
    </>
  );
};

const ExpenseFilter = ({
  filterOptions,
  subGroups,
  expenses,
  onFilteredData,
  parentFilters,
  updateFilters,
}) => {
  const [filters, setFilters] = useState(parentFilters);
  const [filteredData, setFilteredData] = useState(
    filterInitialData(parentFilters)
  );

  useEffect(() => {
    setFilters(parentFilters);
  }, [parentFilters]);

  useEffect(() => {
    onFilteredData(filteredData);
  }, [filteredData, onFilteredData]);

  function filterInitialData(filters) {
    return filterData(filters, subGroups);
  }

  const applyFilters = (newFilters) => {
    // update local state
    setFilters(newFilters);
    // update parent state - tracks filters so when table is opened after closed, the filter state will persist
    updateFilters(newFilters);
    let newData = [...subGroups];
    setFilteredData(filterData(newFilters, newData));
  };

  function filterData(newFilters, newData) {
    newFilters.forEach((filter) => {
      if (filter.field.value && filter.type && filter.value) {
        if (filter.field.value === "Name") {
          newData = filterName(newData, filter);
        } else if (filter.field.value === "Total") {
          newData = filterTotal(newData, filter);
        }
      }
    });
    return newData;
  }

  function filterName(newData, filter) {
    newData = newData.filter((row) => {
      const itemToFilter = row["name"];
      switch (filter.type) {
        case "contains":
          return itemToFilter
            .toString()
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        case "does not contain":
          return !itemToFilter
            .toString()
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        case "equals":
          return itemToFilter.toString() === filter.value.toString();
        default:
          return true;
      }
    });
    return newData;
  }

  function filterTotal(newData, filter) {
    newData = newData.filter((row) => {
      const subGroupTotal = expenses
        .filter((expense) => expense.subgroup === row.id)
        .reduce((total, expense) => total + expense.amount, 0);
      switch (filter.type) {
        case "equals":
          return parseFloat(subGroupTotal / 100) === parseFloat(filter.value);
        case "greater_than":
          return parseFloat(subGroupTotal / 100) > parseFloat(filter.value);
        case "less_than":
          return parseFloat(subGroupTotal / 100) < parseFloat(filter.value);
        default:
          return true;
      }
    });
    return newData;
  }

  return (
    <FilterDropdown
      parentFilters={filters}
      options={filterOptions}
      onFilterUpdate={applyFilters}
    />
  );
};

const BookTab = (props) => {
  const { Icon, name, book, setBooks, currentBook } = props;

  const active = currentBook === book;

  const hrefClasses = classnames(
    "border-transparent group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium",
    {
      "text-gray-500 dark:text-white": !active,
      "hover:border-gray-300": !active,
      "hover:text-gray-700": !active,
      "cursor-pointer": !active,
      "cursor-default": active,
      "text-blue-500": active,
    }
  );

  const iconClasses = classnames("-ml-0.5 mr-2 w-5 h-5", {
    "text-gray-500 dark:text-white": !active,
    "text-blue-500 ": active,
  });

  return (
    <a className={hrefClasses} onClick={() => setBooks(book)}>
      <Icon className={iconClasses} />
      <span>{name}</span>
    </a>
  );
};

const BookTabs = (props) => {
  const { books, setBooks, onlyMarketing } = props;
  return (
    <div className="hidden sm:block">
      <div className="border-b border-gray-200 dark:border-gray-700">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {!onlyMarketing && (
            <BookTab
              name="Offer"
              book="offer"
              setBooks={setBooks}
              currentBook={books}
              Icon={EnvelopeOpenIcon}
            />
          )}
          {!onlyMarketing && (
            <BookTab
              name="Artist"
              book="artist"
              setBooks={setBooks}
              currentBook={books}
              Icon={MusicalNoteIcon}
            />
          )}
          {!onlyMarketing && (
            <BookTab
              name="CoPro"
              book="copro"
              setBooks={setBooks}
              currentBook={books}
              Icon={BuildingOfficeIcon}
            />
          )}
          {!onlyMarketing && (
            <BookTab
              name="Final"
              book="final"
              setBooks={setBooks}
              currentBook={books}
              Icon={PresentationChartLineIcon}
            />
          )}
          {onlyMarketing && (
            <BookTab
              name="Actual"
              book="actual"
              setBooks={setBooks}
              currentBook={books}
              Icon={CreditCardIcon}
            />
          )}
        </nav>
      </div>
    </div>
  );
};

export const Expenses = (props) => {
  const { eventID, event, books, setBooks, onlyMarketing } = props;
  const [expandAll, setExpandAll] = useState(false);
  const groups = useQuery(
    ["expense-groups"],
    () => getExpenseGroups(),
    getExpenseGroupSettings
  );
  const expenses = useQuery(
    ["expenses", books, eventID],
    () => getEventExpenses(eventID, books),
    getEventExpensesSettings
  );

  // Set up keyboard shortcut to expand/collapse all expenses
  useEffect(() => {
    Mousetrap.bind("command+e", () => {
      setExpandAll(!expandAll);
    });
    Mousetrap.bind("ctrl+e", () => {
      setExpandAll(!expandAll);
    });
    return () => {
      Mousetrap.unbind("command+e");
      Mousetrap.unbind("ctrl+e");
    };
  }, [expandAll]);

  if (groups.isLoading || expenses.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  // Retrieve groups and subgroup
  return (
    <>
      <div className="w-full grid grid-cols-2 items-center mt-12 mb-4 border-t-[1px] border-[#595959] pt-[1rem]">
        <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray mt-4 dark:text-white">
          Expenses
        </h3>
        <div className="flex flex-inline justify-end items-center gap-x-4">
          <Expand expanded={expandAll} onClick={() => setExpandAll(true)} />
          <Collapse expanded={expandAll} onClick={() => setExpandAll(false)} />
        </div>
      </div>
      <BookTabs
        books={books}
        setBooks={setBooks}
        onlyMarketing={onlyMarketing}
      />
      {groups.data
        .filter((group) => !onlyMarketing || group.id === 2)
        .map((group, index) => (
          <ExpenseTable
            key={index}
            books={books}
            group={group}
            expenses={expenses.data}
            expandAll={expandAll}
            eventID={eventID}
            onlyMarketing={onlyMarketing}
          />
        ))}
    </>
  );
};

const ExpenseRollUp = (props) => {
  const { event, rollups } = props;
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="mr-2">
      <h2
        onClick={() => setShowTable(!showTable)}
        className="hover:bg-gray-300 cursor-pointer w-full border border-gray-300 bg-gray-200 p-2 font-semibold"
      >
        Total Show Expense
        <div className={styles.expensesChevronAccordionDiv}>
          <div className="inline-block ml-2">
            {showTable ? (
              <ChevronDownIcon className={styles.chevronAccordionDown} />
            ) : (
              <ChevronUpIcon className={styles.chevronAccordionUp} />
            )}
          </div>
        </div>
      </h2>
      {showTable && (
        <>
          <div className="w-full grid grid-cols-2 p-1 border-l border-r border-b border-gray-200 dark:bg-white">
            <span className="font-semibold">Expenses</span>
            <span className="text-right">
              {amountDisplay(rollups.total_expenses)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-l border-r border-b border-gray-200 dark:bg-white">
            <span className="font-semibold">Variables</span>
            <span className="text-right">
              {amountDisplay(rollups.variable_expenses)}
            </span>
          </div>
        </>
      )}
      <div className="w-full grid grid-cols-2 p-1 border-l border-r border-b border-gray-200 dark:bg-white">
        <span className="font-semibold">Total Expenses</span>
        <span className="text-right">
          {amountDisplay(rollups.total_expenses + rollups.variable_expenses)}
        </span>
      </div>
      {showTable && (
        <div className="w-full grid grid-cols-2 p-1 border-l border-r border-b border-gray-200 bg-gray-100 dark:bg-white">
          <span className="font-semibold">Net Expenses</span>
          <span className="text-right font-semibold">
            {amountDisplay(rollups.net_expenses)}
          </span>
        </div>
      )}
    </div>
  );
};

const AttendanceRollUp = (props) => {
  const { rollups } = props;
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="mr-2">
      <h2
        onClick={() => setShowTable(!showTable)}
        className="hover:bg-gray-300 cursor-pointer w-full border border-gray-300 bg-gray-200 p-2 font-semibold"
      >
        Attendance
        <div className={styles.expensesChevronAccordionDiv}>
          <div className="inline-block ml-2">
            {showTable ? (
              <ChevronDownIcon className={styles.chevronAccordionDown} />
            ) : (
              <ChevronUpIcon className={styles.chevronAccordionUp} />
            )}
          </div>
        </div>
      </h2>
      {showTable && (
        <>
          <div className="w-full grid grid-cols-2 p-1 border-b border-l border-r border-gray-200 dark:bg-white">
            <span className="font-semibold">Paid Attendance</span>
            <span className="text-right">{rollups.paid_tickets}</span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-l border-r border-gray-200 dark:bg-white">
            <span className="font-semibold">Comps</span>
            <span className="text-right">{rollups.comps}</span>
          </div>
        </>
      )}
      <div className="w-full grid grid-cols-2 p-1 border-b border-l border-r border-gray-200 dark:bg-white">
        <span className="font-semibold">Total Attendance</span>
        <span className="text-right">{rollups.total_attendance}</span>
      </div>
      {showTable && (
        <div className="w-full grid grid-cols-2 p-1 border-b border-l border-r border-gray-200  dark:bg-white">
          <span className="font-semibold">Capacity</span>
          <span className="text-right">{rollups.capacity}</span>
        </div>
      )}
    </div>
  );
};

const VariableExpenses = (props) => {
  const { event, rollups } = props;
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="mr-2">
      <h2
        onClick={() => setShowTable(!showTable)}
        className="hover:bg-gray-300 cursor-pointer w-full border border-gray-300 bg-gray-200 p-2 font-semibold"
      >
        Variable Expenses
        <div className={styles.expensesChevronAccordionDiv}>
          <div className="inline-block ml-2">
            {showTable ? (
              <ChevronDownIcon className={styles.chevronAccordionDown} />
            ) : (
              <ChevronUpIcon className={styles.chevronAccordionUp} />
            )}
          </div>
        </div>
      </h2>
      {showTable && (
        <>
          <div className="w-full grid grid-cols-2 p-1 border-l border-r border-b border-gray-200 dark:bg-white">
            <span className="font-semibold">Credit Cards</span>
            <span className="text-right">
              {amountDisplay(event.credit_card_fees)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-l border-r border-b border-gray-200 dark:bg-white">
            <span className="font-semibold">Ticket Commission</span>
            <span className="text-right">
              {amountDisplay(event.ticket_commission)}
            </span>
          </div>
          {event.has_ascap && (
            <div className="w-full grid grid-cols-2 p-1 border-l border-r border-b border-gray-200 dark:bg-white">
              <span className="font-semibold">ASCAP</span>
              <span className="text-right">
                {amountDisplay(rollups.ascap_total)}
              </span>
            </div>
          )}
          {event.has_bmi && (
            <div className="w-full grid grid-cols-2 p-1 border-l border-r border-b border-gray-200 dark:bg-white">
              <span className="font-semibold">BMI</span>
              <span className="text-right">
                {amountDisplay(rollups.bmi_total)}
              </span>
            </div>
          )}
          {event.has_sesac && (
            <div className="w-full grid grid-cols-2 p-1 border-l border-r border-b border-gray-200 dark:bg-white">
              <span className="font-semibold">SESAC</span>
              <span className="text-right">
                {amountDisplay(rollups.sesac_total)}
              </span>
            </div>
          )}
          <div className="w-full grid grid-cols-2 p-1 border-l border-r border-b border-gray-200 dark:bg-white">
            <span className="font-semibold">Insurance</span>
            <span className="text-right">
              {amountDisplay(event.insurance_amount)}
            </span>
          </div>
        </>
      )}
      <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
        <span className="font-semibold">Total Variable Expenses</span>
        <span className="font-semibold text-right">
          {amountDisplay(rollups.variable_expenses)}
        </span>
      </div>
    </div>
  );
};

const FinalRollUp = (props) => {
  const { event, rollups } = props;
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="border border-gray-300 mr-2">
      <h2
        onClick={() => setShowTable(!showTable)}
        className="hover:bg-gray-300 cursor-pointer w-full border border-gray-300 bg-gray-200 p-2 font-semibold"
      >
        Final Figures
        <div className={styles.expensesChevronAccordionDiv}>
          <div className="inline-block ml-2">
            {showTable ? (
              <ChevronDownIcon className={styles.chevronAccordionDown} />
            ) : (
              <ChevronUpIcon className={styles.chevronAccordionUp} />
            )}
          </div>
        </div>
      </h2>
      {showTable && (
        <>
          <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
            <span className="font-semibold">Gross</span>
            <span className="text-right">
              {amountDisplay(rollups.tickets_gross)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Less Facility Fee</span>
            <span className="text-right">
              {amountDisplay(rollups.total_facility_fee)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Less Charity</span>
            <span className="text-right">
              {amountDisplay(rollups.charity, true)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Plus Platinum Lift </span>
            <span className="text-right">
              {amountDisplay(rollups.platinum_lift)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
            <span className="font-semibold">Adjusted Gross</span>
            <span className="font-semibold text-right">
              {amountDisplay(rollups.adjusted_gross)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="font-semibold">Tax Percent</span>
            <span className="text-right">{rollups.tax_percent} %</span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="font-semibold">Total Tax</span>
            <span className="text-right">
              {amountDisplay(rollups.tax_amount)}
            </span>
          </div>
        </>
      )}
      <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
        <span className="font-semibold">NET</span>
        <span className="font-semibold text-right">
          {amountDisplay(rollups.net_income)}
        </span>
      </div>
    </div>
  );
};

const PromoterRollUp = (props) => {
  const { event, rollups } = props;
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="border border-gray-300 mr-2">
      <h2
        onClick={() => setShowTable(!showTable)}
        className="hover:bg-gray-300 cursor-pointer w-full border border-gray-300 bg-gray-200 p-2 font-semibold"
      >
        Promoter Settlement
        <div className={styles.expensesChevronAccordionDiv}>
          <div className="inline-block ml-2">
            {showTable ? (
              <ChevronDownIcon className={styles.chevronAccordionDown} />
            ) : (
              <ChevronUpIcon className={styles.chevronAccordionUp} />
            )}
          </div>
        </div>
      </h2>
      {showTable && (
        <>
          <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
            <span className="font-semibold">Profit / Loss</span>
            <span className="text-right">
              {amountDisplay(rollups.profit_loss)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Rebate</span>
            <span className="text-right">{amountDisplay(event.rebate)}</span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Merch</span>
            <span className="text-right">{amountDisplay(event.merch)}</span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Concessions</span>
            <span className="text-right">
              {amountDisplay(event.concessions)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Facility Fee</span>
            <span className="text-right">
              {amountDisplay(rollups.total_facility_fee)}
            </span>
          </div>
        </>
      )}
      <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
        <span className="font-semibold">Total Revenue</span>
        <span className="font-semibold text-right">
          {amountDisplay(rollups.total_revenue)}
        </span>
      </div>
    </div>
  );
};

const PromoterMammoth = (props) => {
  const { event, rollups } = props;
  const [showTable, setShowTable] = useState(false);
  return (
    <div className="border border-gray-300 mr-2">
      <h2
        onClick={() => setShowTable(!showTable)}
        className="hover:bg-gray-300 cursor-pointer w-full border border-gray-300 bg-gray-200 p-2 font-semibold"
      >
        Promoter Mammoth
        <div className={styles.expensesChevronAccordionDiv}>
          <div className="inline-block ml-2">
            {showTable ? (
              <ChevronDownIcon className={styles.chevronAccordionDown} />
            ) : (
              <ChevronUpIcon className={styles.chevronAccordionUp} />
            )}
          </div>
        </div>
      </h2>
      {showTable && (
        <>
          <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
            <span className="font-semibold">Promoter %</span>
            <span className="text-right">{event.mammoth_percentage} %</span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
            <span className="font-semibold">Profit %</span>
            <span className="text-right">
              {amountDisplay(rollups.mammoth_profit)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Plus Expenses</span>
            <span className="text-right">
              {amountDisplay(rollups.mammoth_expenses)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Plus Tax</span>
            <span className="text-right">
              {amountDisplay(rollups.tax_amount)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Less Ticketing</span>
            <span className="text-right">
              {amountDisplay(rollups.mammoth_hold, true)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Less Rebate</span>
            <span className="text-right">
              {amountDisplay(event.rebate, true)}
            </span>
          </div>
        </>
      )}
      <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
        <span className="font-semibold">Due</span>
        <span className="font-semibold text-right">
          {amountDisplay(rollups.due_mammoth)}
        </span>
      </div>
    </div>
  );
};

const Building = (props) => {
  const { event, rollups } = props;
  const [showTable, setShowTable] = useState(false);
  return (
    <div className="border border-gray-300 mr-2">
      <h2
        onClick={() => setShowTable(!showTable)}
        className="hover:bg-gray-300 cursor-pointer w-full border border-gray-300 bg-gray-200 p-2 font-semibold"
      >
        Building
        <div className={styles.expensesChevronAccordionDiv}>
          <div className="inline-block ml-2">
            {showTable ? (
              <ChevronDownIcon className={styles.chevronAccordionDown} />
            ) : (
              <ChevronUpIcon className={styles.chevronAccordionUp} />
            )}
          </div>
        </div>
      </h2>
      {showTable && (
        <>
          <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
            <span className="font-semibold">Building %</span>
            <span className="text-right">{event.building_percentage} %</span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
            <span className="font-semibold">Profit %</span>
            <span className="text-right">
              {amountDisplay(rollups.building_profit)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Plus Expenses</span>
            <span className="text-right">
              {amountDisplay(rollups.building_expenses)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Plus Tax</span>
            <span className="text-right">
              {amountDisplay(rollups.tax_amount)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Plus Facility Fee</span>
            <span className="text-right">
              {amountDisplay(rollups.total_facility_fee)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Less Ticketing</span>
            <span className="text-right">
              {amountDisplay(rollups.building_hold, true)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Less Concessions</span>
            <span className="text-right">
              {amountDisplay(event.concessions, true)}
            </span>
          </div>
        </>
      )}
      <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
        <span className="font-semibold">Due Building</span>
        <span className="font-semibold text-right">
          {amountDisplay(rollups.due_building)}
        </span>
      </div>
    </div>
  );
};

const Artist = (props) => {
  const { event, rollups } = props;
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="border border-gray-300 mr-2">
      <h2
        onClick={() => setShowTable(!showTable)}
        className="hover:bg-gray-300 cursor-pointer w-full border border-gray-300 bg-gray-200 p-2 font-semibold"
      >
        Artist
        <div className={styles.expensesChevronAccordionDiv}>
          <div className="inline-block ml-2">
            {showTable ? (
              <ChevronDownIcon className={styles.chevronAccordionDown} />
            ) : (
              <ChevronUpIcon className={styles.chevronAccordionUp} />
            )}
          </div>
        </div>
      </h2>
      {showTable && (
        <>
          <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
            <span className="font-semibold">Guarantee</span>
            <span className="text-right">
              {amountDisplay(rollups.artist_guarantee)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
            <span className="font-semibold">Plus Artist Production</span>
            <span className="text-right">
              {amountDisplay(rollups.artist_production)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Plus Support</span>
            <span className="text-right">
              {amountDisplay(rollups.artist_support)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Less State Witholding</span>
            <span className="text-right">
              {amountDisplay(rollups.artist_witholding)}
            </span>
          </div>
          <div className="w-full grid grid-cols-2 p-1 border-b border-gray-200 dark:bg-white">
            <span className="">Less Deposit</span>
            <span className="text-right">
              {amountDisplay(rollups.artist_deposit)}
            </span>
          </div>
        </>
      )}
      <div className="w-full grid grid-cols-2 p-1 border border-gray-300 bg-gray-100 dark:bg-white">
        <span className="font-semibold">Due Artist</span>
        <span className="font-semibold text-right">
          {amountDisplay(rollups.artist_net)}
        </span>
      </div>
    </div>
  );
};

const PercentageError = (props) => {
  const { rollups } = props;

  if (rollups.check_percentages < 100.0) {
    return (
      <div className="w-full border border-green-500 bg-green-100 mt-8 mb-8 p-6">
        <h1 className="text-xl font-bold">Percentage Error!</h1>
        <p className="my-4">
          Total profit percentage shares adds up to{" "}
          <span className="font-semibold">{rollups.check_percentages}% </span>
          Between the Mammoth, Building, Artist, and Copro percentages the total
          is below 100%. Please adjust to a realistic value.
        </p>
      </div>
    );
  }

  if (rollups.check_percentages > 100.0) {
    return (
      <div className="w-full border border-red-500 bg-red-100 mt-8 mb-8 p-6">
        <h1 className="text-xl font-bold">Percentage Error!</h1>
        <p className="my-4">
          Total profit percentage shares adds up to{" "}
          <span className="font-semibold">{rollups.check_percentages}% </span>
          Between the Mammoth, Building, Artist, and Copro percentages the total
          is over 100%. Please adjust to a realistic value.
        </p>
      </div>
    );
  }
  return null;
};

const CapacityError = (props) => {
  const { rollups } = props;

  if (rollups.capacity == 0) {
    return (
      <div className="w-full border border-orange-500 bg-orange-100 mt-8 mb-8 p-6">
        <h1 className="text-xl font-bold">Venue Capacity Error!</h1>
        <p className="my-4">
          ASCAP and BMI are based on the Venue's capacity. This Venue does not
          have a value for capacity so they{" "}
          <strong>cannot be calculated.</strong>
        </p>
      </div>
    );
  }

  return null;
};

export const RollUp = (props) => {
  const { eventID, event, books, onlyMarketing } = props;
  const rollups = useQuery(["event-rollups", books, eventID], () =>
    getEventRollups(eventID, books)
  );
  const navigate = useNavigate();
  const { perms } = usePerms();

  if (rollups.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  if (onlyMarketing) {
    return;
  }

  return (
    <>
      <div className="w-full grid grid-cols-2 items-end">
        <h3 className="text-2xl font-semibold text-gray-500 dark:text-white mt-16">
          Roll Up
        </h3>
        <div className="flex flex-inline justify-end gap-x-4">
          {perms.data.edit_events && (
            <Button
              onClick={() => {
                navigate(`/events/edit/accounting/${eventID}`);
              }}
            >
              Edit Accounting
            </Button>
          )}
        </div>
      </div>

      <PercentageError rollups={rollups.data} />
      <CapacityError rollups={rollups.data} />

      <div className="w-full grid xs:grid-cols-1 gap-[2rem] sm:grid-cols-2 mt-8">
        <ExpenseRollUp event={event} rollups={rollups.data} />
        <AttendanceRollUp rollups={rollups.data} />
      </div>
      <div className="w-full mt-8">
        <VariableExpenses event={event} rollups={rollups.data} />
      </div>
      <div className="w-full mt-8">
        <FinalRollUp event={event} rollups={rollups.data} />
      </div>
      <div className="w-full mt-8">
        <Artist event={event} rollups={rollups.data} />
      </div>
      <div className="w-full mt-8">
        <PromoterRollUp event={event} rollups={rollups.data} />
      </div>
      <div className="w-full mt-8">
        <PromoterMammoth event={event} rollups={rollups.data} />
      </div>
      <div className="w-full mt-8">
        <Building event={event} rollups={rollups.data} />
      </div>
    </>
  );
};

export default Expenses;


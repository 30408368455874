/* import classnames from "classnames"; */
/* import dayjs from "dayjs"; */
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Header } from "@/components/Header";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import {
  useQuery /* useMutation, useQueryClient */,
  useQueryClient,
} from "@tanstack/react-query";
import {
  getEvent,
  getEventPeople,
  getEventSettlementInfo,
  updateEvent,
} from "@/queries/events";
import { getArtistEventRollups } from "@/queries/accounting";
import { usePageTitle } from "@/utils/pagetitle";
import { ToastContainer } from "react-toastify";
import SettlementTabs from "@/components/Settlement/SettlementTabs";
import { SettlementTab } from "@/types/settlement";
import SettlementHeader from "@/components/Settlement/SettlementHeader";
import SettlementArtistPage from "@/components/Settlement/SettlementArtistPage";
import SettlementCoproPage from "@/components/Settlement/SettlementCoproPage";
import SettlementFinalPage from "@/components/Settlement/SettlementFinalPage";

export const EventSettlement = ({ isPDF = false }: { isPDF?: boolean }) => {
  const [getEventDate, setGetEventDate] = useState("");
  const { id, type: settlementType } = useParams<{
    id: string;
    type: SettlementTab;
  }>();
  const pageIndex = settlementType;
  const navigate = useNavigate();

useEffect(() => {
    const removeDarkClass = () => {
      document.documentElement.classList.remove('dark');
    };

    removeDarkClass();
    const timeoutId = setTimeout(removeDarkClass, 0);
    return () => {
      clearTimeout(timeoutId);
      // Optionally, add the 'dark' class back when navigating away
      // document.documentElement.classList.add('dark');
    };
  }, []); // Empty dependency array means this effect runs once on mount



  useEffect(() => {
    if (isPDF) {
      navigate('/some-pdf-route');  // Adjust the route as needed
    }
    if (!settlementType) {
      navigate(`/events/settlement/artist/${id}`);
    }
  }, [isPDF, settlementType]);
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const queryClient = useQueryClient();
  const handleUpdateEvent = async (
    id = event.data?.id,
    data: Record<string, unknown>
  ) => {
    await updateEvent(id, {
      ...event.data,
      artists: event.data.artists?.map((artist) => artist.id),
      buyer: event.data.buyer.id,
      manifests: event.data.manifests?.map((manifest) => manifest.id),
      venue: event.data.venue.id,
      ...data,
    });
    event.refetch();
    rollups.refetch();
  };
  const settlement = useQuery(["event-settlement-info", id], () =>
    getEventSettlementInfo(id)
  );
  const people = useQuery(["event", "people", id], () => getEventPeople(id));
  const rollups = useQuery(["event-rollups", "artist", id], () =>
    getArtistEventRollups(id)
  );
  const tabToTitle = () => {
    if (pageIndex === "artist") {
      return "ARTIST";
    }
    if (pageIndex === "copro") {
      return "COPRO";
    }
    return "FINAL";
  };
  usePageTitle(
    event.isSuccess
      ? `/c${String.fromCharCode(92)}${tabToTitle()} Settle`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  if (
    event.isLoading ||
    people.isLoading ||
    rollups.isLoading ||
    settlement.isLoading
  ) {
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  }
    return (
        <>
        <div className="light bg-white dark:bg-cave-gray-900">
          <BreadCrumbs
            links={[
              { text: "Events", url: `/events/` },
              {
                text: `${event.data.name} ${getEventDate}`,
                url: `/events/${event.data.id}`,
              },
              { text: "Edit: Settle" },
            ]}
          />
        </div> 
        <div className="text-xs p-16">
          {!isPDF && <SettlementTabs />}

          <div>
            <SettlementHeader />
            {pageIndex === "artist" && <SettlementArtistPage />}
            {pageIndex === "copro" && <SettlementCoproPage />}
            {pageIndex === "final" && <SettlementFinalPage />}
          </div>
        </div>
  </>
  );
};

export default EventSettlement;

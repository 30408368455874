import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
} from "./base";

export const getMessageSettings = {
  cacheTime: 30 * 60 * 1000, // 30 minutes
  staleTime: 15 * 60 * 1000, // 15 minutes
};

export const getMessages = async () => {
  const url = `/api/bull/messages/`;
  return await getApiRequest(url);
};

export const getMessage = async (id) => {
  const url = `/api/bull/messages/${id}/`;
  return await getApiRequest(url);
};

export const createMessage = async (data) => {
  const url = `/api/bull/messages/`;
  return await postApiRequest(url, data);
};

export const updateMessage = async (id, data) => {
  const url = `/api/bull/messages/${id}/`;
  return await putApiRequest(url, data);
};

export const deleteMessage = async (id) => {
  const url = `/api/bull/messages/${id}/`;
  return await deleteApiRequest(url);
};

export const removeMessage = async (messageId) => {
  const url = `/api/bull/messages/${messageId}/remove/`;
  return await postApiRequest(url, {});
};

export const dismissMessage = async (messageId) => {
  const url = `/api/bull/messages/${messageId}/dismiss/`;
  return await postApiRequest(url, {});
};

export const dismissEvent = async (eventId) => {
  const url = `/api/bull/messages/event/${eventId}/dismiss/`;
  return await postApiRequest(url, {'event_ids': [ eventId, ] });
}

import React, { useState, useEffect } from "react";
import MoneyInputComponent from "./Common/MoneyInput";
import { formatInputToMoney } from "@/utils/money";
type AlignOption = "start" | "center" | "end" | "left" | "right";
interface Column {
  title?: string;
  accessor: string;
  key?: string;
  textAlign?: AlignOption;
  className?: string;
  editable?: boolean;
  render?: (data: any, row: Record<string, any>) => JSX.Element;
  onBlur?: (value: any, rowIndex: number, colKey?: string) => void;
  format?: "money" | "input";
}

interface CustomRow {
  render: () => JSX.Element;
}

interface TableProps {
  className?: string;
  flexClassNames?: string;
  flex?: boolean;
  grid?: boolean;
  textCenter?: boolean;
  tableTitle: string;
  columns: Column[];
  data: Record<string, any>[];
  totalsRow?: {
    title: string;
    textAlign?: AlignOption;
    values: (string | number)[];
  };
  customRows?: CustomRow[];
  hideHeaders?: boolean;
  onChange?: (rowIndex: number, accessor: string, value: any, row: any) => void;
  onCellBlur?: (
    rowIndex: number,
    accessor: string,
    value: any,
    row: any
  ) => void;
}

interface TableRowProps {
  columns: Column[];
  rowData: Record<string, any>;
  onBlur?: (field: string, value: any) => void;
  onChange?: (field: string, value: any) => void;
  rowContainerClass?: string;
  inputClassName?: string;
}

const TableRow: React.FC<TableRowProps> = ({
  columns,
  rowData,
  onBlur,
  onChange,
  rowContainerClass = "",
  inputClassName = "",
}) => {
  const [localRowData, setLocalRowData] = useState(rowData);

  const handleInputChange = (field: string, value: string) => {
    setLocalRowData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (onChange) {
      onChange(field, value);
    }
  };

  return (
    <div
      className={`grid border-b-2 border-black ${rowContainerClass}`}
      style={{
        gridTemplateColumns: `repeat(${columns.length}, minmax(0, 1fr))`,
      }}
    >
      {columns.map((col, colIndex) => (
        <div
          key={colIndex}
          className={`${col.textAlign ? `text-${col.textAlign}` : "left"} ${
            col.className || ""
          }`}
        >
          {(col.render &&
            col.render(localRowData[col.accessor], localRowData)) ?? (
            <>
              {col.editable ? (
                col.format === "money" ? (
                  <MoneyInputComponent
                    value={localRowData[col.accessor]}
                    onBlur={(e) => {
                      if (onBlur) {
                        onBlur(col.accessor, e.target.value, col.key);
                      }
                    }}
                    onChange={(e) =>
                      handleInputChange(col.accessor, e.target.value)
                    }
                    className={`w-full bg-input-blue text-black border border-gray-300 rounded ${inputClassName}`}
                  />
                ) : (
                  <input
                    type="text"
                    value={localRowData[col.accessor]}
                    onBlur={(e) => {
                      if (onBlur) {
                        onBlur(col.accessor, e.target.value, col.key);
                      }
                    }}
                    onChange={(e) =>
                      handleInputChange(col.accessor, e.target.value)
                    }
                    className={`w-full bg-input-blue text-black border border-gray-300 rounded ${inputClassName}`}
                  />
                )
              ) : (
                <span
                  className={`block ${col.textAlign ? `text-${col.textAlign}` : ""}`}
                >
                  {col.format === "money"
                    ? formatInputToMoney(localRowData[col.accessor]?.toString())
                    : localRowData[col.accessor]}
                </span>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

const Table: React.FC<TableProps> = ({
  className,
  flex,
  flexClassNames,
  grid,
  textCenter,
  tableTitle,
  columns,
  data,
  totalsRow,
  customRows,
  hideHeaders = false,
  onChange,
  onCellBlur,
}) => {
  const [localData, setLocalData] = useState(data);

  // Update localData whenever the data prop changes
  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleInputChange = (
    rowIndex: number,
    accessor: string,
    value: any
  ) => {
    const newData = [...localData];
    newData[rowIndex] = { ...newData[rowIndex], [accessor]: value };
    setLocalData(newData);

    if (onChange) {
      onChange(rowIndex, accessor, value, newData[rowIndex]);
    }
  };

  const handleInputBlur = (rowIndex: number, accessor: string, value: any) => {
    const column = columns.find((col) => col.accessor === accessor);
    if (column?.onBlur) {
      column.onBlur(value, rowIndex, localData[rowIndex], column.key);
    } else if (onCellBlur) {
      onCellBlur(rowIndex, accessor, value, localData[rowIndex]);
    }
  };

  const renderCell = (
    rowIndex: number,
    col: Column,
    value: any,
    row: Record<string, any>
  ) => {
    if (col.render) {
      return col.render(value, row);
    }

    if (col.editable) {
      if (col.format === "money") {
        return (
          <MoneyInputComponent
            value={value}
            onChange={(e) =>
              handleInputChange(rowIndex, col.accessor, e.target.value)
            }
            onBlur={(e) =>
              handleInputBlur(rowIndex, col.accessor, e.target.value, col.key)
            }
            className={`bg-input-blue text-${col.textAlign || "left"} w-full`}
          />
        );
      }
      return (
        <input
          className={`bg-input-blue text-${col.textAlign || "left"} w-full`}
          value={value}
          onChange={(event) =>
            handleInputChange(rowIndex, col.accessor, event.target.value)
          }
          onBlur={(event) =>
            handleInputBlur(rowIndex, col.accessor, event.target.value, col.key)
          }
        />
      );
    }

    return (
      <span className={`block ${col.className || ""}`}>
        {col.format === "money"
          ? `${formatInputToMoney(value?.toString())}`
          : value}
      </span>
    );
  };

  return (
    <div
      className={`${textCenter ? "text-center" : ""} border-2 border-black ${
        className || ""
      }`}
    >
      <h2 className="text-center font-bold text-blue-600">{tableTitle}</h2>
      {!hideHeaders && (
        <div
          className="grid border-t-2 border-black"
          style={{
            gridTemplateColumns: `repeat(${columns.length}, minmax(0, 1fr))`,
          }}
        >
          {columns.map((col, colIndex) => (
            <div
              key={colIndex}
              className={`font-bold bg-gray-200 ${
                col.className || ""
              } text-${col.textAlign || "left"}`}
            >
              {col.title}
            </div>
          ))}
        </div>
      )}
      <div>
        {localData.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="grid"
            style={{
              gridTemplateColumns: `repeat(${columns.length}, minmax(0, 1fr))`,
            }}
          >
            {columns.map((col, colIndex) => (
              <div
                key={colIndex}
                className={`${col.className || ""} text-${
                  col.textAlign || "left"
                }`}
              >
                {renderCell(rowIndex, col, row[col.accessor], row)}
              </div>
            ))}
          </div>
        ))}
      </div>
      {customRows &&
        customRows.map((customRow, index) => (
          <div key={`custom-${index}`} className="w-full">
            {customRow.render()}
          </div>
        ))}
      {totalsRow && (
        <div
          className={`grid border-t-2 text-${totalsRow.textAlign} border-black`}
          style={{
            gridTemplateColumns: `repeat(${
              totalsRow.values.length + 1
            }, minmax(0, 1fr))`,
          }}
        >
          <span className="bg-gray-200 font-bold">{totalsRow.title}</span>
          {totalsRow.values.map((value, index) => (
            <span className="bg-gray-200 w-full" key={index}>
              {value}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export { Table, TableRow };

import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import { useUser } from "@/components/Auth";
import { useQuery } from "@tanstack/react-query";
import { getRecentVisits, recentVisitsQuerySettings } from "@/queries/visit";
import { useNavigate } from "react-router-dom";
import { NextEventCard } from "./NextEvent/NextEvent";
//import { BulletinBoard } from "./BulletinBoard/BulletinBoard";
import { BulletinBoard } from "@/components/Dashboard/BulletinBoard/bb";
import { EventCard } from "./EventCard/EventCard";
import {
  getEventsDashboardList,
  getEventsSettings,
  getMyDrafts,
} from "../../queries/events";
import { Carousel } from "./Carousel/Carousel";
import { usePageTitle } from "@/utils/pagetitle";
import { usePerms } from "@/components/Auth";

export const Dashboard = () => {
  const { user } = useUser();
  const { perms } = usePerms();
  const navigate = useNavigate();
  const recent_visits = useQuery(
    ["recent-visits"],
    () => getRecentVisits(),
    recentVisitsQuerySettings
  );

  // Get all confirmed events for Dashboard
  const events = useQuery(
    ["events"],
    () => getEventsDashboardList(),
    getEventsSettings
  );

  // Get my Drafts
  const drafts = useQuery(
    ["events", "drafts"],
    () => getMyDrafts(),
    getEventsSettings
  );

  const [isFadingOut, setIsFadingOut] = useState(false);
  usePageTitle(
    events.isSuccess
      ? `/c${String.fromCharCode(92)}dashboard`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

    useEffect(() => {
      if (events.isLoading) {
        setIsFadingOut(false);
      } else if (events.isSuccess) {
        setTimeout(() => {
          setIsFadingOut(true);
        }, 1000); // Adjust the delay as needed
      }
    }, [events.isLoading, events.isSuccess]);
  
  return (
    <>
      {events.isLoading && (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center bg-welcome bg-cover bg-center transition-opacity duration-1000 ${
            isFadingOut ? "opacity-0" : "opacity-100"
          }`}
          style={{
            backgroundImage:
              "url('/static/images/welcome_to_cave_loading.jpg')",
          }}
        >
          <div className="animate-pulse text-white text-sm mt-4 text-center">
            <br /> <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br /> <br />
            <br />
            /c\
          </div>
        </div>
      )}
      <div
        className={`transition-opacity duration-1000 ${
          events.isLoading && !isFadingOut ? "opacity-0" : "opacity-100"
        }`}
      >

      <Header />
      <div className="flex flex-col relative px-6 py-8 mx-auto lg:py-0 bg-white dark:bg-[#181818]">
        <div className="my-6">
          <span className="text-gray-500 dark:text-gray-200 font-sans">
            Your Role: {user.data.role_name}
          </span>
        </div>
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-1/2">
            {events.data && events.data.length > 0 && (
              <NextEventCard
                eventName={events.data[0].name}
                venue={events.data[0].venue_name}
                date={events.data[0].date}
                status={events.data[0].state}
                onClick={() => {}}
                backgroundImage={events.data[0].event_image_url}
                url={`/events/${events.data[0].id}`}
              />
            )}
            <div className="flex flex-col sm:flex-row justify-between items-center mt-16">
              <h3 className="text-2xl font-sans font-bold dark:text-gray-200">
                Drafts
              </h3>
              <button
                onClick={() => navigate(`/events/add`)}
                className="bg-[#888888] rounded w-full sm:w-[200px] px-4 py-2 text-white font-sans font-bold capitalize mt-4 sm:mt-0"
              >
                CREATE DRAFT
              </button>
            </div>
            <div className="flex flex-col gap-4 items-center mt-6 pt-8 pb-4">
              <Carousel three>
                {drafts.data && drafts.data.map((draft) => (
                  <EventCard
                    eventName={draft.name}
                    venue={draft.venue_name}
                    date={draft.date}
                    status={draft.state}
                    onClick={() => {}}
                    backgroundImage={draft.event_image_url}
                    key={`draft-${draft.id}`}
                    url={`/events/${draft.id}`}
                  />
                ))}
              </Carousel>
              {drafts && drafts.length === 0 && (
                <div className="dark:text-gray-200">No drafts</div>
              )}
            </div>
          </div>
          <div className="w-full lg:w-1/2 mt-6 lg:mt-0">
            <BulletinBoard userId={user.data.id} />
          </div>
        </div>
        <div className="py-6 bg-white dark:bg-[#181818]">
          <h2 className="text-3xl font-sans font-bold text-gray-700 w-full py-2 bg-white mb-4 dark:text-gray-200 dark:bg-[#181818] sm:mb-[0rem] xs:mb-[3rem]">
            Recently Viewed
          </h2>
          {recent_visits.isSuccess && (
            <Carousel>
              {recent_visits.data.map((item, index) => (
                <EventCard
                  key={`recent-visit-${item.id}`}
                  eventName={item.title}
                  venue={item.subtitle}
                  status={""}
                  date={""}
                  backgroundImage={
                    item.event_image_url
                      ? item.event_image_url
                      : "/static/images/cave_no_image_event.png"
                  }
                  url={item.url}
                />
              ))}
            </Carousel>
          )}
        </div>
        <div className="py-6 bg-white dark:bg-[#181818]">
          <h2 className="dark:bg-[#181818] dark:text-gray-200 text-3xl font-sans font-bold text-gray-7000 w-full py-2 bg-white mb-4">
            Events
          </h2>
          <Carousel>
            {events.data && events.data.map((event, index) => (
              <EventCard
                eventName={event.name}
                venue={event.venue_name}
                date={event.date}
                status={event.state}
                onClick={() => {}}
                backgroundImage={
                  event.event_image_url
                    ? event.event_image_url
                    : "/static/images/cave_no_image_event.png"
                }
                url={`/events/${event.id}`}
                key={`non-draft-${event.id}`}
              />
            ))}
          </Carousel>
        </div>
      </div>
     </div>
    </>
  );
};
